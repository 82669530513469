import { get, post } from '@/libs/api.request'

const filterSearchFields = (data) => {
  data['searchKeyParam'] = {}
  for (const key in data) {
    if (key.indexOf('search_key') !== -1) {
      const json = {}
      json[key] = data[key].split(',')
      data['searchKeyParam'] = { ...data['searchKeyParam'], ...json }
      delete data[key]
    }
    if (typeof (data[key]) === 'string' && ['outSerialNos'].includes(key)) {
      data[key] = data[key].trim().replace(/\n/g, ',').split(',')
    }
  }
  return data
}

export const getDictionary = (keys) => {
  const key = keys.join(',')
  return get(`v1/dictionary/list/${key}`)
}

export const getHomeAnalyse = () => {
  return get('v1/case/operation/followCaseDays')
}

export const getOperationLog = (op) => {
  op = filterSearchFields(op)
  return post('v1/cases/log', op, { noSucTip: true })
}

export const applyInfo = (data) => {
  return get('v1/statistics/console/applyInfo', data)
}

export const caseConsoleStatisToday = (data) => {
  return get('v1/statistics/console/caseConsoleStatisToday', data)
}

export const caseConsoleStatistics = (data) => {
  return get('v1/statistics/console/caseConsoleStatistics', data)
}

export const repaymentRankingList = (data) => {
  return get('v1/statistics/console/repaymentRankingList', data)
}

export const caseFollow = (data) => {
  return get('v1/statistics/console/caseFollow', data)
}

export const caseStateAnalyse = (data) => {
  return get('v1/statistics/console/caseStateAnalyse', data)
}

export const getRepaymentAnalyseList = (data) => {
  return get('v1/statistics/console/repaymentAnalyseList', data)
}

export const getMyRank = (data) => {
  return get('v1/statistics/console/myRankingStatistics', data)
}

// 明文申请统计
export const plainCount = (data) => {
  return get('/v1/plaintext/auditing/count', data)
}
