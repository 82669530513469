import axios from 'axios'
import store from '@/store'
import baseConf from '@/config'
import axiosRetry from 'axios-retry'

import { getToken, setCookieUat } from '@/libs/util'
// store.dispatch('handleLogOut')
const responseHandle = (
  { method, noSucTip, noErrTip, hasErrorRes, openApi },
  response
) => {
  if (!['/xapi/v1/mediation/video/roomInfo'].includes(response.config.url)) {
    setCookieUat(response.headers.isUat || response.headers.isuat)
  }
  if (
    (response.data && response.data.status === 200) ||
    baseConf.env === 'mock'
  ) {
    // 去掉提示
    if (!noSucTip && method !== 'get') {
      window.VueMainInstance.$Message.success('操作成功！')
    }
    return response.data
  } else {
    // 去掉提示
    if (noErrTip) {
      return hasErrorRes ? response.data : false
    }
    // open api 接口不管成不成功，数据都返回
    if (openApi) {
      return response.data
    }
    // if (response.data && response.data.status === 1) {
    //   return response.data
    // }
    // token 失效重新登录
    if (response.data.status === 8507) {
      window.VueMainInstance.$Modal.error({
        title: '温馨提示',
        content: '您的账号在另一地点登录，您需退出重新登录',
        okText: '重新登录',
        onOk: () => {
          return store.dispatch('handleLogOut')
        }
      })
      return 'noHandleLogOut'
    } else if (response.data.status === 8508) {
      window.VueMainInstance.$Modal.error({
        title: '温馨提示',
        content: '您当前IP已被限制访问，请联系管理员重新设置IP访问权限',
        okText: '确定',
        onOk: () => {
          return store.dispatch('handleLogOut')
        }
      })
      return 'noHandleLogOut'
    } else if (response.data.status === 8509) {
      window.VueMainInstance.$Modal.error({
        title: '温馨提示',
        content: '企业未开通外访功能，无法登陆外访员账户',
        okText: '确定',
        onOk: () => {
          return store.dispatch('handleLogOut')
        }
      })
      return 'noHandleLogOut'
    } else if (response.data.status === 8510) {
      window.VueMainInstance.$Modal.error({
        title: '温馨提示',
        content: '管理员已修改您的账号信息，请重新登录！',
        okText: '确定',
        onOk: () => {
          return store.dispatch('handleLogOut')
        }
      })
      return 'noHandleLogOut'
    } else if ([7002, 8001, 8002, 8003, 8004, 8005, 8007, 9000, 9001].includes(response.data.status)) {
      return response.data
    } else {
      window.VueMainInstance.$Notice.error({
        title: '操作失败',
        desc: response.data ? response.data.message : '请稍后再试'
      })
    }
    return hasErrorRes ? response.data : false
  }
}

export const doRquest = ({ url, method, axiosObj, config }) => {
  const conf = config || {}

  const axiosInstance = axios.create({
    baseURL: conf.ndApi ? baseConf.nodeBaseUrl : baseConf.baseUrl,
    timeout: conf.uploadTime ? 3000000 : 15000,
    headers: { token: getToken() }
  })

  if (conf.retryCount) {
    axiosRetry(axiosInstance, {
      retries: conf.retryCount,
      retryDelay: () => 1000,
      shouldResetTimeout: true,
      retryCondition(error) {
        return ![200].includes(error.code)
      }
    })
  }

  // get 如果不用定制axiosObj， 默认为axiosObj就是 params
  if (method === 'get' && axiosObj && !axiosObj.params) {
    axiosObj = { params: axiosObj }
  } else if (method === 'delete' && axiosObj && !axiosObj.data) {
    axiosObj = { data: axiosObj }
  }

  return axiosInstance[method](url, axiosObj, conf)
    .then(
      responseHandle.bind(this, {
        method,
        url,
        noSucTip: conf.noSucTip,
        noErrTip: conf.noErrTip,
        hasErrorRes: conf.hasErrorRes,
        openApi: conf.openApi
      })
    )
    .catch(error => {
      // 去掉提示
      if (conf.noErrTip) {
        return false
      }
      if (conf.openApi) {
        return error.response.data
      }

      window.VueMainInstance.$Notice.error({
        title: '操作失败：',
        desc: error.code === 'ECONNABORTED' ? '网络连接超时，请重试' : error
      })
      return false
    })
}

export const get = (url, axiosObj, config) => {
  return doRquest({ url, method: 'get', axiosObj, config })
}

export const post = (url, axiosObj, config) => {
  return doRquest({ url, method: 'post', axiosObj, config })
}

export const postForm = (url, axiosObj, config) => {
  return doRquest({
    url,
    method: 'post',
    axiosObj,
    config: {
      ...config,
      ...{ headers: { 'Content-Type': 'multipart/form-data' }}
    }
  })
}

export const del = (url, axiosObj, config) => {
  return doRquest({ url, method: 'delete', axiosObj, config })
}

export const put = (url, axiosObj, config) => {
  return doRquest({ url, method: 'put', axiosObj, config })
}

export const postStream = (url, axiosObj, config) => {
  return doRquest({
    url,
    method: 'post',
    axiosObj,
    config: {
      ...config,
      ...{
        responseType: 'blob',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    }
  })
}
