import { get, post, put, del, postForm } from '@/libs/api.request'

// export const getSmsTpl = searchData => {
//   return get("v1/yunpian/template", searchData, { noErrTip: true });
// };
// 获取签名
export const getSmsTplSign = searchData => {
  return get('v1/message/sign', searchData)
}
export const getSignSuccess = searchData => {
  return get('v1/message/sign/success', searchData)
}
// 添加签名
export const addSmsTplSign = data => {
  return post('v1/message/sign', data)
}
// 删除短信签名
export const delSmsSign = data => {
  return del(`v1/message/sign/${data.id}`)
}
// 删除模版
export const delTemplate = data => {
  return del(`v1/message/template/${data.id}`)
}
// 获取模版
export const getSmsTpl = data => {
  return get('v1/message/template', data, { noSucTip: true, noErrTip: true })
}
export const getTplSuccess = data => {
  return get('v1/message/template/success', data)
}
// 添加模版
export const addTemplate = data => {
  return post('v1/message/template', data)
}
// 修改模版
export const updateTemplate = data => {
  return put('v1/message/template', data)
}
// 上传图片文件
export const uploadFile = data => {
  return postForm('/v1/message/upload', data, { noSucTip: true })
}
// 短信记录
export const smsList = data => {
  return get('/v1/smsSend', data, { noSucTip: true, noErrTip: true })
}
export const getAccount = data => {
  return get('v1/case/plan/message/account', data, { noSucTip: true, noErrTip: true })
}

export const getReplyList = data => {
  return get('v1/smsSend/replyList', data)
}

// 短信费用查询
export const getMessageFee = data => {
  return get('/v1/smsSend/fee', data)
}
// export const addSmsTpl = (smsTpl) => {
//   return post('v1/yunpian/template', smsTpl)
// }

// export const updateSmsTpl = (smsTpl) => {
//   return put('/v1/yunpian', smsTpl)
// }

// export const delSmsTpl = ({ id }) => {
//   return del(`/v1/yunpian/${id}`)
// }

// export const getSmsPlan = (planData) => {
//   return get('v1/smsSend', planData)
// }

// export const addSmsPlan = (planData) => {
//   return post('v1/smsSend', planData)
// }

export const sendSingleSms = (smsData) => {
  return post('v1/smsSend/single', smsData)
}

// 短信催员回复记录
export const smsReplyList = (data) => {
  return get('/v1/smsSend/smsReplyList', data)
}

// 添加短信自定义字段
export const addSmsField = (data) => {
  return post('/v1/sms/field/add', data)
}

// 获取短信自定义字段
export const getQueryList = (data) => {
  return post('/v1/sms/field/queryList', data, { noSucTip: true })
}
// 校验短信验证码
export const checkSmsCode = (data) => {
  return get('/v1/smsSend/checkSmsCode', data)
}
// 获取验证码
export const getSmsCode = (data) => {
  return get('/v1/smsSend/sendSmsCode', data)
}
