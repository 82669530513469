<template>
  <div v-if="userExtend.noticeSwitch" class="notice">
    <div class="title">
      <Icon type="iconfont icon-gonggao" />
    </div>
    <div class="scroll_box">
      <scrollAnnouncement>
        {{ announcementTiltle }}
        {{ announcementContent }}
      </scrollAnnouncement>
    </div>
  </div>
</template>

<script>
import { getNotice } from '@/api/org'
import { mapGetters } from 'vuex'
import scrollAnnouncement from '_vc/scrollAnnouncement/scrollAnnouncement.vue'
export default {
  components: {
    scrollAnnouncement
  },
  data() {
    return {
      announcementTiltle: '',
      announcementContent: ''
    }
  },
  computed: {
    ...mapGetters(['userExtend'])
  },
  created() {
    this.getNotice()
  },
  methods: {
    // 获取公告
    getNotice() {
      getNotice().then((res) => {
        if (res.data) {
          this.announcementContent = res.data.content
          this.announcementTiltle = `<span style='font-weight: 600;'>${res.data.name}</span>：`
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.notice {
  width: 100%;
  height: 39px;
  background-color: #fff3eb;
  margin-top: 8px;
  line-height: 39px;
  position: relative;
  margin-bottom: 16px;
  border-radius: 4px;
  .title {
    display: inline-block;
    width: 26px;
    position: absolute;
    top: 0px;
    left: 0px;
    color: #ff4f20;
    margin-left: 16px;
    font-size: 14px;
  }
  .scroll_box {
    display: inline-block;
    width: ~"calc(100% - 46px)";
    position: absolute;
    left: 46px;
    line-height: 42px;
  }
}
</style>
