import { put, get, post, del } from '@/libs/api.request'

export const getInspectors = (data) => {
  return get('v1/callCenter/inspectors', data)
}

export const setInspector = (data) => {
  return put('v1/callCenter/setInspectors', data)
}

export const getInspection = (data) => {
  return get('v1/callCenter/getInspection', data)
}

export const setInspection = (data) => {
  return post('v1/callCenter/setInspection', data)
}

export const getCallRecord = (data) => {
  if (data['startTime,endTime']) {
    const timerange = data['startTime,endTime'].split(',')
    data.startTime = Number(timerange[0])
    data.endTime = Number(timerange[1])
    delete data['startTime,endTime']
  }
  return get('v1/qos/call/record', data)
}

export const getCallDetail = (data) => {
  return get('v1/qos/call/detail', data)
}

export const getOperationDetail = (data) => {
  return get('v1/case/operation/detail', data)
}

export const putComment = (data) => {
  return put('v1/case/operation/comment', data)
}

export const getInspects = (data) => {
  return get('v1/user/inspect/team', data)
}

