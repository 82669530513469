import { getDictionary } from '@/api/data'
import { getDelts } from '@/api/delt'
import { getProd } from '@/api/product'
import { getBatch, getOutBatch } from '@/api/batch'
import { caseList } from '@/api/caseCtrl'
import { getAccount, adminList, getAllCounts } from '@/api/account'
import { getTeamList, getDepTeamList } from '@/api/department'
import { getAllTemplates } from '@/api/template'
import { getOrg, getOrgDet } from '@/api/mechanism'
import { getRoles } from '@/api/role'
import { getFiles } from '@/api/file'
import { getRobotVsite } from '@/api/robot'
import { getTplSuccess, getSmsTplSign } from '@/api/sms'
import {smsTemplateList,wechatTemplateList} from '@/api/workphone'
import { caseTagList } from '@/api/case'
import { getNoPageStrategy } from '@/api/strategy'
import { getRefundResult, orgConfigList, getEndTypeList } from '@/api/org'
import { getSales, businessType, getOrgWhite } from '@/api/orgAdmin'
import { getVisitors, getVisitTpl } from '@/api/visit'
import { getInspects } from '@/api/inspect'
import i18n from '@/locale'
import { getSysLetter, getSealListNoPage } from '@/api/letters'
import {
  getCommissionList
} from '@/api/report'
import { addRefund } from '@/api/refund'
import { spaceInfo } from '@/api/fileStorage'
import { getLawsuitConfig } from '@/api/lawsuit'
import { getNumberList } from '@/api/contact'
import { deepClone } from '@/libs/tools'
import router from '@/router'

/**
 * 获取有效数据
 * @param {*} commit
 * @param {*} func 接口api
 * @param {*} searchData 搜索过滤数据
 * @param {*} cacheData 缓存
 * @param {*} commitCmd 赋值方法 或者 string，方法直接执行 string 走commit
 */
const getAvailableData = (commit, func, searchData, cacheData, commitCmd) => {
  return new Promise((resolve, reject) => {
    if (cacheData && cacheData.length) {
      resolve(cacheData)
      return
    }

    try {
      func(searchData).then(res => {
        if (!res) {
          return false
        }

        if (typeof commitCmd === 'string') {
          const data = res.data
          commit(commitCmd, data.list || data || [])
        } else if (typeof commitCmd === 'function') {
          commitCmd(res)
        }

        resolve(true)
      }).catch(err => {
        reject(err)
      })
    } catch (error) {
      reject(error)
    }
  })
}

const $t = (key) => {
  return i18n.t(key)
}

export default {
  state: {
    map: {
      // 工作手机短信群发计划状态
      wpPlanStatus:[{value:3,name:'已取消', icon: 'iconfont icon-yiquxiao1', color: '#b0b7c2'},{value:1,name:'进行中', icon: 'iconfont icon-jinhangzhong1', color: '#ff6700'},{value:2,name:'已完成', icon: 'iconfont icon-yiwancheng1', color: '#ff6700'}],
      // SIM卡
      simCard:[{value:0,name:'自动选择'},{value:1,name:'sim卡1'},{value:2,name:'sim卡2'}],
      // 话术变量
      smsFields:
      [
        { value: 'deltname', name: $t('k_delt')},
        { value: 'productname', name:$t('k_caseProduct')},
        { value: 'caseno', name:$t('K_outSerialNo')},
        { value: 'casename', name: $t('obligor')+'姓名'},
        { value: 'idcard', name: $t('k_idCard')},
        { value: 'amount', name: $t('K_outSetialMoney')},
        { value: 'payamount', name:'已还金额'},
        { value: 'overduedate', name:$t('k_overdueTime')},
        { value: 'overduedays', name: $t('k_overdueDays')},
        { value: 'entrustendtime', name:$t('k_baileEndTime')},
        { value: 'U_contact', name: '联系方式' },
        { value: 'linkid', name: '函件文件链接' },
        { value: 'companyname', name: '单位名称' }
      ],
      // 合作状态
      partnership: [{ name: $t('trialCase'), value: 0 }, { name: $t('cooperating'), value: 1 }, { name: $t('pauseCooperation'), value: 2 }, { name: $t('endCooperation'), value: 3 }],
      // 产品类型
      productType: [{ name: $t('consumerFinance'), value: 0 }, { name: $t('creditCard'), value: 1 }, { name: $t('mortgageLoan'), value: 2 }, { name: $t('commercialLoan'), value: 3 }, { name: $t('carLoan'), value: 4 }, { name: $t('profee'), value: 5 }],
      //
      templateType: [{ name: $t('caseBaseInfoTp'), value: 0 }, { name: $t('caseUpadateTp'), value: 1 }, { name: $t('repayMentTp'), value: 2 }, { name: $t('addressListTp'), value: 3 }, { name: `${$t('reminder')}导出模板`, value: 4 }, { name: '案件导出模板', value: 5 }, { name: `${$t('reminder')}导入模板`, value: 6 }, { name: '案件信息模板(用于还款、减免导出)', value: 7 }, { name: '外访导出模板', value: 8 }, { name: '员工导入模板', value: 11 }, { name: '减免模板', value: 12 }],

      // 模版状态
      templateStatus: [{ name: $t('import'), value: 0 }, { name: $t('export'), value: 1 }],
      // 模板属性
      templateProp: [{ name: $t('publicTp'), value: 0 }, { name: $t('customTp'), value: 1 }],
      // 案件颜色
      caseColor: [{ name: $t('normal'), value: 0 }, { name: $t('markRed'), value: 1 }, { name: $t('markBlue'), value: 2 }],
      // 结案原因
      caseStopReason: [{ name: $t('normal_caseDue'), value: 0 }, { name: $t('abnormal_recellAdvance'), value: 1 }, { name: $t('abnormal_specialCase'), value: 2 }],

      // 案件处理方式 -1~5 对应caseStatus案件状态的；  -2为具体操作
      caseHandleMode: [{ name: $t('leaveCase'), value: 4 }, { name: $t('closeCase'), value: 1 }, { name: $t('stopTalkOver'), value: 2 }, { name: $t('recoverTalkOver'), value: 5 }, { name: $t('retireCase'), value: 8 }, { name: $t('destroy'), value: -1 }, { name: $t('caseAdjust'), value: -2, icon: 'iconfont icon-piliangtiaozheng' }, { name: $t('redistribute'), value: -3, icon: 'iconfont icon-zhongxinfenpei' }],
      teamCaseHandleMode: [{ name: $t('caseAdjust'), value: -2, icon: 'iconfont icon-piliangtiaozheng' }, { name: $t('redistribute'), value: -3, icon: 'iconfont icon-zhongxinfenpei' }],
      // 案件状态
      caseAllot: [{ name: $t('destroy'), value: 1 }, { name: $t('closeCase'), value: 2 }, { name: $t('pause_one'), value: 3 }, { name: $t('leaveCase'), value: 4 }, { name: $t('recoverTalkOver'), value: 5 }, { name: $t('retireCase'), value: 6 }],
      caseStatus: [{ name: '加入待发函件', value: 90 }, { name: $t('Doing'), value: -11 }, { name: $t('unallocated'), value: 0 }, { name: $t('destroy'), value: -1 }, { name: $t('closeCase'), value: 1 }, { name: $t('pause_one'), value: 2 }, { name: $t('dividedCase'), value: 3 }, { name: $t('leaveCase'), value: 4 }, { name: $t('recoverTalkOver'), value: 5 }, { name: $t('caseToTeam'), value: 6 }, { name: '分案至分公司', value: 7 }, { name: $t('retireCase'), value: 8 }, { name: $t('caseAdjust'), value: 20 }, { name: $t('delete'), value: 30 }, { name: $t('deleteTh'), value: 31 }, { name: $t('emptyTrash'), value: 32 }, { name: $t('redistribute'), value: 40 }, { name: '案件恢复', value: 50 }, { name: $t('coordinate'), value: 60 }, { name: `修改${$t('collection')}进程`, value: 70 }, { name: '添加标签', value: 80 }, { name: '加入诉讼', value: 100 }],
      caseStates: [{ name: $t('unallocated'), value: 0 }, { name: $t('destroy'), value: 5 }, { name: $t('closeCase'), value: 4 }, { name: $t('pause_one'), value: 3 }, { name: $t('dividedCase'), value: 1 }, { name: $t('leaveCase'), value: 2 }, { name: $t('retireCase'), value: 8 }, { name: $t('caseToTeam'), value: 6 }, { name: '分案至分公司', value: 7 }],
      caseStatesNoTeam: [{ name: $t('unallocated'), value: 0 }, { name: $t('destroy'), value: 5 }, { name: $t('closeCase'), value: 4 }, { name: $t('pause_one'), value: 3 }, { name: $t('dividedCase'), value: 1 }, { name: $t('leaveCase'), value: 2 }, { name: $t('retireCase'), value: 8 }, { name: '案件恢复', value: 50 }],
      // 案件分配状态
      caseAssignStatus: [{ name: $t('all'), value: 0 }, { name: $t('allocated'), value: 1 }, { name: $t('unallocated'), value: 2 }],
      // 催收结果
      refundResult: [],
      // 催收进程
      operationState: [],

      statusResult: [{ name: '待处理', value: 0, icon: 'iconfont icon-daichuli', color: '#ffb74d' }, { name: '处理中', value: 3, icon: 'iconfont icon-chulizhong1', color: '#B0B7C2' }, { name: '过期', value: 1, icon: 'iconfont icon-guoqi' }, { name: '完成', value: 2, icon: 'iconfont icon-wancheng', color: '#FF4F1F' }, { name: '删除', value: -1, icon: 'iconfont icon-shanchutubiao', color: '#F44F22' }, { name: '失败', value: -2, icon: 'iconfont icon-guanbixiankuang' }, { name: '已取消', value: 4, icon: 'iconfont icon-yiquxiao' }],
      stateResult: [{ name: '处理中', value: 0 }, { name: '成功', value: 1 }, { name: '失败', value: -1 }],

      // 导出列表任务类型
      letterType: [{ name: '创建函件导出', value: 0 }, { name: '待发函导出', value: 1 }, { name: '发函记录导出', value: 2 }, { name: '发函文件导出', value: 3 }],

      // 电话结果
      refundCallResult: [],

      // 还款状态
      returnStatus: [{ name: $t('normal'), value: 0 }, { name: $t('destroy'), value: -1 }],

      // 批次状态
      batchType: [{ name: $t('normal'), value: 0 }, { name: $t('stopTalkOver'), value: 1 }, { name: $t('retireCase'), value: 2 }, { name: $t('leaveCase'), value: 3 }, { name: $t('closeCase'), value: 4 }, { name: $t('destroy'), value: 5 }],
      outBatchType: [{ name: $t('normal'), value: 0, icon: 'iconfont icon-wancheng', color: '#FF4F1F' }, { name: $t('hasDeleted'), value: -1, icon: 'iconfont icon-yizuofei' }],
      // 案件分配 {name:'综合分配', value: 0},
      assginCaseWeight: [{ name: '平均分案', value: 0 }, { name: '比例分案', value: 1 }],

      // 案件策略分配 {name:'综合分配', value: 0},
      assginCaseStrategy: [{ name: $t('allocateByCount'), value: 1 }, { name: $t('allocateByMent'), value: 2 }, { name: $t('unAllocate'), value: -1 }],

      // 创建批次模式
      createBatchMode: [{ name: $t('autoCreate'), value: 0 }, { name: $t('manualCreate'), value: 1 }],
      // 机构性质
      mechanismType: [{ name: $t('headOrg'), value: 0 }, { name: $t('subAccount'), value: 1 }],
      // 上传状态
      fileImportStatus: [{ name: $t('importing'), value: 0 }, { name: $t('imported'), value: 1 }, { name: $t('warehouseFail'), value: 2 }, { name: $t('unimported'), value: -1 }],
      // 更新记录状态
      fileUpdateStatus: [{ name: $t('updateFail'), value: -1 }, { name: $t('updating'), value: 0 }, { name: $t('updateCompleted'), value: 1 }],

      // 案件操作状态
      operationStatus: [{ name: $t('created'), value: 0 }, { name: $t('closeCase'), value: 1 }, { name: $t('stopTalkOver'), value: 2 }, { name: $t('division'), value: 3 }, { name: $t('recoverTalkOver'), value: 4 }, { name: $t('generalUpdate'), value: 5 }, { name: $t('repay'), value: 6 }, { name: $t('destroy'), value: 7 }, { name: $t('leaveCase'), value: 8 }, { name: $t('retireCase'), value: 9 }, { name: $t('caseAdjust'), value: 10 }, { name: $t('delete'), value: 11 }, { name: $t('deleteTh'), value: 12 }, { name: $t('caseRecover'), value: 13 }, { name: $t('redistribute'), value: 14 }, { name: `修改${$t('collection')}进程`, value: 18 }, { name: '案件自动回收', value: 19 }, { name: '添加标签', value: 20 }, { value: 21, name: '案件添加函件' }, { value: 22, name: '生成函件文件' }, { value: 23, name: '移除函件' }, { value: 24, name: '寄送函件' }, { value: 25, name: '案件锁定' }, { value: 26, name: '取消案件锁定' }, { value: 28, name: '加入诉讼' }],
      // 减免状态
      reduceStatus: [{ name: $t('applying'), value: 0 }, { name: $t('pass'), value: 1 }, { name: $t('refuse'), value: 2 }],

      // 计划状态
      planStatus: [{ name: '创建中', value: 'NEW', icon: 'iconfont icon-chulizhong1', color: '#FFB74D' }, { name: $t('k_uploading'), value: 'UPLOADING', icon: 'iconfont icon-daichuli', color: '#FFB74D' }, { name: $t('notStarted'), value: 'NOT_STARTED', icon: 'iconfont icon-guoqi', color: '#0091FF' }, { name: $t('inProgress'), value: 'IN_PROGRESS', icon: 'iconfont icon-jinhangzhong1', color: '#FF6700' }, { name: $t('paused'), value: 'PAUSED', icon: 'iconfont icon-yizanting', color: '#FFB74D' }, { name: $t('cancelled'), value: 'CANCELLED', icon: 'iconfont icon-yiquxiao', color: '#B0B7C2' }, { name: $t('finished'), value: 'FINISHED', icon: 'iconfont icon-wancheng', color: '#FF4F1F' }, { name: '创建失败', value: 'FAIL', icon: 'iconfont icon-guanbixiankuang', color: '#B0B7C2' }, { name: '执行失败', value: 'DUYAN_FAIL', icon: 'iconfont icon-guanbixiankuang', color: '#B0B7C2' }],

      // 客户标签

      customLabel: [{ name: 'A', value: 'A' }, { name: 'B', value: 'B' }, { name: 'C', value: 'C' }, { name: 'D', value: 'D' }, { name: 'E', value: 'E' }, { name: '无标签', value: 'notag' }],

      // 审核状态
      // '审核状态：-1：未通过，0：审核中，1：审核成功，2：导入成功',
      applystatus: [{ name: '待审核', value: 0 }, { name: '审核通过', value: 1 }, { name: '审核未通过', value: -1 }],
      // 还款审核状态
      paymentApplyStatus: [{ name: '待审核', value: 0 }, { name: '审核通过', value: 1 }, { name: '审核未通过', value: -1 }, { name: '导入完成', value: 2 }],

      // 减免审核状态
      reductionApplystatus: [{ name: '待审核', value: 0 }, { name: '审核通过', value: 1 }, { name: '审核未通过', value: 2 }],

      // 协办审核状态
      assistApplystatus: [{ name: '删除', value: -1 }, { name: '待审核', value: 0 }, { name: '待回复', value: 1 }, { name: '已回复', value: 2 }, { name: '审核未通过', value: 3 }],

      // 信息修复审核状态
      infoFixStatus: [{ name: '待审核', value: 2 }, { name: '审核通过', value: 0 }, { name: '审核通过', value: 1 }, { name: '审核通过', value: -1 }, { name: '审核未通过', value: -2 }],

      // 信息修复审核状态
      infoRepairStatus: [{ name: '审核中', value: 2 }, { name: '修复中', value: 0 }, { name: '修复成功', value: 1 }, { name: '修复失败', value: -1 }, { name: '审核未通过', value: -2 }],

      // 信修任务进程
      infoFixState: [{ name: '修复中', value: 1 }, { name: '已完成', value: 1 }, { name: '已完成', value: 3 }],

      // 信修任务结果
      infoFixResult: [{ name: '修复中', value: 0 }, { name: '修复成功', value: 1 }, { name: '修复失败', value: -1 }],

      // 度言电话结果
      outcome: [{ name: '失败', value: 'FAIL' }, { name: '成功', value: 'SUCCESS' }, { name: '用户忙', value: 'USER_BUSY' }, { name: '关机', value: 'POWER_OFF' }, { name: '停机', value: 'SUSPENDED' }, { name: '不存在', value: 'NOT_EXIST' }, { name: '无人接听', value: 'NO_RESPONSE' }],

      // 呼叫类型
      callType: [{ name: $t('inBound'), value: 0 }, { name: $t('outBound'), value: 1 }],
      // 机器人呼叫类型
      robotCallType: [{ name: `机器人智能${$t('coordinate')}`, value: 1 }, { name: '坐席外呼', value: 0 }, { name: '预测式外呼', value: 2 }, { name: '工作手机外呼', value: 3 }],
      // 优先级
      planPriority: [{ name: '高', value: 'HIGH', icon: 'iconfont icon-gao', color: '#F2CB51' }, { name: '中', value: 'NORMAL', icon: 'iconfont icon-zhong', color: '#F2CB51' }, { name: '低', value: 'LOW', icon: 'iconfont icon-di', color: '#F2CB51' }],
      // 案件导入顺序
      sortByImport: [{ name: $t('ascByImport'), value: 1 }, { name: $t('descByImport'), value: 2 }],

      // 报表导出状态
      reportStatus: [{ name: $t('waiting'), value: 0, icon: 'iconfont icon-chulizhong1' }, { name: $t('outOfDate'), value: 1, icon: 'iconfont icon-wuxiao', size: 18 }, { name: $t('finished'), value: 2, icon: 'iconfont icon-wancheng', color: '#FF4F1F' }, { name: $t('delete'), value: -1, icon: 'iconfont icon-shanchutubiao', color: '#F44F22' }, { name: $t('exportFailed'), value: -2, icon: 'iconfont icon-guanbixiankuang' }],

      // 角色
      role: [],
      // 发送状态
      sendStatus: [{ name: '发送中', value: -1, icon: 'iconfont icon-daichuli', color: '#FFB74D' }, { name: '成功', value: 1, icon: 'iconfont icon-wancheng', color: '#FF4F1F' }, { name: '失败', value: 0, icon: 'iconfont icon-guanbixiankuang', color: '#B0B7C2' }],
      // 待发送函件状态
      waitStatus: [{ name: '发送失败', value: -1, icon: 'iconfont icon-guanbixiankuang', color: '#B0B7C2' }, { name: '待发送', value: 0, icon: 'iconfont icon-chulizhong1', color: '#FFB74D' }, { name: '发送中', value: 1, icon: 'iconfont icon-waifangzhong', color: '#FFB74D' }, { name: '已发送', value: 2 }],

      // 接收状态
      receiveStatus: [
        { name: '接收中', value: -1, icon: 'iconfont icon-waifangzhong', color: '#FFB74D' },
        { name: '成功', value: 1, icon: 'iconfont icon-wancheng', color: '#FF4F1F' },
        { name: '失败', value: 0, icon: 'iconfont icon-guanbixiankuang', color: '#B0B7C2' },
        { name: '未知', value: 2, icon: 'iconfont icon-yiquxiao1', color: '#B0B7C2' }
      ],
      // 审核状态
      auditState: [{ name: $t('waitAudit'), value: 0, icon: 'iconfont icon-daichuli', color: '#FFB74D' }, { name: $t('auditSuccess'), value: 1, icon: 'iconfont icon-wancheng', color: '#FF4F1F' }, { name: $t('auditFail'), value: 2, icon: 'iconfont icon-guanbixiankuang', color: '#B0B7C2' }, { name: $t('waitAudit'), value: 3, icon: 'iconfont icon-daichuli', color: '#FFB74D' }],

      // 公司层级
      depType: [{ value: 5, name: '总公司' }, { value: 4, name: '分公司' }, { value: 3, name: '小组' }],

      // 案件分配状态
      caseAssign: [{ value: true, name: $t('assigning') }, { value: false, name: $t('unAssign') }],

      // 案件标色
      caseStandardColor: [{ value: 1, name: '红色', color: '#F43F3F', textColor: '#F52C22', bgColor: 'rgba(245, 44, 34, .18)' }, { value: 2, name: '橙色', color: '#FFA63B', textColor: '#CE8237', bgColor: 'rgba(248, 162, 77, .18)' }, { value: 3, name: '黄色', color: '#FFD530', textColor: '#E0B329', bgColor: 'rgba(247, 206, 82, .18)' }, { value: 4, name: '绿色', color: '#61CB42', textColor: '#44B54A', bgColor: 'rgba(95, 207, 101, .18)' }, { value: 5, name: '蓝色', color: '#2599F1', textColor: '#458EF7', bgColor: 'rgba(69, 142, 247, .18)' }, { value: 6, name: '紫色', color: '#CB4AE1', textColor: '#B370D4', bgColor: 'rgba(179, 112, 212, .18)' }, { value: 7, name: '灰色', color: '#CCCCCC', textColor: '#858592', bgColor: 'rgba(157, 157, 161, .18)' }],
      // 案件标签
      caseColors: [{ value: 1, name: '红色', color: '#F43F3F' }, { value: 2, name: '橙色', color: '#FFA63B' }, { value: 3, name: '黄色', color: '#FFD530' }, { value: 4, name: '绿色', color: '#61CB42' }, { value: 5, name: '蓝色', color: '#2599F1' }, { value: 6, name: '紫色', color: '#CB4AE1' }, { value: 7, name: '灰色', color: '#CCCCCC' }],

      // 案件导入类型
      caseImportType: [{ value: 0, name: 'EXCEL导入' }, { value: 1, name: '接口导入' }],

      // 案件状态更新类型
      caseUpdateType: [{ value: 0, name: '平台任务' }, { value: 1, name: '接口调用创建任务' }],

      // 还款导出任务状态
      refundExportStatus: [{ value: 0, name: '待处理', icon: 'iconfont icon-daichuli', color: '#FFB74D' }, { value: 3, name: '处理中', icon: 'iconfont icon-waifangzhong', color: '#FF4F1F' }, { value: 1, name: '过期', icon: 'iconfont icon-guoqi' }, { value: 2, name: '完成', icon: 'iconfont icon-wancheng', color: '#FF4F1F' }, { value: -1, name: '删除', icon: 'iconfont icon-shanchutubiao', color: '#F44F22' }, { value: -2, name: '失败', icon: 'iconfont icon-guanbixiankuang', color: '#B0B7C2' }, { value: 4, name: '已取消', icon: 'iconfont icon-yiquxiao', color: '#B0B7C2' }],

      // 申请案件修改状态
      applyStatus: [{ value: 0, name: $t('leaveCase') }, { value: 1, name: $t('closeCase') }, { value: 2, name: $t('retireCase') }],
      applyStatuses: [{ value: 0, name: '申请留案' }, { value: 1, name: '申请结案' }, { value: 2, name: '申请退案' }],

      // 申请案件修改状态审核状态
      applyCheckStatus: [{ value: 0, name: '待审核' }, { value: 1, name: '审核通过' }, { value: 2, name: '审核未通过' }, { value: 3, name: '已取消' }],

      // 共债判断方式
      conjointTypes: [{ value: 1, name: `${$t('obligor')}身份证号` }, { value: 2, name: `${$t('obligor')}手机号` }, { value: 0, name: '不判断共债' }],
      // 催记来源
      createTypes: [{ value: 0, name: `员工提交` }, { value: 1, name: `${$t('reminder')}导入` }],

      // 地址状态
      addressStatus: [{ value: 1, name: '有效' }, { value: -1, name: '无效' }, { value: 0, name: '未知' }],
      // 地址来源
      addressResource: [{ value: 0, name: '导入' }, { value: 1, name: '添加' }, { value: 2, name: '接口导入' }],
      // 地址类型
      addressTypes: [{ value: 0, name: '家庭地址' }, { value: 1, name: '住宅地址' }, { value: 2, name: '单位地址' }, { value: 3, name: '户籍地址' }, { value: 4, name: '对账单地址' }, { value: 5, name: '其他地址' }],
      // 外访审核状态
      outvisitAuditStatus: [{ value: 0, name: '待审核' }, { value: 1, name: '审核通过' }, { value: -1, name: '审核拒绝' }, { value: -2, name: '已取消' }],
      // 外访状态
      outvisitStatus: [{ value: 0, name: '待外访', icon: 'iconfont icon-daichuli', color: '#FFB74D' }, { value: 1, name: '外访中', icon: 'iconfont icon-waifangzhong', color: '#FF4F1F' }, { value: 2, name: '已完成', icon: 'iconfont icon-wancheng', color: '#FF4F1F' }, { value: -1, name: '已过期', icon: 'iconfont icon-guoqi', color: '#B0B7C2' }, { value: -2, name: '已取消', icon: 'iconfont icon-guanbixiankuang', color: '#B0B7C2' }],
      // 外访签到状态
      signInStates: [{ value: 0, name: '未签到' }, { value: 1, name: '已签到' }],
      // 协催申请模式
      cooperationWays: [{ value: 0, name: '管理员审核' }, { value: 1, name: `${$t('agent')}抢单` }, { value: 2, name: `${$t('agent')}接单` }],
      // 协催状态
      cooperationState: [{ value: 0, name: `待办${$t('coordinate')}`, icon: 'iconfont icon-daichuli', color: '#FFB74D' }, { value: 1, name: `${$t('coordinate')}完成`, icon: 'iconfont icon-wancheng', color: '#FF4F1F' }],
      // 分区
      duyanServiceKey: [{ value: 'null', name: '一区' }, { value: '23jder', name: '二区' }],
      // 导出任务类型
      exportTypes: [{ value: 1, name: `${$t('reminder')}导出` }, { value: 2, name: '案件导出' }, { value: 3, name: '还款报表导出' }, { value: 4, name: `${$t('collection')}报表导出` }, { value: 5, name: '还款导出' }, { value: 6, name: '减免导出' }, { value: 7, name: '外访导出' }, { value: 8, name: '外访附件导出' }],
      // 客户类型
      customType: [{ value: 1, name: '甲方' }, { value: 0, name: '乙方' }],
      // 开通功能
      switches: [{ name: '外访', value: 'visitSwitch' }, { name: '外访app', value: 'miniProgramsSwitch' }, { name: '短信', value: 'messageSwitch' }, { name: '呼叫中心', value: 'callCenterSwitch' }],
      // 催记导出类型
      recordTypes: [{ value: 1, name: $t('reminder') }, { value: 13, name: '录音' }],
      // 联系人状态
      contactStatus: [{ value: 0, name: '正常', icon: 'iconfont icon-wancheng', color: '#FF4F1F' }, { value: 1, name: '无效', icon: 'iconfont icon-shixiao' }],
      // 联系人操作类型
      contactActions: [{ value: 0, name: '编辑' }, { value: 1, name: '删除' }, { value: 2, name: '修改状态' }],
      // 公司账户类型
      accountTypes: [{ value: 1, name: '正式' }, { value: 0, name: '测试' }],
      // 函件模板类型
      lettersType: [{ value: 0, name: '律师函' }],
      // 发函方式
      sendLetterType: [{ value: 1, name: '短信发送' }, { value: 2, name: '邮箱发送' }, { value: 3, name: '短信和邮箱' }, { value: 0, name: '线下邮寄' }],
      // 发送方式
      sendLettersType: [{ value: 0, name: '线下邮寄' }, { value: 1, name: '短信' }, { value: 2, name: '邮箱' }, { value: 3, name: '短信+邮箱' }],

      // 函件签收状态
      letterReceiveStatus: [{ value: 1, name: '已签收' }, { value: 0, name: '未签收' }, { value: -1, name: '返回' }],
      // 函件操作类型
      oplogLetters: [{ value: 15, name: '生成函件文件' }, { value: 16, name: '移除函件' }, { value: 17, name: '寄送函件' }, { value: 26, name: '删除线上函件' }, { value: 30, name: '生成线下函件' }, { value: 31, name: '删除线下函件' }],
      // 函件异步处理状态
      letterStatus: [{ value: 0, name: '处理中', icon: 'iconfont icon-chulizhong1', color: '#FFB74D' }, { value: 1, name: '处理完成', icon: 'iconfont icon-wancheng', color: '#FF4F1F' }, { value: -1, name: '失败', icon: 'iconfont icon-guanbixiankuang', color: '' }],
      // 公告发布类型
      releaseTypes: [{ value: 0, name: '手动' }, { value: 1, name: '定时' }],
      // 问题反馈类型
      feedbackType: [{ value: 0, name: '功能建议' }, { value: 1, name: '问题反馈' }],
      // 质检状态
      qualityState: [{ value: 0, name: '未质检' }, { value: 2, name: '已实时质检' }, { value: 3, name: '已人工质检' }, { value: 4, name: '智能质检中' }, { value: 5, name: '已智能质检' }],
      // 质检是否预警
      isEarlyWarnings: [{ value: 1, name: '是' }, { value: 0, name: '否' }],
      // 质检是否挂断
      isHangUps: [{ value: 1, name: '是' }, { value: 0, name: '否' }],
      // 语言包类型
      languages: [{ value: 'null', name: '催收' }, { value: 'tj', name: '调解' }],
      // 调解审核状态
      mediateStatus: [{ value: 0, name: '待审批', icon: 'iconfont icon-waifangzhong', color: '#FF4F1F' }, { value: 1, name: '审核通过', icon: 'iconfont icon-wancheng', color: '#FF4F1F' }, { value: 2, name: '失效', icon: 'iconfont icon-yiquxiao', color: '#B0B7C2' }, { value: -1, name: '审核拒绝', icon: 'iconfont icon-guanbixiankuang', color: '#B0B7C2' }, { value: -2, name: '已取消', icon: 'iconfont icon-yiquxiao', color: '#B0B7C2' }],
      // 调解发起状态
      startStatuses: [{ value: 0, name: '待发起' }, { value: 1, name: '已发起' }],
      // 调解签署状态
      signFlowStatus: [{ value: 0, name: '待发起' }, { value: 1, name: '签署中', icon: 'iconfont icon-waifangzhong', color: '#FF4F1F' }, { value: 2, name: '已完成', icon: 'iconfont icon-wancheng', color: '#FF4F1F' }, { value: 7, name: '已拒签', icon: 'iconfont icon-guanbixiankuang', color: '#B0B7C2' }],
      signFlowStatuses: [{ value: 0, name: '未签署' }, { value: 1, name: '已签署' }, { value: 2, name: '已拒签' }],
      // 签署方签署状态
      signerStatus: [{ value: 1, name: '未签署', icon: 'iconfont icon-weiqianshu', color: '#B2B2B2' }, { value: 2, name: '签署完成', icon: 'iconfont icon-wancheng', color: '#FF4F1F' }, { value: 3, name: '签署失败', icon: 'iconfont icon-guanbixiankuang', color: '#B0B7C2' }, { value: 4, name: '已拒签', icon: 'iconfont icon-guanbixiankuang', color: '#B0B7C2' }],
      // 调解催签方式
      noticeTypes: [{ value: 0, name: '短信' }, { value: 1, name: '邮件' }],
      // 调解印章类型
      sealBizTypes: [{ value: 'PUBLIC', name: '公章' }, { value: 'CONTRACT', name: '合同专用章' }, { value: 'LEGAL_PERSON', name: '法定代表人章' }, { value: 'FINANCE', name: '财务章' }, { value: 'INVOICE', name: '发票专用章' }, { value: 'PERSONNEL', name: '人事专用章' }, { value: 'CANCELLATION', name: '作废章' }, { value: 'COMMON', name: '其它' }],
      // 分案对象
      allotObjects: [{ value: 0, name: $t('k_agent') }, { value: 1, name: '小组' }, { value: 2, name: '分公司' }, { value: 3, name: '委外机构' }],
      // 操作列表分案对象
      operTypes: [{ value: 1, name: '策略分案' }, { value: 0, name: '手动分案' }],
      // 印章审核状态
      sealAuditStates: [{ value: 0, name: '待审核', icon: 'iconfont icon-daichuli', color: '#FFB74D' }, { value: 1, name: '审核通过', icon: 'iconfont icon-wancheng', color: '#FF4F1F' }, { value: -1, name: '审核拒绝', icon: 'iconfont icon-guanbixiankuang', color: '#B0B7C2' }],
      // 诉讼审批状态
      lawsuitStatus: [{ value: 0, name: '待审批', icon: 'iconfont icon-chulizhong1', color: '#FFB74D' }, { value: 1, name: '审批通过', icon: 'iconfont icon-wancheng', color: '#FF4F1F' }, { value: -1, name: '审批拒绝', icon: 'iconfont icon-guanbixiankuang', color: '#B0B7C2' }],
      // 诉讼案件状态
      lawsuitCaseStatus: [{ value: 0, name: '办案' }, { value: 1, name: '结案' }],
      // 诉讼案件来源
      lawsuitSources: [{ value: 0, name: '管理员加入' }, { value: 1, name: '诉讼申请' }],
      // 付款方式
      payTypes: [{ value: 0, name: '现金' }, { value: 1, name: '网上支付' }, { value: 2, name: '支票' }, { value: 3, name: '邮政汇款' }, { value: 4, name: '银行电汇' }, { value: 5, name: '其他' }],
      // 资产类型
      assetTypes: [{ value: 0, name: '房产' }, { value: 1, name: '车辆' }, { value: 2, name: '其他' }],
      // 外访管理操作类型
      visitManagement: [{ value: 12, name: '删除附件' }, { value: 23, name: '批量取消外访' }, { value: 24, name: '批量调整外访' }],
      // 结案类型
      closedTypes: [{ value: 1, name: `${$t('collection')}成功` }, { value: 0, name: `${$t('collection')}失败` }],
      // 短信模板变量
      smsTemplateFields: [],
      // 邮箱模板变量
      emailTemplateFields: [],
      // 管控状态
      ctrlStatuses: [{ value: 0, name: '未达标', icon: 'iconfont icon-guanbixiankuang', color: '#B0B7C2' }, { value: 1, name: '已达标', icon: 'iconfont icon-wancheng', color: '#FF4F1F' }],

      caseTplFields: {
        operationBase: {},
        // 催计
        operation: [],
        // 催计必须
        operationRequired: [],
        // 案件更新字段
        update: [],
        // 必须部分
        updateRequired: [],
        // 案件个人基础信息字段
        userBase: [],
        // 必须部分
        userBaseRequired: [],
        // 案件更新字段map:  key: name
        upmap: {},
        // base map
        base: {},
        // base user
        user: {},
        // caseInfo extends
        caseInfoExtends: [],
        // caseInfo required,
        caseInfoExtendsRequired: []
      },

      orgStates: [{ value: 0, name: '启用' }, { value: -1, name: '禁用' }], // 机构状态
      authSwitches: [{ value: 'fifoSwitch', name: '预测式外呼' }, { value: 'robotSwitch', name: `机器人${$t('coordinate')}` }, { value: 'smsSwitch', name: '短信' }, { value: 'visitSwitch', name: '外访' }], // 授权功能

      // 根据域名改文案
      langPage: {},
      // 债务人选项
      debtor: [{ value: 0, name: '全部本人号码（相同的过滤)' }, { value: 1, name: '随机本人号码' }, { value: 2, name: '最新本人号码' }],
      // 浦发减免申请原因
      applyReason: [{ value: 'JJKN', name: '经济困难' }, { value: 'SWDC', name: '死亡客户且第三方代偿' }, { value: 'SFJGKZ', name: '被司法机关采取强制措施' }, { value: 'KHTSL', name: '客户投诉类' }, { value: 'ZDJB', name: '客户或其直系亲属罹患重大疾病' }],
      noticeType: [{ name: '被邀请人自主搜索', value: 0 }],
      // 外访自定义字段类型
      visitFieldTypes: [{ value: 0, name: '文本' }, { value: 1, name: '下拉框' }, { value: 2, name: '下拉框多选' }, { value: 3, name: '数字' }, { value: 4, name: '日期' }],
      // 处理状态
      handleStatus: [{ value: 1, name: '履约结案' }, { value: 2, name: '系统违约结案' }, { value: 3, name: '人工违约结案' }, { value: 4, name: '减免过程中' }, { value: 5, name: '减免待处理' }, { value: 6, name: '减免拒绝' }],
      // 调解状态
      mediateCaseStatus: [{ value: 0, name: '等待调解' }, { value: 1, name: '调解中' }, { value: 2, name: '签署中' }, { value: 3, name: '调解成功' }, { value: 4, name: '调解失败' }, { value: 5, name: '移交立案' }],
      // 明文审核状态
      plainStatus: [{ value: 0, name: '待审核', icon: 'iconfont icon-chulizhong1', color: '#FFB74D' }, { value: 1, name: '审核通过', icon: 'iconfont icon-wancheng', color: '#FF4F1F' }, { value: 2, name: '已取消', icon: 'iconfont icon-yiquxiao', color: '#B0B7C2' }, { value: -1, name: '审核拒绝', icon: 'iconfont icon-guanbixiankuang', color: '#B0B7C2' }],
      // 发函文件操作记录操作
      sendLetterTypes: [],
    },

    // 委托公司
    delt: [],
    allDelt: [],
    // 委托产品
    product: [],
    // 催收结果
    refundResult: [],
    // 催收进程
    operationState: [],
    // 电话结果
    refundCallResult: [],
    // 批次
    batch: [],
    // 委案方批次
    outBatch: [],
    outBatchIds: [],
    // 所有催员，无权限
    allCounts: [],
    // 催员
    account: [],
    // 调解员
    mediator: [],
    // 同一小组催员
    sameTeamAccount: [],
    // 分公司
    department: [],
    // 委外机构
    outsource: [],
    // 分公司 + 委外机构
    deps: [],
    // 分公司，包含直属小组
    depOrg: [],
    // 团队
    team: [],
    // 所有团队，无权限
    allTeams: [],
    // 以duyanAccountId为value的催员列表
    duyanAgent: [],
    // 以duyanReferId为value的小组列表
    duyanTeams: [],
    // 催员选择小组项
    agentTeams: [],
    // 有效模板
    template: [],
    // 有效短信模板
    smsTemplate: [],
    // 公司
    org: [],
    // 总公司
    headOrg: [],
    // 上传文件
    file: [],
    // 话术页面
    vsite: [],
    // 员工列表那块的下拉角色选择
    strategy: [],
    // 佣金比例设置列表
    commissionList: [],
    // 分案策略
    adminRole: [],
    // 外访员
    visitors: [],
    // 设置小组长
    set: {
      callbackData: null
    },
    ctiCall: {
      uuid: '',
      oldUuid: '',
      callStyle: 1,
      urgeRefund: false, // 是否在填写催记, = false 没有打开催记， = caseId 已经在催收
      callbackData: null,
      isReady: false,
      refundForm: null,
      phonelines: [],
      originCtiCall: '',
      startTime: null, // 电话connecting时间
      successTime: null, // 电话接通时间
      endTime: null, // 电话结束时间
      refundedCase: {} // key为uuid，value为caseId，记录所有打过电话的催记
    },
    qosCallUuid: '',
    sms_sign: [],
    smsScript:[],//短信话术模板
    wechatScript:[],//微信话术模板
    // 销售
    sales: [],
    caseTagArr: [],
    allOrgs: [],
    // 公司业务类型
    businessTypes: [],
    isInCalling: {},
    // 函件系统模板
    letterSystem: [],
    // 调解模板
    tjSystem: [],
    // 印章列表
    sealList: [],
    // 当前呼出电话的相关信息
    curOutbounce: {},
    // 呼出电话锁页面
    isOutbounceLock: false,
    // 切换页面等待联系人接口返回后才可以继续切换
    isChangeCase: false,
    // 公司白名单
    orgWhites: [],
    // 是否显示通知
    isNotification: false,
    // 是否显示帮助中心
    isHelping: false,
    // 是否正在通话中
    isInCall: false,
    inspectTeams: [],
    inspectAgents: [],
    isManageRefund: false, // 管理员填催记
    caseCtrlList: [],
    // 诉讼法院
    court: [],
    // 诉讼进度
    lawsuitProcess: [],
    // 诉讼案件类型
    lawsuitCaseTypes: [],
    // 款项类别
    categories: [],
    // 查封轮次
    seiTurns: [],
    // 查封阶段
    seiStages: [],
    // 诉讼文件类型
    lawsuitFiles: [],
    fileSpace: null, // 文件存储空间
    endTypes: [],
    // 不排除本人号码类型
    NumberType: [],
    // 排除本人号码类型
    delNumberType: [],
    // 是否正在视频调解
    mediating: false,
    // 外访报告模板
    visitTpls: []
  },
  getters: {
    wpPlanStatus: state => state.map.wpPlanStatus,
    simCard: state => state.map.simCard,
    smsFields: state => state.map.smsFields,
    sendStatus: state => state.map.sendStatus,
    waitStatus: state => state.map.waitStatus,
    receiveStatus: state => state.map.receiveStatus,
    availableSmsSign: state => state.sms_sign, // 有效签名
    availableSmsScript: state => state.smsScript, // 短信话术
    availableWechatScript: state => state.wechatScript, // 微信话术
    availableSales: state => state.sales, // 销售
    dictionary: state => state.map,
    productType: state => state.map.productType,
    outcome: state => state.map.outcome,
    partnership: state => state.map.partnership,
    templateType: state => state.map.templateType,
    templateStatus: state => state.map.templateStatus,
    templateProp: state => state.map.templateProp,
    caseColor: state => state.map.caseColor,
    auditState: state => state.map.auditState,
    caseStopReason: state => state.map.caseStopReason,
    agentKPI: state => state.map.agentKPI,
    callResultFilter: state => state.map.callResultFilter,
    customLabel: state => state.map.customLabel,
    robotCallType: state => state.map.robotCallType,
    refundResult: state => state.refundResult,
    operationState: state => state.operationState,
    planPriority: state => state.map.planPriority,
    statusResult: state => state.map.statusResult,
    letterType: state => state.map.letterType,
    planStatus: state => state.map.planStatus,
    caseHandleMode: state => state.map.caseHandleMode,
    teamCaseHandleMode: state => state.map.teamCaseHandleMode,
    assginCaseWeight: state => state.map.assginCaseWeight,
    assginCaseStrategy: state => state.map.assginCaseStrategy,
    createBatchMode: state => state.map.createBatchMode,
    refundCallResult: state => state.refundCallResult,
    mechanismType: state => state.map.mechanismType,
    availableRole: state => state.map.role,
    operationStatus: state => state.map.operationStatus,
    returnStatus: state => state.map.returnStatus,
    reduceStatus: state => state.map.reduceStatus,
    applystatus: state => state.map.applystatus,
    paymentApplyStatus: state => state.map.paymentApplyStatus,
    reductionApplystatus: state => state.map.reductionApplystatus,
    assistApplystatus: state => state.map.assistApplystatus,
    caseStatus: state => state.map.caseStatus,
    caseStates: state => state.map.caseStates,
    caseStatesNoTeam: state => state.map.caseStatesNoTeam,
    caseTplFields: state => state.map.caseTplFields,
    batchType: state => state.map.batchType,
    outBatchType: state => state.map.outBatchType,
    caseAssignStatus: state => state.map.caseAssignStatus,
    smsTemplateFields: state => state.map.smsTemplateFields,
    emailTemplateFields: state => state.map.emailTemplateFields,
    infoFixStatus: state => state.map.infoFixStatus,
    infoRepairStatus: state => state.map.infoRepairStatus,
    sortByImport: state => state.map.sortByImport,
    reportStatus: state => state.map.reportStatus,
    caseStandardColor: state => state.map.caseStandardColor,
    caseColors: state => state.map.caseColors,
    caseTagArr: state => state.caseTagArr,
    updateCaseType: state => state.map.updateCaseType,
    caseUpdateType: state => state.map.caseUpdateType,
    applyStatus: state => state.map.applyStatus,
    applyStatuses: state => state.map.applyStatuses,
    applyCheckStatus: state => state.map.applyCheckStatus,
    refundExportStatus: state => state.map.refundExportStatus,
    conjointTypes: state => state.map.conjointTypes,
    addressStatus: state => state.map.addressStatus,
    addressResource: state => state.map.addressResource,
    addressTypes: state => state.map.addressTypes,
    outvisitAuditStatus: state => state.map.outvisitAuditStatus,
    outvisitStatus: state => state.map.outvisitStatus,
    createTypes: state => state.map.createTypes,
    signInStates: state => state.map.signInStates,
    cooperationState: state => state.map.cooperationState,
    orgStates: state => state.map.orgStates,
    authSwitches: state => state.map.authSwitches,
    duyanServiceKey: state => state.map.duyanServiceKey,
    exportTypes: state => state.map.exportTypes,
    customType: state => state.map.customType,
    switches: state => state.map.switches,
    recordTypes: state => state.map.recordTypes,
    contactStatus: state => state.map.contactStatus,
    contactActions: state => state.map.contactActions,
    accountTypes: state => state.map.accountTypes,
    lettersType: state => state.map.lettersType,
    sendLettersType: state => state.map.sendLettersType,
    sendLetterType: state => state.map.sendLetterType,
    letterReceiveStatus: state => state.map.letterReceiveStatus,
    oplogLetters: state => state.map.oplogLetters,
    letterStatus: state => state.map.letterStatus,
    releaseTypes: state => state.map.releaseTypes,
    feedbackType: state => state.map.feedbackType,
    qualityState: state => state.map.qualityState,
    isEarlyWarnings: state => state.map.isEarlyWarnings,
    isHangUps: state => state.map.isHangUps,
    languages: state => state.map.languages,
    langPage: state => state.map.langPage,
    mediateStatus: state => state.map.mediateStatus,
    startStatuses: state => state.map.startStatuses,
    signFlowStatus: state => state.map.signFlowStatus,
    noticeTypes: state => state.map.noticeTypes,
    signFlowStatuses: state => state.map.signFlowStatuses,
    sealBizTypes: state => state.map.sealBizTypes,
    signerStatus: state => state.map.signerStatus,
    allotObjects: state => state.map.allotObjects,
    operTypes: state => state.map.operTypes,
    sealAuditStates: state => state.map.sealAuditStates,
    lawsuitStatus: state => state.map.lawsuitStatus,
    lawsuitCaseStatus: state => state.map.lawsuitCaseStatus,
    lawsuitSources: state => state.map.lawsuitSources,
    payTypes: state => state.map.payTypes,
    assetTypes: state => state.map.assetTypes,
    visitManagement: state => state.map.visitManagement,
    closedTypes: state => state.map.closedTypes,
    debtor: state => state.map.debtor,
    applyReason: state => state.map.applyReason,
    noticeType: state => state.map.noticeType,
    handleStatus: state => state.map.handleStatus,
    mediateCaseStatus: state => state.map.mediateCaseStatus,
    plainStatus: state => state.map.plainStatus,

    availableDelt: state => state.delt,
    availableAllDelt: state => state.allDelt,
    availableProduct: state => state.product,
    availableBatch: state => state.batch,
    availableOutBatch: state => state.outBatch,
    availableOutBatchIds: state => state.outBatchIds,
    availableAgent: state => state.account,
    availableSameTeamAgent: state => state.sameTeamAccount,
    availableDepartment: state => state.department,
    availableTeam: state => state.team,
    availableCommissionList: state => state.commissionList,
    availableOutsource: state => state.outsource,
    availableDeps: state => state.deps,
    availableAllTeams: state => state.allTeams,
    availableDepOrg: state => state.depOrg,
    availableOrg: state => state.org,
    availableHeadOrg: state => state.headOrg,
    availableTemplate: state => state.template,
    availableSmsTemplate: state => state.smsTemplate,
    availableFile: state => state.file,
    availableVsite: state => state.vsite,
    availableStrategy: state => state.strategy,
    availableRefundResult: state => state.refundResult,
    availableOperationState: state => state.operationState,
    availableVisitors: state => state.visitors,
    availableAllCounts: state => state.allCounts,
    availableAgentTeams: state => state.agentTeams,
    availableRefundCallResult: state => state.refundCallResult,
    availableDuyanAgent: state => state.duyanAgent,
    availableDuyanTeams: state => state.duyanTeams,
    availableInspectTeams: state => state.inspectTeams,
    availableInspectAgents: state => state.inspectAgents,
    availableCourts: state => state.court,
    availableNumberType: state => state.NumberType,
    availableDelNumberType: state => state.delNumberType,
    availableLawsuitProcess: state => state.lawsuitProcess,
    availableLawsuitCaseTypes: state => state.lawsuitCaseTypes,
    availableCategories: state => state.categories,
    availableSeiTurns: state => state.seiTurns,
    availableSeiStages: state => state.seiStages,
    availableLawsuitFiles: state => state.lawsuitFiles,
    availableEndTypes: state => state.endTypes,
    availableVisitTpls: state => state.visitTpls,
    availableMediators: state => state.mediator,

    setCallbackData: state => state.set.callbackData,

    ctiCallbackData: state => state.ctiCall.callbackData,
    originCtiCall: state => state.ctiCall.originCtiCall,
    urgeRefund: state => state.ctiCall.urgeRefund,
    isCtiReady: state => state.ctiCall.isReady,
    callUuid: state => state.ctiCall.uuid,
    callUuidOld: state => state.ctiCall.oldUuid,
    qosCallUuid: state => state.qosCallUuid,
    callStyle: state => state.ctiCall.callStyle,
    openedRefundForm: state => state.ctiCall.refundForm,
    phonelines: state => state.ctiCall.phonelines,
    startTime: state => state.ctiCall.startTime,
    successTime: state => state.ctiCall.successTime,
    endTime: state => state.ctiCall.endTime,
    adminRole: state => state.adminRole,
    allOrgs: state => state.allOrgs,
    businessTypes: state => state.businessTypes,
    isInCalling: state => state.isInCalling,
    letterSystem: state => state.letterSystem,
    tjSystem: state => state.tjSystem,
    sealList: state => state.sealList,
    curOutbounce: state => state.curOutbounce,
    isOutbounceLock: state => state.isOutbounceLock,
    isChangeCase: state => state.isChangeCase,
    availableOrgWhites: state => state.orgWhites,
    isNotification: state => state.isNotification,
    isInCall: state => state.isInCall,
    refundings: state => state.refundings,
    isHelping: state => state.isHelping,
    refundedCase: state => state.ctiCall.refundedCase,
    isManageRefund: state => state.isManageRefund,
    caseCtrlList: state => state.caseCtrlList,
    fileSpace: state => state.fileSpace,
    mediating: state => state.mediating
  },
  mutations: {
    updateCtiCall(state, { key, data }) {
      state.ctiCall[key] = data
    },
    setIsInCalling(state, data) {
      state.isInCalling = data
    },
    setCallUuid(state, uuid) {
      state.qosCallUuid = uuid
    },
    setSup(state, { key, data }) {
      state.set[key] = data
    },
    setCaseTplFields(state, { update, userBase, base, operation, caseExport, caseInfoExtends, visitExport, operationExportCaseFields }) {
      if (!update && !userBase && !base && !operation && !caseExport && !caseInfoExtends && !visitExport && !operationExportCaseFields) { return }

      state.map.caseTplFields.updateRequired = []
      const _upmap = {}
      if (update && update.length) {
        state.map.caseTplFields.update = update.filter((c) => {
          c.feGroup = 'Upmap'
          _upmap[c.value] = c
          if (c.required && c.required !== 'false') {
            state.map.caseTplFields.updateRequired.push(c)
            return false
          }
          return true
        })
        state.map.caseTplFields.upmap = _upmap
      } else {
        state.map.caseTplFields.update = []
        state.map.caseTplFields.upmap = _upmap
      }

      state.map.caseTplFields.userBaseRequired = []
      if (userBase.length || base.length) {
        const _userBaseAll = []; const _base = {}; const _user = {}; const _requireds = []

        for (const item of userBase) {
          item.feGroup = 'User'
          _user[item.value] = item

          if (item.required && item.required !== 'false') {
            _requireds.push(item)
          } else {
            _userBaseAll.push(item)
          }
        }

        for (const item of base) {
          item.feGroup = 'Base'
          _base[item.value] = item

          if (item.required && item.required !== 'false') {
            _requireds.push(item)
          } else {
            _userBaseAll.push(item)
          }
        }

        state.map.caseTplFields.userBaseRequired = _requireds
        state.map.caseTplFields.userBase = _userBaseAll
        state.map.caseTplFields.base = _base
        state.map.caseTplFields.user = _user
      } else {
        state.map.caseTplFields.userBase = []
        state.map.caseTplFields.base = {}
        state.map.caseTplFields.user = {}
      }

      if (operation) {
        const _operBaseAll = []; const _requireds = []
        const _oper = {}
        for (const item of operation) {
          item.feGroup = 'Operation'
          _oper[item.value] = item
          if (item.required && item.required !== 'false') {
            _requireds.push(item)
          } else {
            _operBaseAll.push(item)
          }
        }
        state.map.caseTplFields.operationBase = _oper
        state.map.caseTplFields.operation = _operBaseAll
        state.map.caseTplFields.operationRequired = _requireds
      } else {
        state.map.caseTplFields.operation = []
        state.map.caseTplFields.operationRequired = []
        state.map.caseTplFields.operationBase = {}
      }

      if (caseExport) {
        const _caseExportBaseAll = []; const _requireds = []
        const _caseExport = {}
        for (const item of caseExport) {
          item.feGroup = 'CaseExport'
          _caseExport[item.value] = item
          if (item.required && item.required !== 'false') {
            _requireds.push(item)
          } else {
            _caseExportBaseAll.push(item)
          }
        }
        state.map.caseTplFields.caseExportBase = _caseExport
        state.map.caseTplFields.caseExport = _caseExportBaseAll
        state.map.caseTplFields.caseExportRequired = _requireds
      } else {
        state.map.caseTplFields.caseExportBase = []
        state.map.caseTplFields.caseExport = []
        state.map.caseTplFields.caseExportRequired = {}
      }

      if (caseInfoExtends) {
        const _caseInfoExtends = []; const _requireds = []
        const _caseInfo = []
        const _caseExtends = {}
        for (const item of caseInfoExtends) {
          item.feGroup = 'CaseInfoExtends'
          _caseInfo.push(item)
          _caseExtends[item.value] = item
          if (item.required && item.required === 'true') {
            _requireds.push(item)
          } else {
            _caseInfoExtends.push(item)
          }
        }

        state.map.caseTplFields.caseInfoExtends = _caseInfo
        state.map.caseTplFields.caseExtends = _caseExtends
        state.map.caseTplFields.caseInfo = _caseInfoExtends
        state.map.caseTplFields.caseInfoExtendsRequired = _requireds
      }

      if (visitExport) {
        const _visitInfoExtends = {}; const _requireds = []
        const _visitInfo = []
        for (const item of visitExport) {
          item.feGroup = 'visitExport'
          _visitInfoExtends[item.value] = item
          if (item.required && item.required !== 'false') {
            _requireds.push(item)
          } else {
            _visitInfo.push(item)
          }
        }

        state.map.caseTplFields.visitInfoExtends = _visitInfoExtends
        state.map.caseTplFields.visitInfo = _visitInfo
        state.map.caseTplFields.visitInfoExtendsRequired = _requireds
      } else {
        state.map.caseTplFields.visitInfoExtends = []
        state.map.caseTplFields.visitInfo = []
        state.map.caseTplFields.visitInfoExtendsRequired = []
      }

      if (operationExportCaseFields) {
        const _operExportExtends = {}; const _requireds = []
        const _operInfo = []
        for (const item of operationExportCaseFields) {
          item.feGroup = 'operationExportCaseFields'
          _operExportExtends[item.value] = item
          if (item.required && item.required !== 'false') {
            _requireds.push(item)
          } else {
            _operInfo.push(item)
          }
        }

        state.map.caseTplFields.operExportExtends = _operExportExtends
        state.map.caseTplFields.operInfo = _operInfo
        state.map.caseTplFields.operExtendsRequired = _requireds
      }
    },
    setMapData(state, data) {
      let value = ''; const caseFields = {}
      for (const item of data) {
        try {
          value = JSON.parse(item.myvalue)
        } catch (error) {
          console.error(`${item.mykey}解析错误, ${item.myvalue}`)
          value = []
        }

        if (item.mykey === 'caseUpdateFields') {
          caseFields.update = value
        } else if (item.mykey === 'caseUserBaseFields') {
          caseFields.userBase = value
        } else if (item.mykey === 'caseBaseFields') {
          caseFields.base = value
        } else if (item.mykey === 'caseOperationFields') {
          caseFields.operation = value
        } else if (item.mykey === 'caseExportFields') {
          caseFields.caseExport = value
        } else if (item.mykey === 'caseInfoExtendsFields') {
          caseFields.caseInfoExtends = value
        } else if (item.mykey === 'visitExportFields') {
          caseFields.visitExport = value
        } else if (item.mykey === 'operationExportCaseFields') {
          caseFields.operationExportCaseFields = value
        } else {
          state.map[item.mykey] = value
        }
      }

      // 分拣模板字段
      this.commit('setCaseTplFields', caseFields)
    },
    setAdminRole(state, role) {
      state.adminRole = role
    },
    setDelt(state, delt) {
      state.delt = delt
    },
    setAllDelt(state, allDelt) {
      state.allDelt = allDelt
    },
    setProduct(state, product) {
      for (const item of product) {
        item.value = item.id
      }
      state.product = product
    },
    setBatch(state, batch) {
      state.batch = batch
    },
    setOutBatch(state, { outBatch, outBatchIds }) {
      state.outBatch = outBatch
      state.outBatchIds = outBatchIds
    },
    setAgent(state, account) {
      state.account = account
    },
    setDuyanAgent(state, account) {
      const agents = []
      for (const item of account) {
        if (item.duyanAccountId) {
          item.value = item.duyanAccountId
          agents.push(item)
        }
      }

      state.duyanAgent = agents
    },
    setSameTeamAgent(state, account) {
      const teamId = window.VueMainInstance.$store.getters.teamId
      state.sameTeamAccount = []
      state.sameTeamAccount = account.filter(item => item.teamId === teamId)
    },
    setAllCounts(state, allCounts) {
      state.allCounts = allCounts
    },
    setVsite(state, vsite) {
      state.vsite = vsite
    },
    setcommissionList(state, commissionList) {
      state.commissionList = commissionList
    },
    setStrategy(state, strategy) {
      state.strategy = strategy
    },
    setDepAndTeam(state, list) {
      if (!list) {
        state.department = []
        state.team = []
        state.outsource = []
        state.deps = []
        return
      }
      const team = []; const org = []; const outsource = []
      if (list.type === 4) {
        // 分公司/委外机构
        list.value = list.id
        if (list.isAgent === 1) {
          outsource.push(list)
        } else {
          org.push(list)
        }
      } else if (list.type === 3) {
        // 小组
        list.value = list.id
        team.push(list)
      } else {
        // 总公司
        for (const value of list.deps) {
          value.value = value.id

          if (value.type === 3) {
            // 直属小组
            team.push(value)
          } else {
            if (value.isAgent === 1) {
              // 委外机构
              outsource.push(value)
            } else {
              // 分公司
              org.push(value)
            }

            if (value.children && value.children.length) {
              value.children.map(item => {
                if (item.type === 3) {
                  team.push(item)
                }
              })
            }
          }
        }
      }

      state.department = [...org]
      state.team = [...team]
      state.outsource = [...outsource]
      state.deps = [...org, ...outsource]
    },
    setDuyanDepAndTeam(state, list) {
      const team = []
      if (list.type === 3) {
        // 小组
        list.value = list.duyanReferId
        team.push(list)
      } else {
        // 总公司
        for (const value of list.deps) {
          value.value = value.duyanReferId

          if (value.type === 3) {
            // 直属小组
            team.push(value)
          } else {
            if (value.children && value.children.length) {
              value.children.map(item => {
                if (item.type === 3) {
                  team.push(item)
                }
              })
            }
          }
        }
      }

      state.duyanTeams = [...team]
    },
    setDepOrg(state, depOrg) {
      const org = []
      if (depOrg.type === 4) {
        org.push({
          id: this.availableOrg.id,
          name: this.availableOrg.name
        })
      } else {
        for (const value of depOrg.deps) {
          if (value.type === 3) {
            // 找出直接挂在总公司下面的小组
          } else {
            // 找出直接挂在总公司下面的分公司
            org.push(value)
          }
        }
      }
      state.depOrg = [...org]
    },
    setAllTeams(state, allTeams) {
      state.allTeams = allTeams
    },
    setAgentTeams(state, teams) {
      state.agentTeams = teams
    },
    setSmsSign(state, smsSign) {
      state.sms_sign = smsSign
    },
    setSmsScript(state, script) {
      state.smsScript = script
    },
    setWechatScript(state, script) {
      state.wechatScript = script
    },
    setSales(state, sales) {
      state.sales = sales
    },
    setSmsTemplate(state, smsTemplate) {
      state.smsTemplate = smsTemplate
    },
    setTemplate(state, template) {
      state.template = template
    },
    setOrg(state, org) {
      state.org = org
    },
    setHeadOrg(state, headOrg) {
      state.headOrg = headOrg
    },
    setFile(state, file) {
      state.file = file
      // outBatch依存于file api，所以发生改成清除outBatch cache;  batch 文件处理也有关联
      state.batch = []
      state.outBatch = []
      state.outBatchIds = []
    },
    setRole(state, role) {
      state.map.role = role
    },
    setRefundResult(state, refundResult) {
      // 数据过滤
      state.map.refundResult = []
      state.refundResult = []
      if (refundResult) {
        refundResult.map(item => {
          if (item.isSelected === 1) {
            state.refundResult.push({
              value: item.code,
              name: item.rename || item.name
            })
          }
          state.map.refundResult.push({
            value: item.code,
            name: item.rename || item.name
          })
        })
      }

      state.refundResult = [...state.refundResult, ...[{ value: -2, name: '未处理' }, { value: -3, name: '未填写' }]]
      state.map.refundResult = [...state.map.refundResult, ...[{ value: -2, name: '未处理' }, { value: -3, name: '未填写' }]]
    },
    setOperationState(state, operationState) {
      state.map.operationState = []
      state.operationState = []

      operationState.map(item => {
        if (item.isSelected === 1) {
          state.operationState.push({
            value: item.code,
            name: item.rename || item.name
          })
        }
        state.map.operationState.push({
          value: item.code,
          name: item.rename || item.name
        })
      })
    },
    setRefundCallResult(state, refundCallResult) {
      state.map.refundCallResult = []
      state.refundCallResult = []

      refundCallResult.map(item => {
        if (item.isSelected === 1) {
          state.refundCallResult.push({
            value: item.code,
            name: item.rename || item.name
          })
        }
        state.map.refundCallResult.push({
          value: item.code,
          name: item.rename || item.name
        })
      })
    },
    setVisitors(state, visitors) {
      state.visitors = [...visitors]
    },
    setCaseTag(state, caseTagArr) {
      state.caseTagArr = []
      const list = caseTagArr
      for (const key in list) {
        if (list[key].state) {
          state.caseTagArr.push({
            value: list[key].id,
            name: list[key].name,
            color: list[key].color
          })
        }
      }
    },
    setAllOrgs(state, allOrgs) {
      state.allOrgs = allOrgs
    },
    setBusinessTypes(state, businessTypes) {
      state.businessTypes = []
      for (const key in businessTypes) {
        state.businessTypes.push({
          name: businessTypes[key].name,
          value: businessTypes[key].type
        })
      }
    },
    setLetterSystem(state, letterSystem) {
      state.letterSystem = []
      letterSystem = letterSystem || []
      letterSystem.map(item => {
        item.value = item.id
        state.letterSystem.push(item)
      })
    },
    setTjSystem(state, tjSystem) {
      state.tjSystem = []
      tjSystem = tjSystem || []
      tjSystem.map(item => {
        item.value = item.id
        state.tjSystem.push(item)
      })
    },
    setSealList(state, sealList) {
      state.sealList = []
      const list = [...sealList]
      list.map(item => {
        item.value = item.id
        state.sealList.push(item)
      })
    },
    setCurOutbounce(state, curOutbounce) {
      state.curOutbounce = { ...curOutbounce }
    },
    setIsOutbounceLock(state, isOutbounceLock) {
      state.isOutbounceLock = isOutbounceLock
    },
    setIsChangeCase(state, isChangeCase) {
      state.isChangeCase = isChangeCase
    },
    setOrgWhites(state, orgWhites) {
      state.orgWhites = []
      orgWhites.map(item => {
        item.value = item.id
        state.orgWhites.push(item)
      })
    },
    setIsNotification(state, isNotification) {
      state.isNotification = isNotification
    },
    setIsInCall(state, isInCall) {
      state.isInCall = isInCall
    },
    setOpenedRefundForm(state, { key, data }) {
      state.ctiCall[key] = data
    },
    setIsHelping(state, isHelping) {
      state.isHelping = isHelping
    },
    setRefundedCase(state, { uuid, value }) {
      state.ctiCall.refundedCase = {}
      state.ctiCall.refundedCase[uuid] = value
    },
    setInspects(state, list) {
      const inspectTeams = []
      const inspectAgents = []

      if (list.length) {
        for (const item of list) {
          if (item.userList) {
            for (const i of item.userList) {
              if (i.duyanAccountId) {
                i.duyanTeamId = item.duyanReferId
                i.value = i.duyanAccountId
                inspectAgents.push(i)
              }
            }
          }
          delete item.userList
          item.value = item.duyanReferId
          inspectTeams.push(item)
        }

        state.inspectTeams = [...inspectTeams]
        state.inspectAgents = [...inspectAgents]
      }
    },
    setLangPage(state, langs) {
      state.map.langPage.plugin = langs
    },
    setIsManageRefund(state, isManageRefund) {
      state.isManageRefund = isManageRefund
    },
    setCaseCtrlList(state, list) {
      state.caseCtrlList = list
    },
    // 空间存储
    setFileSpace(state, list) {
      state.fileSpace = list
    },
    setCourts(state, list) {
      state.court = list.filter(_c => _c.isSelected === 1)
      for (const item of state.court) {
        item.value = item.code
        item.name = item.rename || item.name
      }
    },
    // 本人号码类型
    setNumberType(state, list) {
      state.NumberType = list.filter(_c => _c.enable === 1)
    },
    // 排除本人号码类型
    setDelNumberType(state, list) {
      state.delNumberType = list.filter(_c => _c.enable === 1)
    },
    setLawsuitProcess(state, list) {
      state.lawsuitProcess = list.filter(_c => _c.isSelected === 1)
      for (const item of state.lawsuitProcess) {
        item.value = item.id
        item.name = item.rename || item.name
      }
    },
    setLawsuitCaseTypes(state, list) {
      state.lawsuitCaseTypes = list.filter(_c => _c.isSelected === 1)
      for (const item of state.lawsuitCaseTypes) {
        item.value = item.id
        item.name = item.rename || item.name
      }
    },
    setCategories(state, list) {
      state.categories = list.filter(_c => _c.isSelected === 1)
      for (const item of state.categories) {
        item.value = item.code
        item.name = item.rename || item.name
      }
    },
    setSeiTurns(state, list) {
      state.seiTurns = list.filter(_c => _c.isSelected === 1)
      for (const item of state.seiTurns) {
        item.value = item.code
        item.name = item.rename || item.name
      }
    },
    setSeiStages(state, list) {
      state.seiStages = list.filter(_c => _c.isSelected === 1)
      for (const item of state.seiStages) {
        item.value = item.code
        item.name = item.rename || item.name
      }
    },
    setLawsuitFiles(state, list) {
      state.lawsuitFiles = list.filter(_c => _c.isSelected === 1)
      for (const item of state.lawsuitFiles) {
        // item.value = item.code
        item.name = item.rename || item.name
      }
    },
    setEndTypes(state, list) {
      const sucTypes = {
        value: 1,
        label: `${$t('collection')}成功`,
        children: []
      }

      const failTypes = {
        value: 0,
        label: `${$t('collection')}失败`,
        children: []
      }

      for (const item of list) {
        item.value = item.id
        item.label = item.attachedRes
        if (item.endType === 1) {
          sucTypes.children.push(item)
        } else {
          failTypes.children.push(item)
        }
      }

      if (!sucTypes.children.length) {
        delete sucTypes.children
      }

      if (!failTypes.children.length) {
        delete failTypes.children
      }

      state.endTypes = [sucTypes, failTypes]
    },
    // 视频调解
    setMediate(state, mediating) {
      state.mediating = mediating
    },
    setVisitTpls(state, visitTpls) {
      for (const item of visitTpls) {
        item.value = item.id
      }
      state.visitTpls = visitTpls
    },
    setMediator(state, mediator) {
      state.mediator = mediator
    }
  },
  actions: {
    getCaseTagColor({ state, commit }) {
      return getAvailableData(commit, caseTagList, {}, state.caseTagArr, (res) => {
        commit('setCaseTag', res.data)
      })
    },
    // 获取管控池分类
    getAvaliableCaseCtrl({ state, commit }) {
      return getAvailableData(commit, caseList, {}, false, (res) => {
        const list = [{ name: '全部', id: 0 }]
        for (const value of res.data) {
          if (value.enable) {
            list.push(value)
          }
        }
        commit('setCaseCtrlList', list)
      })
    },
    // 获取所有的短信签名
    getAvaliableSmsSigns({ state, commit }) {
      return getAvailableData(commit, getSmsTplSign, { limit: 100, page: 1 }, state.sms_sign, (res) => {
        const smsSign = []
        for (const value of res.data.list) {
          smsSign.push({
            name: value.name,
            id: value.id,
            value: value.id,
            auditState: value.auditState
          })
        }
        commit('setSmsSign', smsSign)
      })
    },
    // 获取短信话术
    getAvaliableSmsScript({ state, commit }) {
      return getAvailableData(commit, smsTemplateList, {}, state.smsScript, (res) => {
        const script = []
        for (const value of res.data) {
          script.push({
            name: value.content,
            id: value.id,
            value: value.id
          })
        }
        commit('setSmsScript', script)
      })
    },
   // 获取微信话术
    getAvaliableWechatScript({ state, commit }) {
      return getAvailableData(commit, wechatTemplateList, {}, state.wechatScript, (res) => {
        const script = []
        for (const value of res.data) {
          script.push({
            name: value.content,
            id: value.id,
            value: value.id,
          })
        }
        commit('setWechatScript', script)
      })
    },
    // 获取所有的销售
    getAvaliableSales({ state, commit }) {
      return getAvailableData(commit, getSales, {}, state.sales, (res) => {
        const sales = []
        for (const value of res.data) {
          sales.push({
            id: value.id,
            value: value.id,
            name: value.name
          })
        }
        commit('setSales', sales)
      })
    },
    // 根据当前登陆用户获取所有角色列表
    getAdminList({ state, commit }) {
      return getAvailableData(commit, adminList, {}, state.adminRole, 'setAdminRole')
    },
    getAvailableDelt({ state, commit }) {
      return getAvailableData(commit, getDelts, { statuses: '0,1' }, state.delt, 'setDelt')
    },
    getAllAvailableDelt({ state, commit }) {
      return getAvailableData(commit, getDelts, {statuses: '0,1,2,3'}, state.allDelt, 'setAllDelt')
    },
    getAvailableProduct({ state, commit }) {
      return getAvailableData(commit, getProd, {}, state.product, 'setProduct')
    },
    getAvailableBatch({ state, commit }) {
      return getAvailableData(commit, getBatch, {}, false, (res) => {
        const batch = []
        for (const item of res.data.list) {
          if (item.status !== -1) {
            batch.push(item)
          }
        }

        commit('setBatch', batch)
      })
    },
    getAvailableOutBatch({ commit }) {
      return getAvailableData(commit, getOutBatch, {}, false, (res) => {
        const outBatch = []
        const outBatchIds = []
        for (const item of res.data.list) {
          if (item.id && !outBatchIds.includes(item.id) && item.status !== -1) {
            outBatchIds.push(item.id)
          }
          if (item.status !== -1) {
            item.originName = item.name
            item.name = item.orgDeltName + '-' + item.name
            outBatch.push(item)
          }
        }

        commit('setOutBatch', { outBatch, outBatchIds })
      })
    },
    getAvailableTemplates({ state, commit }) {
      return getAvailableData(commit, getAllTemplates, {}, state.template, 'setTemplate')
    },
    getAvailableSmsTemplates({ state, commit }) {
      return getAvailableData(commit, getTplSuccess, {}, state.smsTemplate, 'setSmsTemplate')
    },
    // 获取佣金比例设置方案
    getCommissionList({ state, commit }) {
      return getAvailableData(commit, getCommissionList, {}, state.commissionList, 'setcommissionList')
    },
    getAvailableAgent({ state, commit }) {
      return getAvailableData(commit, getAccount, { status: 0 }, state.account, (res) => {
        const agents = res.data.list.filter((c) => {
          return c.agent === true
        })
        commit('setAgent', deepClone(agents))
        commit('setSameTeamAgent', deepClone(agents))
        commit('setDuyanAgent', deepClone(agents))
      })
    },
    getAvailableInspects({ state, commit }) {
      return getAvailableData(commit, getInspects, {}, false, (res) => {
        const list = res.data
        commit('setInspects', deepClone(list))
      })
    },
    getAvailableAllCounts({ state, commit }) {
      return getAvailableData(commit, getAllCounts, {}, state.allCounts, (res) => {
        const agents = res.data.filter((c) => {
          return c.agent === true
        })
        commit('setAllCounts', agents)
      })
    },
    getAvailableFile({ state, commit }) {
      return getAvailableData(commit, getFiles, { templateType: 0, status: 1 }, state.file, 'setFile')
    },
    getAvailableVsite({ state, commit }) {
      // 话术类型。ROBOT：机器人话术（用于机器人计划），AUTO：智能话术（用于智能计划），如未填写默认返回智能话术
      return getAvailableData(commit, getRobotVsite, { module: 'ROBOT' }, state.vsite, 'setVsite')
    },
    getAvailableStrategy({ state, commit }) {
      return getAvailableData(commit, getNoPageStrategy, {}, state.strategy, 'setStrategy')
    },
    getAvailableDepAndTeam({ state, commit }) {
      return getAvailableData(commit, getOrgDet, { 'id': window.VueMainInstance.$store.getters.myOrgId }, null, (res) => {
        commit('setDepAndTeam', deepClone(res.data))
        commit('setDuyanDepAndTeam', deepClone(res.data))
      })
    },
    getAvailableAllTeams({ state, commit }) {
      return getAvailableData(commit, getTeamList, {}, state.allTeams, 'setAllTeams')
    },
    getAvailableDepOrg({ state, commit }) {
      return getAvailableData(commit, getOrgDet, { 'id': window.VueMainInstance.$store.getters.myOrgId }, state.depOrg, 'setDepOrg')
    },
    getAvailableAgentTeams({ state, commit }) {
      return getAvailableData(commit, getDepTeamList, {}, state.agentTeams, 'setAgentTeams')
    },
    getAvailableVisitors({ state, commit }) {
      return getAvailableData(commit, getVisitors, {}, state.visitors, (res) => {
        const list = res.data
        const visitors = []
        if (list.length > 0) {
          list.map(item => {
            visitors.push({
              value: item.id,
              name: item.name
            })
          })
        }

        commit('setVisitors', visitors)
      })
    },
    getAvailableHeadOrg({ state, commit }) {
      return getAvailableData(commit, getOrg, {}, state.headOrg, (res) => {
        const data = res.data
        const head = []
        for (const key in data.list) {
          if (data.list[key].type === 0) {
            head.push(data.list[key])
          }
        }
        commit('setHeadOrg', head)
      })
    },
    getAvailableOrg({ state, commit }) {
      return getAvailableData(commit, getOrgDet, { 'id': window.VueMainInstance.$store.getters.myOrgId }, state.org, 'setOrg')
    },
    getAvailabledRole({ state, commit }) {
      return getAvailableData(commit, getRoles, {}, state.map.role, (res) => {
        const data = res.data
        for (const key in data.list) {
          data.list[key]['value'] = data.list[key].id
        }

        commit('setRole', data.list || [])
      })
    },
    getAvailableRefundResult({ state, commit }) {
      return getAvailableData(commit, getRefundResult, {}, state.refundResult, (res) => {
        commit('setRefundResult', res.data)
      })
    },
    // 存储空间
    getAvailableFileSpace({ state, commit }) {
      return getAvailableData(commit, spaceInfo, {}, state.fileSpace, (res) => {
        commit('setFileSpace', res.data)
      })
    },
    getAvailableOperationState({ state, commit }) {
      return getAvailableData(commit, orgConfigList, { type: 1 }, state.operationState, (res) => {
        commit('setOperationState', res.data)
      })
    },
    getAvailableRefundCallResult({ state, commit }) {
      return getAvailableData(commit, orgConfigList, { type: 2 }, state.refundCallResult, (res) => {
        commit('setRefundCallResult', res.data)
      })
    },
    getAvailableBusinessTypes({ state, commit }) {
      return getAvailableData(commit, businessType, null, state.businessTypes, (res) => {
        commit('setBusinessTypes', res.data)
      })
    },
    getAvailableSystemLetters({ state, commit }) {
      return getAvailableData(commit, getSysLetter, { businessType: 0 }, state.letterSystem, (res) => {
        commit('setLetterSystem', res.data)
      })
    },
    getAvailableTjLetters({ state, commit }) {
      return getAvailableData(commit, getSysLetter, { businessType: 1 }, state.tjSystem, (res) => {
        commit('setTjSystem', res.data)
      })
    },
    getAvailableSealList({ state, commit }) {
      return getAvailableData(commit, getSealListNoPage, null, state.sealList, (res) => {
        commit('setSealList', res.data)
      })
    },
    getAvailableOrgWhites({ state, commit }) {
      return getAvailableData(commit, getOrgWhite, null, state.orgWhites, (res) => {
        commit('setOrgWhites', res.data)
      })
    },
    getAvailableCourts({ state, commit }) {
      return getAvailableData(commit, getLawsuitConfig, { type: 2 }, state.court, (res) => {
        commit('setCourts', res.data)
      })
    },
    // todo
    getAvailableNumberType({ state, commit }) {
      return getAvailableData(commit, getNumberList, { excludeSelf: 0 }, state.NumberType, (res) => {
        commit('setNumberType', res.data)
      })
    },
    getAvailableDelNumberType({ state, commit }) {
      return getAvailableData(commit, getNumberList, { excludeSelf: 1 }, state.delNumberType, (res) => {
        commit('setDelNumberType', res.data)
      })
    },
    // todo
    getAvailableLawsuitProcess({ state, commit }) {
      return getAvailableData(commit, getLawsuitConfig, { type: 0 }, state.lawsuitProcess, (res) => {
        commit('setLawsuitProcess', res.data)
      })
    },
    getAvailableLawsuitCaseTypes({ state, commit }) {
      return getAvailableData(commit, getLawsuitConfig, { type: 1 }, state.lawsuitCaseTypes, (res) => {
        commit('setLawsuitCaseTypes', res.data)
      })
    },
    getAvailableCategories({ state, commit }) {
      return getAvailableData(commit, getLawsuitConfig, { type: 3 }, state.categories, (res) => {
        commit('setCategories', res.data)
      })
    },
    getAvailableSeiTurns({ state, commit }) {
      return getAvailableData(commit, getLawsuitConfig, { type: 6 }, state.seiTurns, (res) => {
        commit('setSeiTurns', res.data)
      })
    },
    getAvailableSeiStages({ state, commit }) {
      return getAvailableData(commit, getLawsuitConfig, { type: 5 }, state.seiStages, (res) => {
        commit('setSeiStages', res.data)
      })
    },
    getAvailableLawsuitFiles({ state, commit }) {
      return getAvailableData(commit, getLawsuitConfig, { type: 4 }, state.lawsuitFiles, (res) => {
        commit('setLawsuitFiles', res.data)
      })
    },
    getAvailableEndTypes({ state, commit }) {
      return getAvailableData(commit, getEndTypeList, null, state.endTypes, (res) => {
        commit('setEndTypes', res.data)
      })
    },
    getAvailableVisitTpls({ state, commit }) {
      return getAvailableData(commit, getVisitTpl, null, [], (res) => {
        commit('setVisitTpls', res.data)
      })
    },
    getAvailableMediator({ state, commit }) {
      return getAvailableData(commit, getAccount, { status: 0, mediator: 1 }, state.mediator, (res) => {
        const agents = res.data.list.filter((c) => {
          return c.agent === true
        })
        commit('setMediator', deepClone(agents))
      })
    },
    postAddRefund({ state, commit }, { key, data }) {
      return new Promise((resolve, reject) => {
        // 只有当refundForm有值的时候才去提交refundForm
        if (!state.ctiCall.refundForm) {
          commit('setIsOutbounceLock', false)
          commit('updateCtiCall', { key, data })
          resolve()
          return
        }

        if (!state.ctiCall.refundForm.caseId) {
          commit('setIsOutbounceLock', false)
          commit('updateCtiCall', { key, data })
          resolve()
          return
        }

        const datas = {
          ...state.ctiCall.refundForm,
          syncTypes: '',
          actionType: -3,
          callType: -3
        }

        if (datas.nextTime) {
          datas.nextTime = new Date(datas.nextTime).getTime()
        }

        if (datas.ptpTime) {
          datas.ptpTime = new Date(sendData.ptpTime).getTime()
        }

        if (!state.ctiCall.refundedCase[state.ctiCall.oldUuid]) {
          datas.callUuid = state.ctiCall.oldUuid
        }

        datas.desc = datas.desc || '自动保存'

        const formData = new FormData()
        for (const key in datas) {
          if (datas[key] || datas[key] === 0) {
            formData.append(key, datas[key])
          }
        }

        addRefund(formData).finally(() => {
          if (!state.ctiCall.refundedCase[state.ctiCall.oldUuid]) {
            commit('setRefundedCase', { key: state.ctiCall.oldUuid, value: state.ctiCall.refundForm.caseId })
          }
          commit('setOpenedRefundForm', { key: 'refundForm', data: null })
          commit('updateCtiCall', { key: 'oldUuid', data: '' })
          commit('updateCtiCall', { key, data })
          commit('setIsOutbounceLock', false)
          resolve()
        })
      })
    },
    // 这里只做电话打进来时接口请求相关
    setCtiCall({ state, commit, dispatch }, { key, data }) {
      return new Promise((resolve, reject) => {
        // 只有在 callConnecting(仅外呼)、callConfirm、callBridge_start阶段进行uuid的判断替换
        // 新进入值 如果 和当前值一直，不进行覆盖 oldUuid
        const callData = data.data
        commit('setIsOutbounceLock', true)
        if ((data.action === 'callConnecting' && callData.type === 1) || ['callConfirm', 'callBridge_start'].includes(data.action)) {
          if (callData.uuid && callData.uuid !== state.ctiCall.uuid) {
            state.ctiCall.oldUuid = state.ctiCall.uuid
            state.ctiCall.uuid = callData.uuid
            if (router.app._route.name !== 'caseDetail') {
              dispatch('postAddRefund', { key, data }).then(() => {
                resolve()
              })
            } else {
              // 这里如果是预测外呼且callBridge_start和callConfirm在案件详情里面我们要设法先拿到refundForm
              // 点呼外呼的情况不需要去自动提交催记，在另有催记未提交的情况下，点呼外呼的按钮是禁用状态
              if (['callConfirm', 'callBridge_start'].includes(data.action)) {
                commit('updateCtiCall', { key: 'originCtiCall', data: { key, data }})
              } else {
                commit('setIsOutbounceLock', false)
              }
              // 解决了请求与跳转的先后问题，先临时处理下拿到refundForm，后面已有替代方案
              setTimeout(() => {
                resolve()
              }, 100)
            }
          } else {
            commit('setIsOutbounceLock', false)
            resolve()
          }
        } else {
          commit('updateCtiCall', { key, data })
          commit('setIsOutbounceLock', false)
          resolve()
        }
        // 正在打电话的状态
        if (['callConnecting', 'callConfirm', 'callBridge_start'].includes(data.action)) {
          state.isInCall = true
        } else {
          state.isInCall = false
        }
      })
    },
    /**
     * 获取数据字典， 如果本地存在走cache，否则走接口
     * @param {*} param0
     * @param {*} keys 需要取的值
     */
    getDictionary({ state, commit }, keys = []) {
      return new Promise((resolve, reject) => {
        // 去掉已经加载过的数据字典
        const _keys = keys.filter((key) => {
          return (!state.map[key] || state.map[key].length === 0)
        })

        if (!_keys.length) {
          // 走cache
          resolve(true)
          return
        }

        try {
          getDictionary(_keys).then(res => {
            if (!res) {
              return false
            }

            commit('setMapData', res.data)
            resolve(true)
          }).catch(err => {
            reject(err)
          })
        } catch (error) {
          reject(error)
        }
      })
    },

    /**
     * 加载页面关联数据
     * map 为数字字典内容，如 ['partnership', 'productType']
     * func avaliable系列的数据， 如 ['getAvailableBatch', 'getAvailableProduct']
     */
    getRefData({ dispatch }, { map, func }) {
      window.VueMainInstance.$Loading.start()
      const actions = []
      if (map instanceof Array && map.length) {
        actions.push(dispatch('getDictionary', map))
      }

      if (func instanceof Array && func.length) {
        func.forEach((c) => {
          actions.push(dispatch(c))
        })
      }

      return Promise.all(actions).then(() => {
        window.VueMainInstance.$Loading.finish()
        return true
      })
    }
  }
}
