import { get, post, put, postForm } from '@/libs/api.request'

const filterSearchFields = (data) => {
  for (const key in data) {
    if (typeof (data[key]) === 'string' && ['ownMobiles', 'outSerialNos', 'names', 'conMobiles', 'outSerialNos', 'idCards', 'nameList', 'outSerialTempList'].includes(key)) {
      data[key] = data[key].trim().replace(/\n/g, ',').split(',')
    }
  }
  return data
}

// 处理时间样式
const filterSearchYearDate = (searchData) => {
  if (searchData.yearDate || searchData.achieveDate) {
    let data
    if (searchData.yearDate) {
      data = new Date(searchData.yearDate)
    }
    if (searchData.achieveDate) {
      data = new Date(searchData.achieveDate)
    }
    const year = data.getFullYear()
    let month = data.getMonth() + 1
    if (month < 10) {
      month = '0' + month
    } else {
      month = '' + month
    }
    if (searchData.yearDate) {
      searchData.yearDate = year + month
    }
    if (searchData.achieveDate) {
      searchData.achieveDate = year + month
    }
  }
  return searchData
}

// 处理时间样式
const filterDate = (searchData) => {
  const startDate = new Date(searchData)
  const year = startDate.getFullYear()
  let month = startDate.getMonth() + 1
  if (month < 10) {
    month = '0' + month
  } else {
    month = '' + month
  }
  let data = startDate.getDate()
  if (data < 10) {
    data = '0' + data
  }
  searchData = year + '-' + month + '-' + data
  return searchData
}

export const getRefund = (searchData) => {
  searchData = filterSearchFields(searchData)

  return post('/v1/case/operation/list', searchData, { noSucTip: true })
  // return post('/v1/case/operation/listElasticsearch', searchData, { noSucTip: true })
}

export const addRefund = (refund) => {
  return postForm('/v1/case/operation', refund, { uploadTime: true })
}
// v1/case/repayment/add
export const addRepayment = (data) => {
  return postForm('/v1/case/repayment/add', data, { uploadTime: true })
}
// 还款通过或者拒绝
export const verify = data => {
  return post('/v1/case/repayment/verify', data)
}

export const editRefund = (refund) => {
  return put('/v1/case/operation', refund)
}

export const getRepayment = (searchData) => {
  searchData = filterSearchFields(searchData)
  return post('/v1/case/repayment', searchData, { noSucTip: true })
}

export const cancelRepayment = (data) => {
  return post('/v1/case/repayment/invalid', data)
}

export const getReductions = (searchData) => {
  searchData = filterSearchFields(searchData)

  return post('/v1/cases/reduction/list', searchData, { noSucTip: true })
}

// 案件审批 status 通过1  拒绝2
export const handleReductions = (data) => {
  return put('/v1/cases/reduction', data)
}
// 导出
export const exportCaseOpe = ({ taskId }) => {
  return `/xapi/v1/case/operation/exportCaseOpe?taskId=${taskId}&downloadT=${Date.now()}`
}
// 获取导出地址
export const getExportUrl = ({ taskId }) => {
  return get('/v1/case/operation/downloadUrl/' + taskId, null)
}
// 添加催记下载任务
export const addExportPlan = (data) => {
  return post('/v1/case/operation/addDownloadTask', data, { noSucTip: true })
}
// task list
export const exportList = (data) => {
  return get('/v1/case/operation/listDownloadTask', data)
}

export const addAutomatic = (data) => {
  return post('/v1/case/operation/addAutomatic', data, { noSucTip: true })
}
// v1/case/operation/delete
export const operationDelete = data => {
  data = filterSearchFields(data)
  return post('/v1/case/operation/delete', data)
}
export const getList = data => {
  return get('/v1/task/getList', data)
}

// export const getOperationState = ({id}) => {
//   return get('/v1/case/operation/lastOperationState/'+id, null)
// }

export const getDebtList = (searchData) => {
  return get('/v1/case/operation/sameDebtList', searchData)
}

// 任务列表
export const getTaskList = (data) => {
  return get('v1/case/repayment/exportTasks', data)
}

export const createExportTask = (data) => {
  return post('v1/case/repayment/addExportTask', data, { noSucTip: true })
}

export const reductionTaskList = (data) => {
  return get('/v1/cases/reduction/exportTasks', data)
}

export const createReductionExportTask = (data) => {
  return post('/v1/cases/reduction/addExportTask', data, { noSucTip: true })
}

export const getAssistApplyList = (searchData) => {
  searchData = filterSearchFields(searchData)

  return post('/v1/assistApply/list', searchData, { noSucTip: true })
}

export const addAssistApply = (data) => {
  return postForm('/v1/assistApply/add', data)
}

export const auditAssistApply = (data) => {
  return postForm('/v1/assistApply/audit', data)
}

export const replyAssistApply = (data) => {
  return postForm('/v1/assistApply/reply', data)
}

// export const getRecording = (data) => {
//   return get('/v1/case/operation/recording', data)
// }

export const repaymentStatistic = (data) => {
  data = filterSearchFields(data)
  return post('/v1/case/repayment/repaymentStatistic', data, { noSucTip: true })
}

// 申请减免
export const deductionApplication = (data) => {
  return post('/v1/cases/reduction/add', data)
}
/**
 * 减免分期
 */
// 减免申请
export const reduceApply = data => {
  return post('/v1/spd/p/reduce/apply', data)
}
// 减免申请列表 GET/v1/spd/p/reduce/apply/list
export const reduceApplyList = data => {
  return get('/v1/spd/p/reduce/apply/list', data)
}
// 减免历史列表 GET/v1/spd/p/reduce/history/list
export const reduceHistoryList = data => {
  return get('v1/spd/p/reduce/history/list', data)
}

// 电子信函申请列表
export const emailList = data => {
  return get('/v1/spd/p/letter/apply/list', data)
}

// 电子信函申请
export const emailApply = data => {
  return post('/v1/spd/p/letter/apply', data)
}

// 业绩核对列表
export const achieveList = data => {
  return get('/v1/spd/p/achieve/list', data)
}

// 业绩核对提交
export const achieveSubmit = data => {
  return post('/v1/spd/p/achieve/submit', data)
}

// 业绩核对查询结果
export const achieveUpdate = id => {
  return get(`/v1/spd/p/achieve/result/update?achieveId=${id}`)
}

// 佣金核对列表
export const commisionList = data => {
  return get('/v1/spd/p/commision/list', data)
}

// 佣金提交
export const commisionSubmit = data => {
  return post('/v1/spd/p/commision/submit', data)
}

// 佣金核对结果更新
export const commisionUpdate = id => {
  return get(`/v1/spd/p/commision/result/update?commisionId=${id}`)
}
// 催记补传列表
export const compensateList = data => {
  return get('/v1/spd/p/operation/compensate/list', data)
}

// 催记补传
export const submitCompensate = data => {
  return post('/v1/spd/p/operation/compensate', data)
}

// 业绩汇总字段
export const summaryList = searchData => {
  let forbidRequest = true
  if (searchData.achieveDate) {
    forbidRequest = false
  }

  if (forbidRequest) {
    return new Promise((resolve, reject) => {
      window.VueMainInstance.$Message.error('请输入筛选项后再查询！')
      resolve([])
    })
  }
  filterSearchYearDate(searchData)
  return get('/v1/spd/p/achieve/summary/list', searchData)
}

// 业绩明细列表
export const detailList = searchData => {
  let forbidRequest = true
  if ((searchData.custNbr && searchData.achieveDate) || searchData.queueType && searchData.achieveDate) {
    forbidRequest = false
  }

  if (forbidRequest) {
    return new Promise((resolve, reject) => {
      window.VueMainInstance.$Message.error('请输入筛选项后再查询！')
      resolve([])
    })
  }
  filterSearchYearDate(searchData)
  return post('/v1/spd/p/achieve/detail/list', searchData)
}

// 业绩目标回收率列表
export const goalBackRateList = searchData => {
  let forbidRequest = true
  if (searchData.queueName && searchData.yearDate) {
    forbidRequest = false
  }

  if (forbidRequest) {
    return new Promise((resolve, reject) => {
      window.VueMainInstance.$Message.error('请输入筛选项后再查询！')
      resolve([])
    })
  }
  filterSearchYearDate(searchData)
  return post('/v1/spd/p/achieve/goalBackRate/list', searchData, { noSucTip: true })
}

// 用户班表列表
export const infoList = searchData => {
  const keys = Object.keys(searchData)
  let forbidRequest = true
  for (const item of keys) {
    if (item === 'compLoginId') {
      forbidRequest = false
    }
  }
  if (forbidRequest) {
    return new Promise((resolve, reject) => {
      window.VueMainInstance.$Message.error('请输入用户登录账号再查询！')
      resolve([])``
    })
  }
  return get('/v1/spd/p/scheduling/info/list', searchData)
}

// 用户账号列表
export const compUserList = (searchData) => {
  const keys = Object.keys(searchData)
  let forbidRequest = true
  for (const item of keys) {
    if (item === 'branchName' || item === 'compLoginId' || item === 'compUserNbr' || item === 'operLocationName') {
      forbidRequest = false
    }
  }
  if (forbidRequest) {
    return new Promise((resolve, reject) => {
      window.VueMainInstance.$Message.error('请至少选择一个筛选项后再查询！')
      resolve([])
    })
  }
  return post('/v1/spd/p/compUser/info/list', searchData, { noSucTip: true })
}

// 实时还款信息列表
export const realTimeRepaymentList = searchData => {
  const keys = Object.keys(searchData)
  let forbidRequest = true
  for (const item of keys) {
    if (item === 'acctNbr') {
      forbidRequest = false
    }
  }
  if (forbidRequest) {
    return new Promise((resolve, reject) => {
      window.VueMainInstance.$Message.error('请选择账号后再查询！')
      resolve([])
    })
  }
  return post('/v1/spd/p/realTimeRepayment/info/list', searchData)
}

// 历史还款信息列表
export const historyRepaymentList = searchData => {
  let forbidRequest = true
  if (searchData.startDate && searchData.endDate) {
    forbidRequest = false
    searchData.startDate = filterDate(searchData.startDate)
    searchData.endDate = filterDate(searchData.endDate)
  }

  if (forbidRequest) {
    return new Promise((resolve, reject) => {
      window.VueMainInstance.$Message.error('请输入筛选项后再查询！')
      resolve([])
    })
  }
  return post('/v1/spd/p/historyRepayment/info/list', searchData)
}

// 退单流水信息列表
export const returnFlowList = searchData => {
  let forbidRequest = true
  if (searchData.startDate && searchData.endDate) {
    forbidRequest = false
    searchData.startDate = filterDate(searchData.startDate)
    searchData.endDate = filterDate(searchData.endDate)
  }

  if (forbidRequest) {
    return new Promise((resolve, reject) => {
      window.VueMainInstance.$Message.error('请输入筛选项后再查询！')
      resolve([])
    })
  }
  return post('/v1/spd/p/returnFlow/info/list', searchData)
}

// 下载信函
export const downloadLetter = id => {
  return get(`/v1/spd/p/letter/apply/download?applyId=${id}`)
}

// 减免申请处理状态更新
export const updateReduce = id => {
  return get(`/v1/spd/p/reduce/apply/update?applyId=${id}`)
}
