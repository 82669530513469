import { put, get, post, del } from '@/libs/api.request'

export const toggleConnectedDuyan = (isConnect) => {
  return put(`/v1/org/connectDuyan/${isConnect}`)
}

export const toggleAutoUpdatedOverdueDays = (isOpen) => {
  return put(`/v1/org/autoUpdateOverdueDays/${isOpen}`)
}

export const toggleDesensitizationRule = (isOpen, data) => {
  return post(`/v1/org/desensitizationRule/${isOpen}`, data)
}

export const getIpList = (target) => {
  return get(`/v1/org/listIps/${target}`)
}

export const toggleAddIpLimit = (data) => {
  return post(`/v1/org/ipAccessSwitch`, data)
}

export const deleteIp = (target, ip) => {
  return put(`/v1/org/deleteIp/${target}/${ip}`)
}

export const getRefundResult = (data) => {
  return get('/v1/operationStatus/list', data)
}

export const addNewResults = (data) => {
  return post('/v1/operationStatus/add', data)
}

export const editResults = (data) => {
  return post('/v1/operationStatus/edit', data)
}

export const delResults = (data) => {
  return del(`/v1/operationStatus/delete/${data.id}`)
}

export const reorderOperationStatus = (data) => {
  return post('/v1/operationStatus/reorder', data, { noSucTip: true })
}

export const setConjointType = (data) => {
  return put(`/v1/org/setConjointType/${data.type}`)
}

export const getConjointType = (data) => {
  return get('/v1/org/getConjointType')
}

export const getIpAccess = (data) => {
  return get('/v1/org/getIpAccessResult', data)
}

export const getSysContactResult = (data) => {
  return get('/v1/org/getSysContactResult', data)
}

export const setSysContactResult = (data) => {
  return put('/v1/org/setSysContactResult', data)
}

export const setSwitch = (data) => {
  return put(`/v1/org/setSwitch/${data.switchName}/${data.switchOpen}`)
}

export const setCaseCooperation = (data) => {
  return put('/v1/org/setCaseCooperation', data)
}

export const orgConfigList = (data) => {
  return get(`/v1/orgConfig/list/${data.type}`)
}
// 获取诉讼
export const getLitigation = (data) => {
  return get(`/v1/lawsuit/config/lawsuitList/${data.type}`)
}
// 添加诉讼节点
export const addLitigation = (data) => {
  return post('/v1/lawsuit/config/add', data)
}
// 编辑诉讼设置
export const editLitigation = (data) => {
  return put('/v1/lawsuit/config/update', data)
}
// 删除诉讼节点
export const delLitigation = ({ id }) => {
  return put(`/v1/lawsuit/config/delete/${id}`)
}
// 更新节点开关
export const updateLitigation = (data) => {
  return put('/v1/lawsuit/config/updateSwitch', data)
}

export const reorder = (data) => {
  return post('/v1/orgConfig/reorder', data, { noSucTip: true })
}

export const editOrgConfig = (data) => {
  return post('/v1/orgConfig/edit', data)
}

export const setWatermarkPattern = (data) => {
  return put('/v1/org/setWatermarkPattern', data)
}

export const addOrgConfig = (data) => {
  return post('/v1/orgConfig/add', data)
}

export const deleteOrgConfig = ({ id }) => {
  return del(`/v1/orgConfig/delete/${id}`)
}

export const exportUser = (data) => {
  return post('/v1/user/export', data)
}

export const postTeamUrgeSwitch = (isOpen, teamIdList) => {
  return post(`/v1/org/teamUrgeSwitch/${isOpen}`, teamIdList)
}

export const getUrgeSwitchTeamList = () => {
  return get('/v1/org/urgeSwitchTeamList')
}

export const getNoticeMsg = (data) => {
  return get('/v1/notice/msg', data)
}

// 获取公司公告
export const getNotice = () => {
  return get('/v1/org/notice')
}

// 添加公司公告
export const addNotice = (data) => {
  return post('/v1/org/notice', data, { noSucTip: true })
}

// 设置字段颜色
export const setColorField = (data) => {
  return put('/v1/org/setColorField', data)
}

// 案件资料导入查询
export const getAttachImport = (data) => {
  return get('/v1/cases/attachImport/list', data)
}

// 案件资料导入
export const postAttachImport = (data) => {
  return post('/v1/cases/attachImport', data)
}

// 获取结案类型列表
export const getEndTypeList = (data) => {
  return get('/v1/caseEndConfig/list', data)
}

// 新增结案类型
export const addEndType = (data) => {
  return post('/v1/caseEndConfig/add', data)
}

// 编辑结案类型
export const updateEndType = (data) => {
  return put('/v1/caseEndConfig/update', data)
}

// 删除结案类型
export const deleteEndType = (id) => {
  return put(`/v1/caseEndConfig/delete/${id}`)
}
// 自定义标签查询
export const getLabelList = data => {
  return get('/v1/customClientLabel/list', data)
}
// 自定义标签排序
export const sortLabel = data => {
  return post('/v1/customClientLabel/reorder', data)
}
// 新增自定义标签
export const addLabel = data => {
  return post('/v1/customClientLabel/add', data)
}
// 编辑自定义标签
export const editLabel = data => {
  return post('/v1/customClientLabel/edit', data)
}
// 启用自定义标签
export const enableLabel = data => {
  return post('/v1/customClientLabel/enable', data)
}
// 删除标签
export const delLabel = data => {
  return del('v1/customClientLabel/delete/' + data.id)
}
// 手机号加密
export const encryPhone = data => {
  return post('v1/cmbc/p/toWellCloud', data, { hasErrorRes: true, noSucTip: true, noErrTip: true })
}
// 获取催收进程配置
export const getOperationLink = (data) => {
  return post('/v1/operation/config/link/list', data, { noSucTip: true })
}

// 删除催收配置
export const delConfigLink = (data) => {
  return del('/v1/operation/config/link', data)
}

// 添加催收配置
export const setConfigLink = (data) => {
  return post('/v1/operation/config/link', data)
}
// /v1/org/setRecordAddrPrefix 设置本地化录音地址前缀

export const setRecordAddrPrefix = data => {
  return post('/v1/org/setRecordAddrPrefix?prefix=' + data.prefix, data)
}
// /v1/org/getRecordAddrPrefix
export const getRecordAddrPrefix = () => {
  return get('/v1/org/getRecordAddrPrefix')
}