import { get, post, put, del } from '@/libs/api.request'

const filterSearchFields = (data) => {
  for (const key in data) {
    if (typeof (data[key]) === 'string' && ['names', 'mobiles'].includes(key)) {
      data[key] = data[key].trim().replace(/\n/g, ',').split(',')
    }
  }
  return data
}

export const getContact = (searchData) => {
  return get('/v1/contacts', searchData)
}
// 民生银行获取联系人列表
export const getByCaseId = (data) => {
  return get('/v1/contacts/getByCaseId', data)
}
export const addContact = (contact) => {
  return post('/v1/contacts', contact)
}

export const updateContact = (contact) => {
  return put('/v1/contacts', contact)
}

export const contactFixApply = (contact) => {
  return post('/v1/contactsfix', contact)
}

export const rejectContactFixApply = (contact) => {
  return post('/v1/contactsfix', contact)
}

export const getContactFixList = (contact) => {
  return get('/v1/repair/applyList', contact)
}

export const getContactFixRecord = (contact) => {
  return get('/v1/repair/recordList', contact)
}

export const applyRepair = (searchData) => {
  return post('/v1/repair/apply', searchData)
}

export const addPlan = (contact) => {
  return post('/v1/repair', contact)
}

export const cancelRepair = (contact) => {
  return post('/v1/repair/reject', contact)
}

export const getFixedContact = (id) => {
  return get(`/v1/repair/successInfos/${id}`)
}
// 同步联系人
export const addSyncConcat = (data) => {
  return post('/v1/contacts/sync', data)
}

export const changeStatus = data => {
  return post('/v1/contacts/changeStatus', data)
}
export const deleteContact = data => {
  return post('/v1/contacts/delete', data)
}

export const getContactsList = (data) => {
  data = filterSearchFields(data)

  return post('/v1/contacts/list', data, { noSucTip: true })
}

export const delBatch = (data) => {
  data = filterSearchFields(data)

  return del('/v1/contacts/batch', data)
}

export const getRecord = (data) => {
  return get('/v1/contacts/operation/record', data)
}

export const batchContacts = (data) => {
  return put('/v1/contacts/batch', data, { noSucTip: true })
}


// 获取号码类型列表
export const getNumberList = (data) => {
  return get('/v1/contacts/type/list',data)
}
// 号码类型排序
export const sortNumber = (data) => {
  return post('/v1/contacts/type/sort',data)
}
// 启用/禁用号码类型
export const enableNumberType = ({id,enable}) => {
  return post(`/v1/contacts/type/enable/${id}`+ '?enable='+ enable)
}
// 删除号码类型
export const delNumberType =(id) => {
  return post(`/v1/contacts/type/del/${id}`)
}
// 添加号码类型
export const addNumberType =(data) => {
  return post('/v1/contacts/type/add'+ '?name=' + data)
}
// 编辑号码类型
export const editNumberType = ({id,name}) => {
  return post(`/v1/contacts/type/update/${id}`+'?name='+ name)
}