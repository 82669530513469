import {
  getBreadCrumbList,
  setTagNavListInLocalstorage,
  getTagNavListFromLocalstorage,
  getRouteByName,
  getNextRoute,
  routeHasExist,
  routeEqual,
  getRouteTitleHandled,
  localSave,
  localRead,
  generateUuid
} from '@/libs/util.js?t=20221115'
import beforeClose from '@/router/before-close'
import router from '@/router'
import config from '@/config'
import i18n from '@/locale'
const { homeName } = config

const closePage = (state, route) => {
  const nextRoute = getNextRoute(state.tagNavList, route)
  state.tagNavList = state.tagNavList.filter(item => {
    return !routeEqual(item, route)
  })
  router.push(nextRoute)
}

const $t = (key) => {
  return i18n.t(key)
}

export default {
  state: {
    breadCrumbList: [],
    tagNavList: getTagNavListFromLocalstorage() || [],
    homeRoute: {},
    local: localRead('local'),
    errorList: [],
    hasReadErrorPage: false,
    vcodeImg: '/xapi/v1/user/validateCode?v=' + (new Date()).getTime() + '&imageUuid=' + generateUuid()
  },
  getters: {
    errorCount: state => state.errorList.length,
    vcodeImg: state => state.vcodeImg,
    tagNavList: state => state.tagNavList
  },
  mutations: {
    refreshVcodeImg(state) {
      state.vcodeImg = '/xapi/v1/user/validateCode?v=' + (new Date()).getTime() + '&imageUuid=' + generateUuid()
    },
    setBreadCrumb(state, route) {
      state.breadCrumbList = getBreadCrumbList(route, state.homeRoute)
    },
    setHomeRoute(state, routes) {
      state.homeRoute = getRouteByName(routes, homeName)
    },
    setTagNavList(state, list) {
      let tagList = []
      if (list) {
        tagList = [...list]
      } else tagList = getTagNavListFromLocalstorage() || []

      if (tagList[0] && tagList[0].name !== homeName) tagList.shift()
      const homeTagIndex = tagList.findIndex(item => item.name === homeName)
      if (homeTagIndex > 0) {
        const homeTag = tagList.splice(homeTagIndex, 1)[0]
        tagList.unshift(homeTag)
      }

      // 国际化
      for (const value of tagList) {
        if (value.name !== 'caseDetail' && value.name !== 'companyAdmin') {
          value.meta.title = $t('m_' + value.name)
        }
      }

      const tagLists = [...tagList]
      tagList = []
      let isUniq = true
      let isIndex = -1

      tagLists.forEach(item => {
        tagList.forEach((i, index) => {
          /**
           * 判断params.id是否存在
           */
          if (item.params && i.params && item.params.id && i.params.id) {
            /**
             * 区分案件列表层级的跳转和催员案件详情
             *  案件列表层级params无id属性或id为null，催员案件详情paramsid必定有值
             */
            if (item.params.id === i.params.id && item.meta.title === i.meta.title) {
              isUniq = false
              isIndex = index
            }
          } else {
            if (item.meta.title === i.meta.title && item.name === i.name) {
              isUniq = false
              isIndex = index
            }
          }
        })

        if (isUniq) {
          tagList.push(item)
        } else {
          /**
           * 若存在判定为相同的路由，则后一个路由取代前一个路由
           */
          if (isIndex > -1) {
            tagList[isIndex] = { ...item }
          }
        }
      })

      state.tagNavList = [...tagList]
      setTagNavListInLocalstorage([...tagList])
    },
    closeFocusPage(state, route) {
      const nextRoute = getNextRoute(state.tagNavList, route)
      state.tagNavList = state.tagNavList.filter(item => {
        return !routeEqual(item, route)
      })
      router.push(nextRoute)
    },
    closeTag(state, route) {
      const tag = state.tagNavList.filter(item => routeEqual(item, route))
      route = tag[0] ? tag[0] : null
      if (!route) return
      if (route.meta && route.meta.beforeCloseName && route.meta.beforeCloseName in beforeClose) {
        new Promise(beforeClose[route.meta.beforeCloseName]).then(close => {
          if (close) {
            closePage(state, route)
          }
        })
      } else {
        closePage(state, route)
      }
    },
    addTag(state, { route, type = 'unshift' }) {
      const router = getRouteTitleHandled(route)

      if (!routeHasExist(state.tagNavList, router)) {
        if (type === 'push') state.tagNavList.push(router)
        else {
          if (router.name === homeName) state.tagNavList.unshift(router)
          else state.tagNavList.splice(1, 0, router)
        }

        setTagNavListInLocalstorage([...state.tagNavList])
      }
    },
    setLocal(state, lang) {
      localSave('local', lang)
      state.local = lang
    },
    addError(state, error) {
      state.errorList.push(error)
    },
    setHasReadErrorLoggerStatus(state, status = true) {
      state.hasReadErrorPage = status
    }
  },
  actions: {
    addErrorLog({ commit, rootState }, info) {

      // if (!window.location.href.includes('error_logger_page')) commit('setHasReadErrorLoggerStatus', false)
      // const { user: { token, userId, userName } } = rootState
      // let data = {
      //   ...info,
      //   time: Date.parse(new Date()),
      //   token,
      //   userId,
      //   userName
      // }
      // saveErrorLogger(info).then(() => {
      //   commit('addError', data)
      // })
    }
  }
}
