import { get, post, put } from '@/libs/api.request'

export const login = (account) => {
  account.password = btoa(account.password)
  return post(
    'v1/user/login',
    account,
    { noSucTip: true, hasErrorRes: true }
  )
}

export const logout = (data) => {
  return get('/v1/user/logout', data, { noSucTip: true, noErrTip: true })
}

export const redirect = (data) => {
  return get('v1/user/redirectLogin', data)
}

export const getInfo = ({ roleId, userId }) => {
  return post(
    'v1/user/choice',
    { roleId, userId },
    { noSucTip: true, hasErrorRes: true }
  )
}

export const getDesensitizationRules = () => {
  return get('v1/dictionary/desensitizationRules')
}

export const changePwd = ({ id, newPassword, oldPassword, validateCode, imageUuid }) => {
  newPassword = btoa(newPassword)
  oldPassword = btoa(oldPassword)

  return put(
    'v1/user/password',
    { id, newPassword, oldPassword, validateCode, imageUuid }
  )
}

export const forgetPwd = (data) => {
  data.newPassword = btoa(data.newPassword)
  return put('v1/user/password2', data)
}

export const resetPwd = (id) => {
  return put(
    `v1/user/init/${id}`, null,
    { noSucTip: true }
  )
}

export const getFollowCases = (searchData) => {
  return get('v1/case/operation/home', searchData)
}

export const getCasesData = (id) => {
  return get(`v1/cases/statisAgentCases/${id}`)
}
// 质检员登陆
export const inspectorLogin = data => {
  return post('v1/user/inspectorLogin', data)
}
export const getAgents = data => {
  return get('v1/user/fifoAgents', data)
}

export const getVerifyCode = data => {
  return get('v1/user/sendSmsCode', data)
}

export const checkVerifyCode = data => {
  return post('v1/user/smsCodeValidate', data)
}

export const deletePhone = (id) => {
  return put(`v1/user/mobileInvalid/${id}`)
}

export const dunnerTree = (data) => {
  return get('/v1/user/dunnerTree', data)
}
