import { get, post, put, del } from '@/libs/api.request'

export const getProd = (searchData) => {
  return get('/v1/delt/product', searchData)
}

export const getCaseProd = (searchData) => {
  return get('/v1/delt/product/caseList', searchData)
}

export const getNotCaseProd = (searchData) => {
  return get('/v1/delt/product/caseNotList', searchData)
}

export const getCaseUserProd = (searchData) => {
  return get('/v1/delt/product/caseUserList', searchData)
}

export const addProd = (delt) => {
  return post('/v1/delt/product', delt)
}

export const updateProd = (delt) => {
  return put('/v1/delt/product', delt)
}

export const delProd = ({ id }) => {
  return del('/v1/delt/product/details/{id}')
}
