import { put, get, post, del, postStream } from '@/libs/api.request'

const filterSearchFields = (data) => {
  data['searchKeyParam'] = {}
  for (const key in data) {
    if (key.indexOf('search_key') !== -1) {
      const json = {}
      json[key] = data[key].split(',')
      data['searchKeyParam'] = { ...data['searchKeyParam'], ...json }
      delete data[key]
    }
    if (typeof (data[key]) === 'string' && ['names', 'mobiles', 'contactMobiles', 'outSerialNos', 'idCards', 'nameList', 'outSerialTempList', 'ownMobiles', 'letterIds'].includes(key)) {
      data[key] = data[key].trim().replace(/\n/g, ',').split(',')
    }
  }
  return data
}

const handleOverdueDays = (data) => {
  if (data.enableAutoUpdateOverdueDays) {
    if (data.overdueDate) {
      delete data.overdueDaysStart
      delete data.overdueDaysEnd
    } else if (data.overdueDaysStart || data.overdueDaysEnd || (data.overdueDaysStart === 0) || (data.overdueDaysEnd === 0)) {
      let overdueDaysStart = (data.overdueDaysStart || data.overdueDaysStart === 0) ? (new Date().getTime()) - (data.overdueDaysStart * 24 * 3600 * 1000) : ((data.overdueDaysEnd || data.overdueDaysEnd === 0) ? new Date().getTime() : null)

      if (overdueDaysStart) {
        overdueDaysStart = new Date(new Date(overdueDaysStart).toLocaleDateString()).getTime() + ',' + (new Date(new Date(overdueDaysStart).toLocaleDateString()).getTime() + 24 * 3600 * 1000 - 1)
      }

      let overdueDaysEnd = (data.overdueDaysEnd || data.overdueDaysEnd === 0) ? (new Date().getTime()) - (data.overdueDaysEnd * 24 * 3600 * 1000) : null

      if (overdueDaysEnd) {
        overdueDaysEnd = new Date(new Date(overdueDaysEnd).toLocaleDateString()).getTime() + ',' + (new Date(new Date(overdueDaysEnd).toLocaleDateString()).getTime() + 24 * 3600 * 1000 - 1)
      }

      if (overdueDaysStart && !overdueDaysEnd) {
        const overdueDaysStarts = overdueDaysStart.split(',')
        data['overdueDate'] = `,${overdueDaysStarts[1]}`
      } else {
        const overdueDaysEnds = overdueDaysEnd.split(',')
        const overdueDaysStarts = overdueDaysStart.split(',')
        data['overdueDate'] = `${overdueDaysEnds[0]},${overdueDaysStarts[1]}`
      }

      delete data.overdueDaysStart
      delete data.overdueDaysEnd
    }
  }

  delete data.enableAutoUpdateOverdueDays

  return data
}

const handleMoreSearch = (data) => {
  if (data.searchs) {
    if (/^\d{11}$/.test(data.searchs)) {
      data.contactMobiles = [data.searchs]
    } else if(data.searchs.length === 18){
      data.idCards = [data.searchs]
    } else {
      data.names = [data.searchs]
    }
    delete data.searchs
  }

  return data
}

export const templateList = (data) => {
  return get('/v1/letter/template', data)
}

export const addLetterTemplate = (data) => {
  return post('/v1/letter/template', data)
}

export const getLetterTemp = ({ id }) => {
  return get(`/v1/letter/template/${id}`)
}

export const updateLetterTemplate = (data, { id }) => {
  return put(`/v1/letter/template/${id}`, data)
}

export const previewTemp = (data) => {
  return postStream(`/v1/letter/template/preview?templateId=${data.templateId}`, null, { noSucTip: true, noErrTip: true, hasErrorRes: true })
}

export const getSealList = (data) => {
  return get('/v1/letter/seal', data)
}

export const addSeal = (data) => {
  return post('/v1/letter/seal', data)
}

export const updateSeal = (data, id) => {
  return post(`/v1/letter/seal/${id}`, data, { noSucTip: true })
}

export const getSysLetter = (data) => {
  return get(`/v1/letter/template/system/${data.businessType}`)
}

export const addCaseLetter = (data) => {
  data = handleOverdueDays(data)
  data = filterSearchFields(data)
  data = handleMoreSearch(data)
  return post('/v1/cases/letter', data, { noSucTip: true })
}

export const letterList = (data) => {
  data = filterSearchFields(data)
  return post('/v1/letter/list', data, { noSucTip: true })
}

export const deleteLetter = (data) => {
  data = filterSearchFields(data)
  return del('/v1/letter', data, { noSucTip: true })
}

export const exportLetter = (data) => {
  data = filterSearchFields(data)
  return post('/v1/letter/export', data, { noSucTip: true })
}

export const downloadTask = (data) => {
  return get('/v1/letter/downloadTask', data)
}

export const letterListNoPage = (data) => {
  return get('/v1/letter/template/list', data)
}

export const getSealListNoPage = (data) => {
  return get('/v1/letter/seal/list', data)
}

export const getSealListByPage = (data) => {
  return get('/v1/letter/seal/listByPage', data)
}

export const createFile = (data) => {
  data = filterSearchFields(data)
  return post('/v1/letter/create/file', data, { noSucTip: true })
}

export const createOfflineFile = (data) => {
  data = filterSearchFields(data)
  return post('/v1/letter/create/offlineFile', data, { noSucTip: true })
}

export const updateLetter = (data) => {
  data = filterSearchFields(data)
  return post('/v1/letter/send', data)
}

export const updateOneLetter = (data, id) => {
  return put(`/v1/letter/${id}`, data)
}

export const oplogRecord = (data) => {
  return get('/v1/letter/operation/record', data)
}

export const letterFileExport = (data) => {
  return get('/v1/letter/file/downloadTask', data)
}

export const emailTemplate = (data) => {
  return get('/v1/email/template', data)
}

export const letterRecordList = (data) => {
  data = filterSearchFields(data)
  return post('/v1/letter/send/record', data, { noSucTip: true })
}
// 获取自定义变量
export const customField = (data) => {
  return get('/v1/letter/custom/field', data)
}

export const delLetterTpl = (data) => {
  return del(`/v1/letter/template/${data.id}`)
}

export const delSeal = (data) => {
  return put(`/v1/letter/seal/${data.id}`)
}

export const uploadImg = (data) => {
  return post('/v1/letter/image', data, { noSucTip: true })
}

// 批量删除函件
export const delVisit = (data) => {
  return del('/v1/letter/file/batch', data)
}

// 删除线下函件
export const delBatchOffline = (data) => {
  data = filterSearchFields(data)
  return del('/v1/letter/offlineLetter/batch', data)
}

// 发函文件操作记录
export const getLetterRecord = (data) => {
  return get('/v1/letter/file/record',data)
}

// 线下函件导出
export const addExportOffline = (data) => {
  data = filterSearchFields(data)
  return post('/v1/letter/offlineLetter/addExportTask', data)
}

// 线下函件列表
export const offlineList = (data) => {
  data = filterSearchFields(data)
  return post('/v1/letter/offlineList', data, { noSucTip: true })
}

// 单线下文件导出
export const singleExportOffline = (data) => {
  return get('/v1/letter/offlineLetter/singleExport', data)
}
