import { get, post, del } from '@/libs/api.request'

export const getStrategy = (data) => {
  return post(`/v1/allot/strategy/list`, data, { noSucTip: true })
}

export const getStrategyDetail = (id) => {
  return get(`/v1/allot/strategy/detail`, id)
}

export const addStrategy = (data) => {
  return post(`/v1/allot/strategy/save`, data)
}

export const editStrategyName = (data) => {
  return post(`/v1/allot/strategy/editName`, data)
}

export const delStrategy = (data) => {
  return del(`/v1/allot/strategy/delete?id=${data.id}`, data)
}
/**
 * @param {*} 决策🌲相关api
 */
// 添加策略
export const addDecision = data => {
  return post('/v1/strategy/add', data)
}
// 获取策略详情
export const getDecision = data => {
  return get('/v1/strategy/detail', data)
}
// 策略启用
export const enableDecision = data => {
  return post('/v1/strategy/enable', data, { noSucTip: true })
}
// 策略列表
export const getDecisionList = data => {
  return get('/v1/strategy/list', data)
}
// 策略更新
export const updateDecision = data => {
  return post('/v1/strategy/update', data, { noSucTip: true })
}
export const delDecision = id => {
  return del('v1/strategy/delete?id=' + id)
}
// 策略复制
export const copyDecision = id => {
  return post('/v1/strategy/copy?strategyId=' + id, {}, { noSucTip: true })
}
/**
 * 智能计划管理
 */
export const getTemplateList = data => {
  return get('/v1/case/planTemplate/templateList', data)
}
export const getTemplateDetail = id => {
  return get('/v1/case/planTemplate/templateDetail?id=' + id)
}
export const addPlanTemplate = data => {
  return post('/v1/case/planTemplate/addPlanTemplate', data)
}
export const updatePlanTemplate = data => {
  return post('/v1/case/planTemplate/updatePlanTemplate', data)
}
export const delTemplate = id => {
  return del('/v1/case/planTemplate/delete?id=' + id)
}
// /v1/case/planTemplate/relList?tmplId=1
export const getStraList = data => {
  return get('/v1/case/planTemplate/relList', data)
}

export const tempList = (data) => {
  return get('/v1/case/allotTemplate/list', data)
}

export const getTmpDetail = (data) => {
  return get('/v1/case/allotTemplate/detail', data)
}

export const delAllotTmp = (id) => {
  return del(`/v1/case/allotTemplate/delete?id=${id}`)
}

export const addAllotTemplate = (data) => {
  return post('/v1/case/allotTemplate/add', data)
}

export const relAllotList = (data) => {
  return get('/v1/case/allotTemplate/relList', data)
}

export const getAllotDetail = (data) => {
  return get('/v1/case/allotTemplate/detail', data)
}

export const updateAllotTmp = (data) => {
  return post('/v1/case/allotTemplate/update', data)
}

export const addStrategys = (data) => {
  return post('/v1/allot/strategy/add', data)
}

export const updateStrategys = (data) => {
  return post('/v1/allot/strategy/edit', data)
}

export const getNoPageStrategy = (data) => {
  return get(`/v1/allot/strategy/listAll`, data)
}

export const doAllot = (data) => {
  return post('/v1/allot/strategy/doAllot', data)
}
