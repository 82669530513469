import Vue from 'vue'
import VueI18n from 'vue-i18n'
import customZhCn from './lang/zh-CN'
import config from '@/config/index'
// import customZhTw from './lang/zh-TW'
import customEnUs from './lang/en-US'
import zhCnLocale from 'view-design/src/locale/lang/zh-CN'
import enUsLocale from 'view-design/src/locale/lang/en-US'
// import zhTwLocale from 'view-design/src/locale/lang/zh-TW'
import zhTJ from './lang/zh-TJ'

Vue.use(VueI18n)

// 自动根据浏览器系统语言设置语言
const navLang = config.useI18n ? navigator.language : 'zh-CN'
const localLang = (navLang === 'zh-CN' || navLang === 'en-US') ? navLang : false
const lang = localStorage.getItem('local') || localLang || 'zh-CN'

Vue.config.lang = lang
// vue-i18n 6.x+写法
Vue.locale = () => {}
const messages = {
  'zh-CN': Object.assign(zhCnLocale, customZhCn),
  // 'zh-TW': Object.assign(zhTwLocale, customZhTw),
  'en-US': Object.assign(enUsLocale, customEnUs),
  'tj': Object.assign({}, { ...zhCnLocale, ...customZhCn, ...zhTJ })
}
const i18n = new VueI18n({
  locale: lang, // 设置地区
  messages // 设置地区信息
})

export default i18n

// vue-i18n 5.x写法
// Vue.locale('zh-CN', Object.assign(zhCnLocale, customZhCn))
// Vue.locale('en-US', Object.assign(zhTwLocale, customZhTw))
// Vue.locale('zh-TW', Object.assign(enUsLocale, customEnUs))
