import { get, post, put, del } from '@/libs/api.request'

export const getDep = (searchData) => {
  return get('/v1/dep', searchData)
}

export const getDepDet = ({ id }) => {
  return get('/v1/dep/details/' + id)
}

export const addDep = (delt) => {
  return post('/v1/dep', delt)
}

export const updateDep = (delt) => {
  return put('/v1/dep', delt)
}

export const delDep = ({ id }) => {
  return del('/v1/dep/' + id)
}

export const getTeamList = (data) => {
  return get('/v1/dep/teamList', data)
}

export const getDepTeamList = (data) => {
  return get('/v1/dep/depTeamList', data)
}

export const getDepList = (data) => {
  return get('/v1/dep/depList', data)
}

export const updateDepNo = (data) => {
  return post('/v1/dep/teamNo', data)
}
