import { get } from '@/libs/api.request'

// 存储空间信息
export const spaceInfo = (data) => {
  return get('/v1/space', data)
}

// 获取购买记录
export const buySpace = (data) => {
  return get('/v1/space/record', data)
}
