// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
// import { useRegisterSW } from 'virtual:pwa-register/vue'
import { registerSW } from 'virtual:pwa-register'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import router from './router'
import store from './store'
import iView from 'view-design'
import i18n from '@/locale'
import config from '@/config'
import importDirective from '@/directive'
// import installPlugin from '@/plugin'
import '@/libs/polly.js'
import '@/style/index.less'
import '@/style/vxeTable.scss'
// import '@/assets/icons/iconfont.css'
import '@/assets/iconfont/index.css'
// import '@/assets/iconfont/iconfont.css'
import '@/assets/icon/iconfont.css'
import '@/assets/lhjf-common/iconfont.css'
import VueClipboard from 'vue-clipboard2'
import announcement from '_vc/announcement/announcement.vue'
import jQuery from 'jquery';
Vue.component('announcement', announcement)
Vue.use(iView, {
  i18n: (key, value) => i18n.t(key, value)
})
// Vue.config.silent = true
// service work 注册
registerSW()
VXETable.setup({
  zIndex: 9999
})
Vue.use(VXETable)

Vue.use(VueClipboard)

/**
 * @description 注册admin内置插件
 */
// installPlugin(Vue)
/**
 * @description 生产环境关掉提示
 */
Vue.config.productionTip = false
/**
 * @description 全局注册应用配置
 */
Vue.prototype.$config = config
window.$ = window.jQuery = jQuery;
/**
 * 注册指令
 */
importDirective(Vue)

window.VueMainInstance = new Vue({
  el: '#app',
  router,
  i18n,
  store,
  render: h => h(App)
})
