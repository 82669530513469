
import i18n from '@/locale'
export const Main = () => import('@/components/main/main.vue')

/**
 * iview-admin中meta除了原生参数外可配置的参数:
 * meta: {
 *  title: { String|Number|Function }
 *         显示在侧边栏、面包屑和标签栏的文字
 *         使用'{{ 多语言字段 }}'形式结合多语言使用，例子看多语言的路由配置;
 *         可以传入一个回调函数，参数是当前路由对象，例子看动态路由和带参路由
 *  hideInBread: (false) 设为true后此级路由将不会出现在面包屑中
 *  hideInMenu: (false) 设为true后在左侧菜单不会显示该页面选项
 *  noCloseable: (false)  设为true后不能被关闭
 *  notCache: (false) 设为true后页面不会缓存
 *  access: (null) 可访问该页面的权限数组，当前路由设置的权限会影响子路由
 *  icon: (-) 该页面在左侧菜单、面包屑和标签导航处显示的图标，如果是自定义图标，需要在图标名称前加下划线'_'
 *  beforeCloseName: (-) 设置该字段，则在关闭当前tab页时会去'@/router/before-close.js'里寻找该字段名对应的方法，作为关闭前的钩子函数
 * }
 */
const $t = (key) => {
  return i18n.t(key)
}

export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Login - 登录',
      hideInMenu: true
    },
    component: () => import('@/view/login/login.vue')
  },
  {
    path: '/caseQosDetail',
    name: 'caseQosDetail',
    meta: {
      hideInMenu: true,
      title: '案件详情'
    },
    component: () => import('@/view/dy-casectrl/caseDetail.vue')
  },
  {
    path: '/',
    name: '_home',
    redirect: '/home',
    component: Main,
    meta: {
      hideInMenu: false,
      notCache: false
    },
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          hideInMenu: false,
          title: '控制台',
          notCache: false,
          icon: 'iconfont icon-kongzhitai',
          isTopMenu: true
        },
        component: () => import('@/view/dy-nomenu/home/home.vue')
      },
      {
        path: '/inter',
        name: 'inter',
        isHide: true,
        meta: {
          noCloseable: true,
          hideInMenu: true,
          title: '外访记录',
          notCache: false,
          icon: 'iconfont icon-waifangjilu',
          isTopMenu: true
        },
        component: () => import('@/view/dy-nomenu/home/interHome.vue')
      },
      {
        path: '/mycase',
        name: 'mycase',
        isHide: true,
        meta: {
          noCloseable: true,
          hideInMenu: true,
          title: '案件',
          notCache: false,
          icon: 'iconfont icon-wodeanjian',
          isTopMenu: true
        },
        component: () => import('@/view/dy-nomenu/agent/mycase.vue')
      },
      {
        path: '/mediate',
        name: 'mediate',
        isHide: true,
        meta: {
          noCloseable: true,
          hideInMenu: true,
          title: '调解',
          notCache: false,
          icon: 'iconfont icon-tiaojie',
          isTopMenu: true
        },
        component: () => import('@/view/dy-agent-mediate/cases.vue')
      },
      {
        path: '/cooperation',
        name: 'cooperation',
        isHide: true,
        meta: {
          noCloseable: true,
          hideInMenu: true,
          title: $t('coordinate'),
          notCache: false,
          icon: 'iconfont icon-xiecui',
          isTopMenu: true
        },
        component: () => import('@/view/dy-nomenu/agent/cooperation/cooperation.vue')
      },
      {
        path: '/agentFifo',
        name: 'agentFifo',
        isHide: true,
        meta: {
          noCloseable: true,
          hideInMenu: true,
          title: '预测式外呼',
          notCache: false,
          icon: 'iconfont icon-yuceshiwaihu',
          isTopMenu: true
        },
        component: () => import('@/view/dy-nomenu/agent/agentFifo.vue')
      },
      {
        path: '/messageEnable',
        name: 'messageEnable',
        isHide: true,
        meta: {
          noCloseable: true,
          hideInMenu: true,
          title: '短信管理',
          notCache: false,
          icon: 'iconfont icon-duanxinguanli',
          isTopMenu: true
        },
        component: () => import('@/view/dy-nomenu/agent/messageEnable.vue')
      },
      {
        path: '/invalidCase',
        name: 'invalidCase',
        meta: {
          noCloseable: true,
          hideInMenu: true,
          title: '失效案件',
          notCache: true
        },
        component: () => import('@/view/dy-nomenu/agent/invalidCase.vue')
      },
      {
        path: '/closedCase',
        name: 'closedCase',
        meta: {
          noCloseable: true,
          hideInMenu: true,
          title: '已结案案件',
          notCache: true
        },
        component: () => import('@/view/dy-nomenu/agent/closedCase.vue')
      }
    ]
  },
  {
    path: '/inspection',
    name: 'inspection',
    meta: {
      title: '质检管理',
      icon: 'iconfont icon-zhijianguanli-01',
      hideInMenu: true
    },
    component: Main,
    children: [
      {
        path: 'callLog',
        name: 'callLogs',
        meta: {
          title: '通话记录',
          icon: 'iconfont icon-tonghuajilu'
        },
        component: () => import('@/view/dy-inspect/callLog.vue')
      }
    ]
  },
  {
    path: '/argu',
    name: 'argu',
    meta: {
      hideInMenu: true
    },
    component: Main,
    children: [
      {
        path: 'caseDetail/:id',
        name: 'caseDetail',
        meta: {
          title: route => `案子-${route.params.name}`,
          notCache: true,
          beforeCloseName: 'before_close_normal'
        },
        component: () => import('@/view/dy-nomenu/agent/casedetail.vue')
      },
      {
        path: 'robotDetail/:id/:type',
        name: 'robotDetail',
        meta: {
          hideInMenu: true,
          notCache: true,
          title: '外呼详情'
        },
        component: () => import('@/view/dy-assist/robotDetail.vue')
      }, {
        path: 'fifoDetail/:id/:type',
        name: 'fifoDetail',
        meta: {
          hideInMenu: true,
          notCache: true,
          title: '外呼详情'
        },
        component: () => import('@/view/dy-assist/fifoDetail.vue')
      }, {
        path: 'smsDetail/:id',
        name: 'smsDetail',
        meta: {
          hideInMenu: true,
          notCache: true,
          title: '短信详情'
        },
        component: () => import('@/view/dy-assist/smsplan/smsDetail.vue')
      }, {
        path: 'call-log',
        name: 'callLog',
        meta: {
          hideInMenu: true,
          notCache: true,
          title: '短信详情'
        },
        component: () => import('@/view/dy-inspect/callLog.vue')
      }
    ]
  },
  // 灰度
  // {
  //   path: '/phone',
  //   name: 'phone',
  //   meta: {
  //     title: '工作手机',
  //     icon: 'iconfont icon-zhijianguanli-01',
  //     hideInMenu: false
  //   },
  //   component: Main,
  //   children: [
  //     {
  //       path: 'sms',
  //       name: 'sms',
  //       meta: {
  //         title: '短信群发',
  //         icon: 'iconfont icon-tonghuajilu'
  //       },
  //       component: () => import('@/view/dy-assist/smsSend.vue')
  //     },
  //     {
  //       path: 'script',
  //       name: 'script',
  //       meta: {
  //         title: '话术配置',
  //         icon: 'iconfont icon-tonghuajilu'
  //       },
  //       component: () => import('@/view/dy-assist/scriptConfig.vue')
  //     }
  //   ]
  // },

  // {
  //   path: '/reduction',
  //   name: 'reduction',
  //   meta: {
  //     title: '减免分期',
  //     icon: 'iconfont icon-duanxinguanli',
  //   },
  //   component: Main,
  //   children: [
  //     {
  //       path: 'stag',
  //       name: 'reductionStag',
  //       meta: {
  //         title: '减免分期',
  //         icon: 'iconfont icon-duanxinguanli',
  //       },
  //       component: () => import('@/view/dy-refund/reductionStag.vue')
  //     }
  //   ]
  // },
  // {
  //   path: '/report',
  //   name: 'report',
  //   meta: {
  //     title: '报表统计',
  //     icon: 'iconfont icon-duanxinguanli',
  //   },
  //   component: Main,
  //   children: [
  //     {
  //       path: 'performance',
  //       name: 'performance',
  //       meta: {
  //         title: '业绩统计',
  //         notCache: true
  //       },
  //       component: () => import('@/view/dy-report/performanceCount.vue')
  //     },
  //     {
  //       path: 'workCoverage',
  //       name: 'workCoverage',
  //       meta: {
  //         title: '工作覆盖',
  //         notCache: true
  //       },
  //       component: () => import('@/view/dy-report/workCoverage.vue')
  //     },
  //     {
  //       path: 'callStatistics',
  //       name: 'callStatistics',
  //       meta: {
  //         title: '点呼统计',
  //         notCache: true
  //       },
  //       component: () => import('@/view/dy-report/callStatistics.vue')
  //     },
  //     {
  //       path: 'robotStatistics',
  //       name: 'robotStatistics',
  //       meta: {
  //         title: '机器人统计',
  //         notCache: true
  //       },
  //       component: () => import('@/view/dy-report/robotStatistics.vue')
  //     },
  //     {
  //       path: 'preOutsideStatistics',
  //       name: 'preOutsideStatistics',
  //       meta: {
  //         title: '预测式外呼统计',
  //         notCache: true
  //       },
  //       component: () => import('@/view/dy-report/preOutsideStatistics.vue')
  //     },
  //     {
  //       path: 'trialStatistics',
  //       name: 'trialStatistics',
  //       meta: {
  //         title: '提成统计',
  //         notCache: true
  //       },
  //       component: () => import('@/view/dy-report/trialStatistics.vue')
  //     },
  //   ]
  // },
  {
    path: '/401',
    name: 'error_401',
    meta: {
      hideInMenu: true
    },
    component: () => import('@/view/error-page/401.vue')
  },
  {
    path: '/authority',
    name: 'authority',
    meta: {
      hideInMenu: true
    },
    component: () => import('@/view/error-page/authority.vue')
  },
  {
    path: '/500',
    name: 'error_500',
    meta: {
      hideInMenu: true
    },
    component: () => import('@/view/error-page/500.vue')
  },
  {
    path: '*',
    name: 'error_404',
    meta: {
      hideInMenu: true
    },
    component: () => import('@/view/error-page/404.vue')
  }
]
