function at(n) {
  // ToInteger() abstract op
  n = Math.trunc(n) || 0
  // Allow negative indexing from the end
  if (n < 0) n += this.length
  // OOB access is guaranteed to return undefined
  if (n < 0 || n >= this.length) return undefined
  // Otherwise, this is just normal property access
  return this[n]
}
function replaceAll(oldString, newString) {
  return this.replace(new RegExp(oldString, 'gm'), newString)
}
const TypedArray = Reflect.getPrototypeOf(Int8Array)
for (const C of [Array, String, TypedArray]) {
  Object.defineProperty(C.prototype, 'at',
    {
      value: at,
      writable: true,
      enumerable: false,
      configurable: true
    })
}
// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'replaceAll',
  {
    value: replaceAll,
    writable: true,
    enumerable: false,
    configurable: true
  }
)