import { get, post, put, del } from '@/libs/api.request'

export const getDelts = (searchData) => {
  return get('/v1/delt', searchData)
}

export const getCaseDelts = (searchData) => {
  return get('/v1/delt/caseList', searchData)
}

export const getNotCaseDelts = (searchData) => {
  return get('/v1/delt/notCaseList', searchData)
}

export const getCaseUserDelts = (searchData) => {
  return get('/v1/delt/caseUserList', searchData)
}

export const addDelt = (delt) => {
  return post('/v1/delt', delt)
}

export const updateDelt = (delt) => {
  return put('/v1/delt', delt)
}

export const delDelt = ({ id }) => {
  return del(`/v1/delt/${id}`)
}

export const getDeltOperation = (data) => {
  return get('/v1/delt/operation', data)
}

export const setDeltOperation = (data) => {
  return post('/v1/delt/operation', data)
}
