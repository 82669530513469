import { get, post, put, del } from '@/libs/api.request'
// 删除工作手机坐席
export const delAgent = (data) => {
  return del('v1/wp/agent', data)
}
// 获取工作手机坐席
export const getAgent = (data) => {
  return get('v1/wp/agent', data)
}
// 添加工作手机坐席
export const addAgent = (data) => {
  return post('v1/wp/agent', data)
}

// 刷新工作手机坐席状态
export const refreshAgent = (data) => {
  return post('v1/wp/agent/refresh', data, { noSucTip: true })
}
// 通过手机拨打电话
export const callWorkPhone = (data) => {
  return post('v1/wp/call', data, { noSucTip: true })
}
// 获取工作手机未绑定催员列表
export const getWorkPhoneUser = (data) => {
  return get('v1/wp/user', data)
}
// 获取录音地址
export const getWorkPhoneUrl = data => {
  return get('v1/wp/call/url', data)
}

// 编辑坐席权限
export const editWorkPhoneAuth = (data) => {
  return post('/v1/wp/editWorkPhoneAuth', data, { noSucTip: true })
}
// 新增工作手机短信话术
export const addSmsTemplate = (data) => {
  return post('/v1/wp/smsTemplate/add', data, { noSucTip: true })
}
// 查询短信话术列表
export const smsTemplate = data => {
  return get('/v1/wp/smsTemplate', data)
}
// 批量修改微信话术权限
export const batchSmsAuth = (data) => {
  return post('/v1/wp/smsTemplate/batchAuth', data, { noSucTip: true })
}
// 删除微信话术
export const delSmsTemplate = (id) => {
  return post(`/v1/wp/smsTemplate/del/${id}`,  { noSucTip: true })
}
// 编辑微信话术
export const editSmsTemplate = (data) => {
  return post('/v1/wp/smsTemplate/edit', data, { noSucTip: true })
}
// 查看话术权限信息
export const templateAuth = (data) => {
  return post('/v1/wp/templateAuth', data, { noSucTip: true })
}

// 新增微信话术
export const addWechatTemplate = (data) => {
  return post('/v1/wp/wechatTemplate/add', data, { noSucTip: true })
}
// 查询微信话术列表
export const wechatTemplate = data => {
  return get('/v1/wp/wechatTemplate', data)
}
// 批量修改微信话术权限
export const batchWechatAuth = (data) => {
  return post('/v1/wp/wechatTemplate/batchAuth', data, { noSucTip: true })
}
// 删除微信话术
export const delWechatTemplate = (id) => {
  return post(`/v1/wp/wechatTemplate/del/${id}`,  { noSucTip: true })
}
// 编辑微信话术
export const editWechatTemplate = (data) => {
  return post('/v1/wp/wechatTemplate/edit', data, { noSucTip: true })
}

// 分页短信群发计划查询
export const smsPlan = data => {
  return post('/v1/wp/smsPlan', data, { noSucTip: true })
}
// 取消计划
export const smsCancel = planId => {
  return post(`/v1/wp/smsPlan/cancel/${planId}`,null, { noSucTip: true })
}
// 对应计划详情
export const smsPlanDetail = data => {
  return post('/v1/wp/smsPlan/detail', data, { noSucTip: true })
}


// 添加短信群发计划
export const addSmsPlan = (data) => {
  return post('/v1/wp/smsPlan/add', data)
}
// 通过工作手机发送短信
export const sendSms = (data) => {
  return post('/v1/wp/sendSms', data, { noSucTip: true })
}

// 查询所有历史催员
export const getHistoryUsers = caseId => {
  return get(`/v1/cases/log/historyUsers/${caseId}`)
}
// 查询坐席绑定手机号
export const getBindPhone = userId => {
  return get(`/v1/wp/bindPhone/${userId}`)
}
// 分页查询工作手机短信对话记录
export const smsListByPage = (data) => {
  return post('/v1/wp/smsListByPage', data, { noSucTip: true })
}

// 查询坐席对应微信信息
export const bindWx = userId => {
  return get(`/v1/wp/bindWx/${userId}`)
}

// 分页查询工作手机微信对话记录
export const wechatListByPage = (data) => {
  return post('/v1/wp/wechatListByPage', data,{ noSucTip: true })
}

// 不分页查询短信话术列表
export const smsTemplateList = data => {
  return get('/v1/wp/smsTemplateList', data)
}
// 不分页查询微信话术列表
export const wechatTemplateList = data => {
  return get('/v1/wp/wechatTemplateList', data)
}