import { get, post, put, postForm, del } from '@/libs/api.request'
import {
  formatDate,
} from "@/libs/util";

export const getAgentReport = (data) => {
  return get('/v1/statistics/repayment/list', data)
}

export const exportDownload = (data) => {
  return post('/v1/statistics/repayment/download', data)
}

export const downloadTaskList = (data) => {
  return get('/v1/statistics/tasklist', data)
}

export const getDailyReport = (data) => {
  return get('/v1/statistics/operation/list', data)
}

export const dailyDownload = (data) => {
  return post('/v1/statistics/operation/download', data)
}

export const repaymentListByDunnerIds = (data) => {
  return get('/v1/statistics/repayment/repaymentListByDunnerIds', data)
}

let sendData=null
const dataProcess=(data)=>{
  sendData = Object.assign({}, data)
  const rankingDate=sendData.rankingDate.split(',')
  sendData.startTime=formatDate(Number(rankingDate[0]), "yyyy-MM-dd") + " 00:00:00"
  sendData.endTime=formatDate(Number(rankingDate[1]), "yyyy-MM-dd") + " 23:59:59"
  if(sendData.userIds){
    sendData.userIds=sendData.userIds.split(',')
    sendData.userIds.forEach((item,index) =>{
      sendData.userIds[index] = parseInt(sendData.userIds[index])
    })
  }
  if(sendData.teamIds){
    sendData.teamIds=sendData.teamIds.split(',')
    sendData.teamIds.forEach((item,index) =>{
      sendData.teamIds[index] = parseInt(sendData.teamIds[index])
    })
  }
  if(sendData.depIds){
    sendData.depIds=sendData.depIds.split(',')
    sendData.depIds.forEach((item,index) =>{
      sendData.depIds[index] = parseInt(sendData.depIds[index])
    })
  }
  if(sendData.orgDeltIds){
    sendData.orgDeltIds=sendData.orgDeltIds.split(',')
    sendData.orgDeltIds.forEach((item,index) =>{
      sendData.orgDeltIds[index] = parseInt(sendData.orgDeltIds[index])
    })
  }
  if(sendData.batchIds){
    sendData.batchIds=sendData.batchIds.split(',')
    sendData.batchIds.forEach((item,index) =>{
      sendData.batchIds[index] = parseInt(sendData.batchIds[index])
    })
  }
  if(sendData.productIds){
    sendData.productIds=sendData.productIds.split(',')
    sendData.productIds.forEach((item,index) =>{
      sendData.productIds[index] = parseInt(sendData.productIds[index])
    })
  }
  delete sendData.type
  delete sendData.rankingDate
}
// 业绩统计
export const getPerformance = (data) => {
  dataProcess(data)
  return post(`/v1/bi/statistics/performance/${data.type}`, sendData,{ noSucTip: true })
}

export const performanceExport = (data) => {
  dataProcess(data)
  return post(`/v1/bi/statistics/performance/${data.type}/export`, sendData,{ noSucTip: true })
}

// 工作覆盖
export const getWorkCoverage = (data) => {
  dataProcess(data)
  return post(`/v1/bi/statistics/work/${data.type}`, sendData,{ noSucTip: true })
}

export const workCoverageExport = (data) => {
  dataProcess(data)
  return post(`/v1/bi/statistics/work/${data.type}/export`, sendData,{ noSucTip: true })
}

// 点呼统计
export const getCallStatistics = (data) => {
  dataProcess(data)
  return post(`/v1/bi/statistics/call/${data.type}`, sendData,{ noSucTip: true })
}

export const callStatisticsExport = (data) => {
  // dataProcess(data)
  dataProcess(data)
  return post(`/v1/bi/statistics/call/${data.type}/export`, sendData,{ noSucTip: true })
}

// 机器人统计
export const getRobotStatistics = (data) => {
  dataProcess(data)
  return post(`/v1/bi/statistics/robot/${data.type}`, sendData,{ noSucTip: true })
}

export const robotStatisticsExport = (data) => {
  dataProcess(data)
  return post(`/v1/bi/statistics/robot/${data.type}/export`, sendData,{ noSucTip: true })
}

// 预测式外呼统计
export const getPreOutsideCall = (data) => {
  dataProcess(data)
  return post(`/v1/bi/statistics/fifo/${data.type}`, sendData,{ noSucTip: true })
}

export const preOutsideCallExport = (data) => {
  dataProcess(data)
  return post(`/v1/bi/statistics/fifo/${data.type}/export`, sendData,{ noSucTip: true })
}

// 提成统计
export const getTrialStatistics = (data) => {
  if(data.userIds){
    data.userIds=data.userIds.split(',')
  }
  if(data.teamIds){
    data.teamIds=data.teamIds.split(',')
  }
  data.startTime=formatDate(data.startTime, "yyyy-MM-dd") + " 00:00:00"
  return post('/v1/bi/statistics/commission', data,{ noSucTip: true })
}

export const trialStatisticsExport = (data) => {
  return post('/v1/bi/statistics/commission/export', data,{ noSucTip: true })
}
/**
 * 佣金比例
 */
// 删除佣金比例 v1/org/commission
export const delCommission = data => {
  return del('/v1/org/commission', data)
}
// 获取佣金比例列表
export const getCommissionList = data => {
  return get('/v1/org/commission', data)
}
// 添加佣金比例列表
export const addCommission = data => {
  return post('/v1/org/commission', data)
}
// 修改佣金比例
export const updateCommission = (data) => {
  return put(`v1/org/commission/${data.id}`, data)
}
// 获取佣金详情
export const getDetail = id => {
  return get('v1/org/commission/' + id)
}

// 调解数据报表
export const getMediationList = (data) => {
  return post(`/v1/mediationStatistics/list/${data.type}`, data, { noSucTip: true })
}

// 调解导出
export const exportMediation = (data) => {
  return post(`/v1/mediationStatistics/export/${data.type}`, data)
}

// 短信消费
export const getSmsList = (data) => {
  const statsTime = formatDate(data.statsTime, 'yyyy-MM-dd')
  data.statsTime = []
  for (const item of [statsTime + ' 00:00:00', statsTime + ' 23:59:59']) {
    data.statsTime.push(new Date(item).getTime())
  }
  data.statsTime = data.statsTime.join(',')
  return post('/v1/messageStatistics/list', data, { noSucTip: true })
}

// 导出短信消费
export const exportSms = (data) => {
  return post('/v1/messageStatistics/download', data)
}

// 台账统计
export const caseStatusStatistics = (data) => {
  return post('/v1/caseStatusStatistics/list', data, { noSucTip: true })
}
// 台账统计导出
export const exportCaseStatusStatistics = (data) => {
  return post('/v1/caseStatusStatistics/export', data, { noSucTip: true })
}
// 编辑销售名称
export const editSalesPerson = (data) => {
  return put(`/v1/caseStatusStatistics/editSalesPerson`,data)
}
