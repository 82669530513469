import Cookies from 'js-cookie'
// cookie保存的天数
import config from '@/config'
import { forEach, hasOneOf, objEqual, deepClone } from '@/libs/tools'
// import Main from '@/components/main/main.vue'
// import parentView from '@/components/parent-view'

import mainRouters from '@/router/routers'
const routeAllPathToCompMap = import.meta.glob(`../view/**/*.vue`)
export const TOKEN_KEY = 'token'
export const ORGID_KEY = 'orgId'
export const DEP_KEY = 'depInfo'
export const NAME = 'name'
export const ISPHONE = 'isPhone'
export const UAT_KEY = 'isUat'

const padLeftZero = (str) => {
  return ('00' + str).substr(str.length)
}

//
export const getImgInfo = (file) => {
  return new Promise((resolve, reject) => {
    var img = new Image()

    img.src = window.URL.createObjectURL(file)

    img.onload = () => {
      resolve({
        w: img.width,
        h: img.height,
        pic: img.src
      })
    }
    // 损坏的图片不会触发onload导致程序卡住无返回信息
    img.onerror = () => {
      resolve({
        w: img.width,
        h: img.height,
        pic: img.src
      })
    }
  })
}

export const dataURItoBlob = (dataURI) => {
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) { byteString = atob(dataURI.split(',')[1]) } else { byteString = unescape(dataURI.split(',')[1]) }
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ia], { type: mimeString })
}

// 字符串部分用*隐藏
export const hideStringByStar = (str, pre, end) => {
  return (window.VueMainInstance.$store.getters.userExtend.enableHiddenPhone) ? str.substr(0, pre) + '****' + str.substr(end) : str
}

// 数组对象根据属性去重
export const unique = (arr, field) => {
  const map = {}
  const res = []
  for (var i = 0; i < arr.length; i++) {
    if (!map[arr[i][field]]) {
      map[arr[i][field]] = 1
      res.push(arr[i])
    }
  }
  return res
}

const getLocalstorageKey = (key) => {
  return window.isAdminSys ? key + 'Adm' : key
}

export function formatDate(timestamp, fmt = 'yyyy-MM-dd hh:mm') {
  if (!timestamp) return ''
  const date = new Date(timestamp)

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    const str = o[k] + ''
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
    }
  }
  return fmt
}

export const formatNum = (num) => {
  const str = String(num)
  const len = str.length
  if (len <= 3) return num
  const r = len % 3
  return r > 0 ? str.slice(0, r) + ',' + str.slice(r, len).match(/\d{3}/g).join(',') : str.slice(r, len).match(/\d{3}/g).join(',')
}

export const moneyli2yuan = (intnum) => {
  // 厘截取两位小数
  const num = Math.round(Number(intnum) / 10 / 100 * 100) / 100

  return formatMoney(num)
}

export const formatMoney = (num) => {
  if (num && num != null) {
    num = String(num)
    const left = num.split('.')[0]; let right = num.split('.')[1]
    right = right ? (right.length >= 2 ? '.' + right.substr(0, 2) : '.' + right + '0') : '.00'
    const temp = left.split('').reverse().join('').match(/(\d{1,3})/g)
    return (Number(num) < 0 ? '-' : '') + temp.join(',').split('').reverse().join('') + right
  } else if (num === 0) { // 注意===在这里的使用，如果传入的num为0,if中会将其判定为boolean类型，故而要另外做===判断
    return '0.00'
  } else {
    return ''
  }
}

export const moneyuan2li = (floatNum) => {
  const reg = /^[0-9]+.?[0-9]*$/ // 验证字符串是否是数字
  if (!(reg.test(floatNum))) {
    // 为字符串 则判断是否存在','
    if ((floatNum + '').replace(/^\s\s*/, '').replace(/\s\s*$/, '') == '') {
      return 0
    }
    if (floatNum == undefined) {
      return 0
    }
    if ((floatNum || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')) {
      floatNum = floatNum.replace(/,/gi, '')
      return Number(floatNum) * 1000
    }
  }
  return Math.round(Number(floatNum) * 1000)
}

export const formatSecond = (intnum) => {
  if (!intnum) { return 0 + '秒' }

  let out = 0
  intnum = Number(intnum)

  if (intnum < 60) {
    out = parseInt(intnum) + '秒'
  } else if (intnum < 3600) {
    out = parseInt(intnum / 60) + '分' + (intnum % 60).toFixed(0) + '秒'
  } else {
    out = parseInt(intnum / 3600) + '时' + parseInt(intnum % 3600 / 60) + '分' + (intnum % 3600 % 60).toFixed(0) + '秒'
  }
  return out
}

/**
 *
 * @param {*} mapkey anmi 中map的key 或者 formatDate（格式化时间）或者 array内容为 [{ name: 'xxx', value: xx },]
 * @param {*} value anmi 中map 字典的value
 * @param {*} _fmt formatDate 时的格式，可以不传
 */
export const getDictionaryName = (mapkey, value, _fmt) => {
  switch (mapkey) {
    case 'formatDate':
      return value ? formatDate(value, _fmt) : ''
    case 'money':
      return value ? moneyli2yuan(value) : value == 0 ? '0.00' : null
    case 'time':
      return value ? formatSecond(value) : '0秒'
    case 'depName': case 'teamName':
      return value || '-'
    case 'formatDuration':
      return value ? formatDuration(value, 'zh', false) : '0秒'
    case 'formatMoney':
      return value ? formatMoney(value) : value === 0 ? '0.00' : null
  }
  const dictionary = mapkey instanceof Array ? mapkey : window.VueMainInstance.$store.getters.dictionary[mapkey]
  if (!dictionary) { return '' }

  if (typeof (value) === 'string' && value.split(',').length > 0) {
    const values = value.split(',')
    const arr = []

    for (const item in dictionary) {
      for (const key in values) {
        if (String(dictionary[item].value) === String(values[key]) || String(dictionary[item].id) === String(values[key])) {
          arr.push(dictionary[item].name)
        }
      }
    }
    return arr.join(',')
  } else {
    for (const item of dictionary) {
      const iValue = (item.value || [0, false].includes(item.value)) ? item.value : ((item.id || [0, false].includes(item.id)) ? item.id : '')
      if (iValue === value) { return item.name }
    }
  }

  return ''
}
/**
 *
 * @param {*} array anmi 中map的key 或者 formatDate（格式化时间）或者 array内容为 [{ name: 'xxx', value: xx },]
 * @param {*} ele 用以比对的key
 * @param {*} eleVal 与ele对应的value
 */
export const findTargetEle = (array, ele, eleVal) => {
  const dictionary = array instanceof Array ? array : window.VueMainInstance.$store.getters.dictionary[array]
  if (!Array.isArray(dictionary)) return

  for (const key in dictionary) {
    if (dictionary[key][ele] === eleVal) {
      return dictionary[key]
    }
  }
}

export const setOrgApikey = (orgApikey) => {
  Cookies.set('orgApikey', orgApikey, { expires: config.cookieExpires || 1 })
}
export const getOrgApikey = () => {
  const orgApikey = Cookies.get('orgApikey')
  if (orgApikey) return orgApikey
  else return false
}
export const getName = () => {
  const name = Cookies.get('name')
  if (name) return name
  else return false
}

export const setToken = (token) => {
  Cookies.set(TOKEN_KEY, token, { expires: config.cookieExpires || 1 })
}

export const getToken = () => {
  const token = Cookies.get(TOKEN_KEY)
  if (token) return token
  else return false
}

export const setUat = (isUat) => {
  Cookies.set(UAT_KEY, isUat, {
    expires: config.cookieExpires || 1
  })
}

export const removeUat = () => {
  Cookies.remove(UAT_KEY)
}
export const setCookieUat = (isuat) => {
  const newUat = !!((isuat === 'true' || isuat === true))
  const oldUat = Cookies.get(UAT_KEY)
  if (newUat && !oldUat) {
    setUat(true)
  } else if (!newUat) {
    removeUat()
  }
}

export const setOrgId = (orgId) => {
  Cookies.set(ORGID_KEY, orgId, { expires: config.cookieExpires || 1 })
}

export const setDepInfo = (dep) => {
  Cookies.set(DEP_KEY, dep, { expires: config.cookieExpires || 1 })
}

export const setName = (name) => {
  Cookies.set(NAME, name, { expires: config.cookieExpires || 1 })
}

export const getOrgId = () => {
  const orgId = Cookies.get(ORGID_KEY)
  if (orgId) return orgId
  else return false
}

export const hasChild = (item) => {
  return item.children && item.children.length !== 0
}

const showThisMenuEle = (item, access) => {
  if (item.meta && item.meta.access && item.meta.access.length) {
    if (hasOneOf(item.meta.access, access)) return true
    else return false
  } else return true
}
/**
 * @param {Array} list 通过路由列表得到菜单列表
 * @returns {Array}
 */
export const getMenuByRouter = (list, access) => {
  const res = []
  forEach(list, item => {
    if (!item.meta || (item.meta && !item.meta.hideInMenu)) {
      const obj = {
        icon: (item.meta && item.meta.icon) || '',
        name: item.name,
        meta: item.meta,
        id: item.id
      }
      if ((hasChild(item) || (item.meta && item.meta.showAlways)) && showThisMenuEle(item, access)) {
        obj.children = getMenuByRouter(item.children, access)
      }
      if (item.meta && item.meta.href) obj.href = item.meta.href
      if (showThisMenuEle(item, access)) res.push(obj)
    }
  })
  return res
}

/**
 * @param {Array} routeMetched 当前路由metched
 * @returns {Array}
 */
export const getBreadCrumbList = (route, homeRoute) => {
  const homeItem = { ...homeRoute, icon: homeRoute.meta.icon }
  const routeMetched = route.matched
  if (routeMetched.some(item => item.name === homeRoute.name)) return [homeItem]
  let res = routeMetched.filter(item => {
    return item.meta === undefined || !item.meta.hideInBread
  }).map(item => {
    const meta = { ...item.meta }
    if (meta.title && typeof meta.title === 'function') meta.title = meta.title(route)
    const obj = {
      icon: (item.meta && item.meta.icon) || '',
      name: item.name,
      meta: meta
    }
    return obj
  })
  res = res.filter(item => {
    return !item.meta.hideInMenu
  })
  return [{ ...homeItem, to: homeRoute.path }, ...res]
}

export const getRouteTitleHandled = (route) => {
  const router = { ...route }
  const meta = { ...route.meta }
  let title = ''
  if (meta.title) {
    if (typeof meta.title === 'function') title = meta.title(router)
    else title = meta.title
  }
  meta.title = title
  router.meta = meta
  return router
}

/**
 * @description 本地存储和获取标签导航列表
 */
export const setTagNavListInLocalstorage = list => {
  localStorage[getLocalstorageKey('tagNaveList')] = list ? JSON.stringify(list) : ''
}
/**
 * @returns {Array} 其中的每个元素只包含路由原信息中的name, path, meta三项
 */
export const getTagNavListFromLocalstorage = () => {
  const list = localStorage[getLocalstorageKey('tagNaveList')]
  return list ? JSON.parse(list) : []
}

/**
 * @description 本地存储用户信息
 */
export const setUserInLocalstorage = info => {
  localStorage[getLocalstorageKey('userInfo')] = info ? JSON.stringify(info) : ''
}
/**
 * @returns {object} 获取本地用户信息
 */
export const getUserInLocalstorage = () => {
  const info = localStorage[getLocalstorageKey('userInfo')]
  return info ? JSON.parse(info) : {}
}
/**
 * @description 本地存储验证码Uuid
 */
export const getImageUuid = () => {
  const imageUuid = localStorage.getItem('imageUuid')
  return imageUuid || ''
}
/**
     * 递归数组返回想要的值，返回所有匹配到的节点组成的数组, 适用于四五级 tab 按钮 相关的权限
     * @param {Array}           list        要递归的数组
     * @param {String}          childKey    子集字段
     * @param {String}          key         要匹配的字段（===判断）
     * @param {String/Number}   val         要匹配字段所对应的值（===判断）
     * */
export const findTarget = (list, childKey, key, val, result = []) => {
  if (result.length > 0) return result
  list.forEach(item => {
    if (item[key] === val) {
      result.push(item)
    }
    if (item[childKey] && item[childKey].length > 0) {
      findTarget(item[childKey], childKey, key, val, result)
    }
  })
  return result
}

export const findIndexTarget = (list, childKey, key, val, result = []) => {
  list.forEach(item => {
    if (item[key].indexOf(val) > -1) {
      result.push(item)
    }
    if (item[childKey] && item[childKey].length > 0) {
      findIndexTarget(item[childKey], childKey, key, val, result)
    }
  })
  return result
}

export const deleteTarget = (list, childKey, key, val) => {
  for (let i = list.length - 1; i > 0; i--) {
    if (list[i][key] === val) {
      list.splice(i, 1)
    } else {
      if (list[i][childKey] && list[i][childKey].length > 0) {
        deleteTarget(list[i][childKey], childKey, key, val)
      }
    }
  }
}
/**
     * 递归数组返回tree的所有子节点
     * @param {Array}           list        要递归的数组
     * @param {Array}           result      存储数据的数组
     * */
export const findChild = (list, result = []) => {
  list.forEach(item => {
    if (item.children) {
      findChild(item.children, result)
    } else {
      result.push(item)
    }
  })
  return result
}
/**
 * @description 本地存储是否需要验证码登录
 */
export const setLoginInLocalstorage = info => {
  localStorage[getLocalstorageKey('loginInfo')] = info ? JSON.stringify(info) : ''
}
/**
 * @returns {object} 获取本地是否需要验证码登录信息
 */
export const getLoginInLocalstorage = () => {
  const info = localStorage[getLocalstorageKey('loginInfo')]
  return info ? JSON.parse(info) : {}
}

/**
 * @returns {object} 本地录音命名
 */
export const setRecordName = (value) => {
  localStorage.setItem('recordName', value)
}

export const setLocal = (key, value) => {
  localStorage.setItem(key, value)
}

export const getRecordName = () => {
  return localStorage.getItem('recordName') ? localStorage.getItem('recordName') : null
}

/**
 * 生成router对象格式json
 * TODO remobe mock
 */
export const buildRouter = (routers = []) => {
  const newRouters = []; let itemPath
  const oldRouters = mainRouters
  const router = deepClone(routers)

  router.forEach((item) => {
    itemPath = item.path.replace(/\//g, '')
    if (item.children) {
      // 第一层数据
      item.component = () => import('@/components/main/main.vue')
      item.children.map((c, index) => {
      // 如果c下面还有children 且为二级菜单
        if (c.children) {
          c.component = () => import('@/components/parent-view/parent-view.vue')
          c['meta'] = {
            title: c.name,
            icon: (item.children.length === 1 && c.children.length > 0) ? item.children[0].icon : null,
            href: c.type === '5' ? c.fileName : null,
            level: 2
          }

          c['name'] = itemPath + '_' + item.children[index].path.replace(/\//g, '')
          // 如果一级二级三级菜单都只有一个children  parentView下面的三级菜单显示会有问题
          if (item.children.length === 1 && c.children.length === 1 && c.children[0].type === '2') {
            const cpoyArray = c.children.concat()
            // c.component = (resolve) => require([`@/view/${cpoyArray[0].fileName}`], resolve)
            c.component = routeAllPathToCompMap[`../view/${cpoyArray[0].fileName.replace('.vue', '')}.vue`]
            // console.log(`../view/${cpoyArray[0].fileName.replace('.vue', '')}.vue`, 111)
            c['name'] = itemPath + '_' + cpoyArray[0].path.replace(/\//g, '')
            delete c.children
          } else {
            c.children.map((d, key) => {
              if (d.type === '2') {
              // 当前为三级菜单
                // d.component = (resolve) => require([`@/view/${d.fileName}`], resolve)
                d.component = routeAllPathToCompMap[`../view/${d.fileName.replace('.vue', '')}.vue`]

                d['meta'] = {
                  title: c.children[key].name,
                  // icon: c.children[key].icon,
                  href: c.children[key].type === '5' ? c.children[key].fileName : null,
                  level: 3
                }
                d['name'] = itemPath + '_' + c.children[key].path.replace(/\//g, '')
                if (d.children) {
                  delete d.children
                }
              } else {
              // 没有三级菜单直接删除 这里因为二级菜单后面直接是4级Tab所有把父节点的component重新设置
                // c.component = (resolve) => require([`@/view/${c.fileName}`], resolve)
                c.component = routeAllPathToCompMap[`../view/${c.fileName.replace('.vue', '')}.vue`]
                // console.log(`../view/${c.fileName.replace('.vue', '')}.vue`, 333)

                delete c.children
              }
              return d
            })
          }
        } else {
          // 没有子children，单独为二级菜单
          // c.component = (resolve) => require([`@/view/${c.fileName}`], resolve)
          c.component = routeAllPathToCompMap[`../view/${c.fileName.replace('.vue', '')}.vue`]
          // console.log(`../view/${c.fileName.replace('.vue', '')}.vue`, 444)

          c['path'] = item.children[index].path
          c['icon'] = item.children[index].icon
          c['meta'] = {
            title: item.children[index].name,
            icon: item.children.length === 1 ? item.children[0].icon : null,
            href: item.children[index].type === '5' ? item.children[index].fileName : null,
            level: 2
          }
          c['name'] = itemPath + '_' + item.children[index].path.replace(/\//g, '')
        }
        return c
      })
    } else {
      // item.component = (resolve) => require([`@/view/${item.file}`], resolve)
      item.component = routeAllPathToCompMap[`../view/${item.fileName.replace('.vue', '')}.vue`]
    }
    // console.log(`../view/${item.fileName.replace('.vue', '')}.vue`)
    item['meta'] = {
      title: item.name,
      icon: item.icon,
      href: item.type === '5' ? item.fileName : null
    }
    item['name'] = itemPath
    newRouters.push(item)

    let isAddMenu = -1
    oldRouters.map((i, inNum) => {
      if (i.id === item.id) {
        isAddMenu = inNum
      }
    })

    if (isAddMenu === -1) {
      oldRouters.push(item)
    }
  })

  return { oldRouters, newRouters }
}


/**
 * 显示国际化菜单的title
 * @param {*} item
 * @param {*} vm
 */
export const showTitle = (item, vm) => {
  let title

  if (vm.$config.useI18n && !item.query) {
    title = vm.$t('m_' + item.name) || item.meta.title
  } else {
    title = item.meta.title
  }

  return title || ''
}

/**
 * @param {Array} routers 路由列表数组
 * @description 用于找到路由列表中name的对象
 */
export const getRouteByName = (routers, homeName = 'home') => {
  let i = -1
  const len = routers.length

  let homeRoute = {}
  while (++i < len) {
    const item = routers[i]
    if (item.children && item.children.length) {
      const res = getRouteByName(item.children, homeName)
      if (res.name) return res
    } else {
      if (item.name === homeName) homeRoute = item
    }
  }
  return homeRoute
}

/**
 * @param {*} list 现有标签导航列表
 * @param {*} newRoute 新添加的路由原信息对象
 * @description 如果该newRoute已经存在则不再添加
 */
export const getNewTagList = (list, newRoute) => {
  const { name, path, meta } = newRoute
  const newList = [...list]
  if (newList.findIndex(item => item.name === name) >= 0) return newList
  else newList.push({ name, path, meta })
  return newList
}

/**
 * @param {*} access 用户权限数组，如 ['super_admin', 'admin']
 * @param {*} route 路由列表
 */
const hasAccess = (access, route) => {
  if (route.meta && route.meta.access) return hasOneOf(access, route.meta.access)
  else return true
}

/**
 * 权鉴
 * @param {*} name 即将跳转的路由name
 * @param {*} access 用户权限数组
 * @param {*} routes 路由列表
 * @description 用户是否可跳转到该页
 */
export const canTurnTo = (name, access, routes) => {
  const routePermissionJudge = (list) => {
    return list.some(item => {
      if (item.children && item.children.length) {
        return routePermissionJudge(item.children)
      } else if (item.name === name) {
        return hasAccess(access, item)
      }
    })
  }

  return routePermissionJudge(routes)
}

/**
 * @param {String} url
 * @description 从URL中解析参数
 */
export const getParams = url => {
  let keyValueArr = url.split('?')
  const paramObj = {}

  if (keyValueArr.length === 1) { return paramObj }

  keyValueArr = url.split('?')[1].split('&')

  keyValueArr.forEach(item => {
    const keyValue = item.split('=')
    paramObj[keyValue[0]] = keyValue[1]
  })
  return paramObj
}
export const judgeAgent = () => {
  return window.location.href.indexOf('w-regress') !== -1
}

/**
 * @param {Array} list 标签列表
 * @param {String} name 当前关闭的标签的name
 */
export const getNextRoute = (list, route) => {
  let res = {}
  if (list.length === 2) {
    res = getRouteByName(list)
  } else {
    const index = list.findIndex(item => routeEqual(item, route))
    if (index === list.length - 1) res = list[list.length - 2]
    else res = list[index + 1]
  }
  return res
}

/**
 * @param {Number} times 回调函数需要执行的次数
 * @param {Function} callback 回调函数
 */
export const doCustomTimes = (times, callback) => {
  let i = -1
  while (++i < times) {
    callback(i)
  }
}

/**
 * @param {Object} file 从上传组件得到的文件对象
 * @returns {Promise} resolve参数是解析后的二维数组
 * @description 从Csv文件中解析出表格，解析成二维数组
 */
export const getArrayFromFile = (file) => {
  const nameSplit = file.name.split('.')
  const format = nameSplit[nameSplit.length - 1]
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(file) // 以文本格式读取
    let arr = []
    reader.onload = function(evt) {
      const data = evt.target.result // 读到的数据
      const pasteData = data.trim()
      arr = pasteData.split((/[\n\u0085\u2028\u2029]|\r\n?/g)).map(row => {
        return row.split('\t')
      }).map(item => {
        return item[0].split(',')
      })
      if (format === 'csv') resolve(arr)
      else reject(new Error('[Format Error]:你上传的不是Csv文件'))
    }
  })
}

/**
 * @param {Array} array 表格数据二维数组
 * @returns {Object} { columns, tableData }
 * @description 从二维数组中获取表头和表格数据，将第一行作为表头，用于在iView的表格中展示数据
 */
export const getTableDataFromArray = (array) => {
  let columns = []
  let tableData = []
  if (array.length > 1) {
    const titles = array.shift()
    columns = titles.map(item => {
      return {
        title: item,
        key: item
      }
    })
    tableData = array.map(item => {
      const res = {}
      item.forEach((col, i) => {
        res[titles[i]] = col
      })
      return res
    })
  }
  return {
    columns,
    tableData
  }
}

export const findNodeUpper = (ele, tag) => {
  if (ele.parentNode) {
    if (ele.parentNode.tagName === tag.toUpperCase()) {
      return ele.parentNode
    } else {
      return findNodeUpper(ele.parentNode, tag)
    }
  }
}

export const findNodeUpperByClasses = (ele, classes) => {
  const parentNode = ele.parentNode
  if (parentNode) {
    const classList = parentNode.classList
    if (classList && classes.every(className => classList.contains(className))) {
      return parentNode
    } else {
      return findNodeUpperByClasses(parentNode, classes)
    }
  }
}

export const findNodeDownward = (ele, tag) => {
  const tagName = tag.toUpperCase()
  if (ele.childNodes.length) {
    let i = -1
    const len = ele.childNodes.length
    while (++i < len) {
      const child = ele.childNodes[i]
      if (child.tagName === tagName) return child
      else return findNodeDownward(child, tag)
    }
  }
}

export const showByAccess = (access, canViewAccess) => {
  return hasOneOf(canViewAccess, access)
}

/**
 * @description 根据name/params/query判断两个路由对象是否相等
 * @param {*} route1 路由对象
 * @param {*} route2 路由对象
 */
export const routeEqual = (route1, route2) => {
  const params1 = route1.params || {}
  const params2 = route2.params || {}
  const query1 = route1.query || {}
  const query2 = route2.query || {}

  // id 不能使用 ===
  // eslint-disable-next-line
  let isParamsEqual = objEqual(params1, params2) || (params1 && params2 && params1.id == params2.id)

  return (route1.name === route2.name) && isParamsEqual && objEqual(query1, query2)
}

/**
 * 判断打开的标签列表里是否已存在这个新添加的路由对象
 */
export const routeHasExist = (tagNavList, routeItem) => {
  const len = tagNavList.length
  let res = false
  doCustomTimes(len, (index) => {
    if (routeEqual(tagNavList[index], routeItem)) res = true
  })
  return res
}

export const localSave = (key, value) => {
  localStorage.setItem(key, value)
}

export const localRead = (key) => {
  return localStorage.getItem(key) || ''
}

export function timeDifference(startTime, endTime) {
  const start = {
    year: Number(startTime.split('-')[0]),
    month: Number(startTime.split('-')[1]),
    day: Number(startTime.split('-')[2])
  }
  const end = {
    year: Number(endTime.split('-')[0]),
    month: Number(endTime.split('-')[1]),
    day: Number(endTime.split('-')[2])
  }

  let temp = start.year + '-' + (start.month == 12 ? 1 : (start.month + 1)) + '-' + start.day
  temp = {
    year: Number(temp.split('-')[0]),
    month: Number(temp.split('-')[1]),
    day: Number(temp.split('-')[2])
  }

  if (end.year - start.year > 1) {
    return true
  } else if (end.year - start.year > 0 && (start.month - end.month < 11 || start.day - end.day < -1)) {
    return true
  } else if (end.year == start.year && end.month == start.month) {
    return false
  } else if (temp.month < end.month) {
    return true
  } else if ((temp.month == end.month) && (temp.day <= end.day)) {
    return true
  } else {
    return false
  }
}

export const timeover6 = (startTimestamp, endTimestamp) => {
  // 根据时间戳获取年月日等信息
  const startYear = new Date(startTimestamp).getFullYear()
  const startMonth = new Date(startTimestamp).getMonth() + 1
  const startDate = new Date(startTimestamp).getDate()
  const endYear = new Date(endTimestamp).getFullYear()
  const endMonth = new Date(endTimestamp).getMonth() + 1
  const endDate = new Date(endTimestamp).getDate()

  let ifOver = false

  if (startYear === endYear) { // 同年
    if (startMonth + 6 <= endMonth) { // 月间隔不超过6个月
      if (startMonth + 6 === endMonth) {
        if (startDate < endDate) {
          ifOver = true
        }
      } else {
        ifOver = true
      }
    }
  } else { // 不同年份
    if (startYear + 1 === endYear) {
      if (startMonth < 7) {
        ifOver = true
      } else {
        if (endMonth + 6 > startMonth) {
          ifOver = true
        } else if (endMonth + 6 === startMonth) {
          if (startDate < endDate) {
            ifOver = true
          }
        }
      }
    } else { // 超过一年
      ifOver = true
    }
  }

  return ifOver
}

/**
 * 屏蔽规则
 * @param {String} value
 * @param {Number} scope
 *  0 开头
 *  1 中间
 *  -1 末尾
 *  2 全部
 * @param {Number} count 位数
 * @param {Number} type 类型
 *  0 隐藏
 *  1 显示
 * @param {Number} start 从第几
 * @param {Number} end 到第几
 */
export const desenRules = (value, type, scope, count, start, end) => {
  if (!value) return null

  value = String(value)
  if (type === 0) { // 隐藏
    const c = value.length < count ? value.length : count
    if (scope === 0) { // 开头
      return '*'.repeat(c) + value.substr(count)
    } else if (scope === 1) { // 中间
      const len = value.length
      return value.substr(0, Math.floor(len / 2) - Math.floor(count / 2)) + '*'.repeat(c) + value.substr(Math.floor(len / 2) - Math.floor(count / 2) + count)
    } else if (scope === -1) { // 末尾
      const end = value.length - count > 0 ? value.length - count : 0
      return value.substr(0, end) + '*'.repeat(c)
    } else if (scope === 2) { // 全部
      return '*'.repeat(value.length)
    } else if (scope === 3) { // 区间
      const startLength = start > value.length ? value.length + 1 : start
      const repeatCount = end > value.length ? value.length - startLength : end - startLength
      return value.substr(0, startLength - 1) + '*'.repeat(repeatCount + 1) + value.substr(end)
    } else {
      return value
    }
  } else if (type === 1) { // 显示
    const c = value.length - count > 0 ? value.length - count : 0 // 显示位数
    if (scope === 0) { // 开头
      return value.substr(0, count) + '*'.repeat(c)
    } else if (scope === 1) { // 中间
      const starts = Math.floor(c / 2)
      const ends = c - starts

      return '*'.repeat(starts) + value.substr(starts, count) + '*'.repeat(ends)
    } else if (scope === -1) { // 末尾
      const ends = value.length - count > 0 ? value.length - count : 0
      return '*'.repeat(c) + value.substr(ends)
    } else if (scope === 3) {
      const len = end - start
      const startLen = start - 1
      const endLen = value.length - len - startLen - 1
      return '*'.repeat(startLen) + value.substr(start - 1, len + 1) + '*'.repeat(endLen < 0 ? 0 : endLen)
    } else {
      return value
    }
  }
}
export const camelToUnderline = (str) => {
  if (str) {
    return str.replace(/([A-Z])/g, '_$1').toLowerCase()
  } else {
    return str
  }
}

export const desenRulesAll = (key, value) => {
  if (window.VueMainInstance.$store.getters.userExtend.enableDesensitizationRule) {
    const isVisit = window.VueMainInstance.$store.getters.isVisit
    const isAgent = window.VueMainInstance.$store.getters.isAgent
    const desensitizationRules = window.VueMainInstance.$store.getters.desensitizationRules
    const desenTeamIdList = window.VueMainInstance.$store.getters.desenTeamIdList
    const desenObjects = window.VueMainInstance.$store.getters.desenObjects
    const teamId = window.VueMainInstance.$store.getters.teamId
    // if (['idCard'].includes(key)) {
    //   key = camelToUnderline(key)
    // }
    if (key === 'mobile' && desensitizationRules['own_mobile']) {
      key = 'own_mobile'
    }
    if (isVisit || isAgent) { // 催员、外访员身份
      if (desenObjects.desensitizationUserLevel) {
        const isInTeam = desenTeamIdList.includes(teamId)
        if (isInTeam) {
          let targetKey = key
          if (['name', 'debtorName', 'debtName', 'caseName', 'contactName', 'conName'].includes(key)) {
            targetKey = 'name'
          } else if (['address', 'house_address', 'company_address', 'native_address', 'bill_path'].includes(key)) {
            targetKey = 'address'
          }
          const target = desensitizationRules[targetKey] || desensitizationRules[camelToUnderline(targetKey)]
          if (target) {
            return desenRules(value, target.type, target.scope, target.count, target.start, target.end)
          } else {
            return value
          }
        } else {
          return value
        }
      } else {
        return value
      }
    } else {
      if (desenObjects.desensitizationAdminLevel) {
        let targetKey = key
        if (['name', 'debtorName', 'debtName', 'caseName', 'contactName', 'conName'].includes(key)) {
          targetKey = 'name'
        } else if (['address', 'house_address', 'company_address', 'native_address', 'bill_path'].includes(key)) {
          targetKey = 'address'
        }
        const target = desensitizationRules[targetKey] || desensitizationRules[camelToUnderline(targetKey)]
        if (target) {
          return desenRules(value, target.type, target.scope, target.count, target.start, target.end)
        } else {
          return value
        }
      } else {
        return value
      }
    }
  } else {
    return value
  }
}

/**
 * 比较两个数组之间的数据，以array1的数据为基准，array2的数据的顺序及属性状态为依据进行调整array1的数据
 * @param {*} array1 基准数组
 * @param {*} array2 调准对象数组
 * @param {*} prop 用于判断两个数组内数据相等的属性
 */
export const compileArray = (array1, array2, prop) => {
  if (array2 && array2.length > 0) {
    const checked = []; const unChecked = []
    array2.map(item => {
      array1.map(i => {
        if (item[prop] === i[prop] && item[prop] && i[prop]) {
          if (item.checked === 'true' || item.checked === true) {
            checked.push({
              [prop]: item[prop],
              name: i.name || item.name,
              checked: true,
              fixed: item.fixed ? item.fixed : '',
              customType: item.customType ? item.customType : ''
            })
          } else {
            unChecked.push({
              [prop]: item[prop],
              name: i.name || item.name,
              checked: false,
              fixed: item.fixed ? item.fixed : '',
              customType: item.customType ? item.customType : ''
            })
          }
        }
      })
    })
    // 遍历array1以加入新增项
    array1.map(item => {
      let isNotExist = true
      array2.map(i => {
        if (item[prop] === i[prop]) {
          isNotExist = false
        }
      })
      if (isNotExist) {
        if (item.checked === 'true' || item.checked === true) {
          checked.push({
            [prop]: item[prop],
            name: item.name,
            checked: true
          })
        } else {
          unChecked.push({
            [prop]: item[prop],
            name: item.name,
            checked: false
          })
        }
      }
    })

    let result = [...checked, ...unChecked]; const hash = []
    result = result.reduce(function(item, next) {
      hash[next[prop]] ? '' : hash[next[prop]] = true && item.push(next)
      return item
    }, [])
    return result
  } else {
    return array1
  }
}

/**
 * 获取度言录音地址
 * @param {*} orgId
 * @param {*} callTime
 * @param {*} callUuid
 */
export const getAudioUrl = (orgId, callTime, callUuid) => {
  if (typeof callTime !== 'number') { callTime = new Date(callTime).getTime() }
  const localRecordStorage = window.VueMainInstance.$store.getters.userExtend.localRecordStorage
  // 开通了录音本地存储
  if (localRecordStorage) {
    const recordPrefix = window.VueMainInstance.$store.getters.userExtend.recordPrefix
    return `${recordPrefix}/ng/download/recording/${orgId}/${getDictionaryName('formatDate', Number(callTime), 'yyyy-MM-dd')}/${callUuid}.wav`
  }
  const baseUrl = `https://vresource.duyansoft.com${window.VueMainInstance.$store.getters.currentUserInfo.duyanServiceKey ? '/' + window.VueMainInstance.$store.getters.currentUserInfo.duyanServiceKey : ''}/recording/${orgId}/${getDictionaryName('formatDate', Number(callTime), 'yyyy-MM-dd')}/${callUuid}`
  return callTime < 1595520000000 ? baseUrl + '.oga' : baseUrl + '.wav'
}

/**
 * MB => GB，并保留两位小数，向上取整
 */
export const MBToGB = (num) => {
  return Math.ceil((num / 1024) * 100) / 100
}

/**
 * 随机uuid
 */
export const generateUuid = () => {
  var d = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
  localStorage.setItem('imageUuid', uuid)
  return uuid
}

/**
 * 时长计算
 */
export const calDurtion = (num) => {
  // 接收秒
  const result = parseInt(num)
  const h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600)
  const m = Math.floor(result / 60 % 60) < 10 ? '0' + Math.floor(result / 60 % 60) : Math.floor(result / 60 % 60)
  const s = Math.floor(result % 60) < 10 ? '0' + Math.floor(result % 60) : Math.floor(result % 60)

  let res = ''
  if (h !== '00') res += `${h}h`
  if (m !== '00') res += `${m}min`
  res += `${s}s`
  return res
}

export const formatDuration = (intnum, _zh, isMillisecond = false) => {
  if (!intnum) { return !_zh ? '00:00:00' : '0秒' }

  let out = 0
  intnum = Number(intnum)
  if (isMillisecond) { intnum /= 1000 }
  const h = parseInt(intnum / 3600) < 10 && !_zh ? ('0' + parseInt(intnum / 3600)) : parseInt(intnum / 3600)
  const m = parseInt(intnum % 3600 / 60) < 10 ? (_zh ? parseInt(intnum % 3600 / 60) : '0' + parseInt(intnum % 3600 / 60)) : parseInt(intnum % 3600 / 60)
  const s = (intnum % 3600 % 60).toFixed(0) < 10 ? (_zh ? (intnum % 3600 % 60).toFixed(0) : '0' + (intnum % 3600 % 60).toFixed(0)) : (intnum % 3600 % 60).toFixed(0)

  out = _zh ? ((h + '小时') + (m + '分钟') + (s + '秒')) : (h + ':' + m + ':' + s)
  if (!_zh) {
    out = h + ':' + m + ':' + s
  } else {
    if (intnum < 60) {
      out = s + '秒'
    } else if (intnum < 3600) {
      out = m + '分钟' + s + '秒'
    } else {
      out = h + '小时' + m + '分钟' + s + '秒'
    }
  }
  return out
}

/**
 * 获取水印内容
 * @param {*} str
 * @param {*} refs
 * @param {*} className
 * @returns
 */
export const getWatermark = () => {
  const store = window.VueMainInstance.$store.getters

  let str = ''
  if (store.watermarkPattern) {
    const pattern = store.watermarkPattern.split('-')
    const watermark = []
    pattern.map(item => {
      switch (item) {
        case 'role':
          watermark.push(store.currentRole.name)
          break
        case 'name':
          watermark.push(store.userName)
          break
        case 'date':
          watermark.push(formatDate(new Date(), 'yyyyMMdd'))
          break
        case 'ip':
          watermark.push(store.ip)
          break
        case 'companyName':
          watermark.push(store.orgName)
          break
      }
    })

    str = watermark.join('-')
  } else {
    str = `${store.currentRole.name}-${store.orgName}-${formatDate(new Date().getTime(), 'yyyyMMdd')}`
  }

  return str
}

/**
 * 水印
 * @param {*} str 水印内容
 * @param {*} refs 页面元素
 * @param {*} className 页面class
 */
export const setWaterMark = (refs, className) => {
  if (document.getElementById('waterMark')) return
  // 创建一个画布
  const can = document.createElement('canvas')
  // 设置画布的长宽
  can.width = refs.offsetWidth
  can.height = refs.offsetHeight
  const str = getWatermark()

  const cans = can.getContext('2d')
  // 旋转角度
  cans.rotate(-20 * Math.PI / 180)
  cans.font = '14px Vedana'
  // 设置填充绘画的颜色、渐变或者模式
  cans.fillStyle = 'rgba(200, 200, 200, 0.40)'
  // 设置文本内容的当前对齐方式
  cans.textAlign = 'left'
  // 设置在绘制文本时使用的当前文本基线
  cans.textBaseline = 'Middle'
  const cansWidth = cans.measureText(str).width

  const rows = [100, 275, 450, 625, 800, 975, 1150]

  for (let forIndex = 0; forIndex < rows.length; forIndex++) {
    for (let i = 0; i < 10; i++) {
      const contx = str ? str.split('-') : []
      const len = contx.length

      let showText = ''
      for (let j = 0; j < len; j++) {
        showText += (j === len - 1) ? contx.slice(j, j + 1) : contx.slice(j, j + 1) + '-'
        if (((j + 1) / 3) === 1) {
          cans.fillText(showText, i * 300, rows[forIndex])
          showText = ''
        }
        if (j === len - 1 && ((j + 1) / 3 !== 1)) {
          cans.fillText(showText, i * 300, rows[forIndex] + 20)
          showText = ''
        }
      }
    }
  }

  const div = document.createElement('div')
  const box = className
  div.id = 'waterMark'
  div.style.pointerEvents = 'none'
  div.style.top = '11%'
  div.style.left = '0px'
  div.style.position = 'fixed'
  div.style.zIndex = '900'
  div.style.width = box.clientWidth + 'px'
  div.style.height = box.clientHeight + 'px'
  div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat'

  box.appendChild(div)
}

/**
 * 获取数组对象中与value相匹配的name
 */
export const findName = (value, list) => {
  if (!Array.isArray(list)) return ''

  let name = ''
  for (const item of list) {
    if (item.value === value) {
      name = item.name
    }
  }

  return name
}

/**
 * 权限判断
 * @param {*} functions 权限集合
 * @param {*} id 页面id
 * @param {*} functionType 权限名称
 */
export const operationRight = (id, functionType = [], functions = null) => {
  const functionList = functions || window.VueMainInstance.$store.getters.functions
  const operationRight = new Array(functionType.length).fill(false)
  const result = findTarget(functionList, 'children', 'id', id)
  if (result.length > 0) {
    result[0].children && result[0].children.map(item => {
      for (const key in functionType) {
        if (item.name === functionType[key]) {
          operationRight[key] = true
        }
      }
    })
  }

  return operationRight
}
// 保留两位小数不四舍五入
export const getRate = (num) => {
  // return (Math.floor(num*100)/100).toFixed(2)
  // 四舍五入
  return (num.toFixed(2))
}
// 提取统计相关方法
// 昨天
export const yesterday = () => {
  const today = formatDate(new Date(), 'yyyy/MM/dd')
  const start = new Date(today + ' 00:00:00').getTime() - 24 * 60 * 60 * 1000
  const end = new Date(today + ' 00:00:00').getTime() - 24 * 60 * 60 * 1000
  return [new Date(start), new Date(end)]
}

// 本周
export const thisWeek = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = today.getMonth() + 1
  const day = today.getDate()
  const date = today.getDay()
  const todayDate = new Date(`${year}/${month}/${day}`).getTime()
  const start = todayDate + 1000 - (date === 0 ? 7 : date) * 24 * 3600 * 1000
  return [new Date(start), new Date(todayDate)]
}

// 上周
export const lastWeek = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = today.getMonth() + 1
  const day = today.getDate()
  const date = today.getDay()
  const end =
    new Date(`${year}/${month}/${day}`).getTime() -
    (date - 1 === -1 ? 6 : date - 1) * 24 * 3600 * 1000 -
    1000 -
    24 * 3600 * 1000 +
    1000
  const start = new Date(end) - 7 * 24 * 3600 * 1000 + 1000

  return [new Date(start), new Date(end)]
}

// 本月
export const thisMonth = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = today.getMonth() + 1
  const day = today.getDate()
  const end = new Date(`${year}/${month}/${day}`).getTime()
  const start = new Date(`${year}/${month}/1 00:00:00`).getTime()
  return [formatDate(new Date(start)), formatDate(new Date(end))]
}

// 上月
export const lastMonth = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = today.getMonth() + 1
  const day = today.getDate()

  // 获取上个月最后一天时间
  let end, start

  if (month === 1) {
    end = new Date(new Date(`${year - 1}/12/31`).getTime())
    start = new Date(new Date(`${year - 1}/12/1 00:00:00`).getTime())
  } else {
    end = new Date(new Date(`${year}/${month}/${day}`).getTime()) - day * 24 * 3600 * 1000
    start = new Date(`${year}/${month - 1}/1 00:00:00`).getTime()
  }

  return [formatDate(new Date(start)), formatDate(new Date(end))]
}

export const closeTransPoptip = (ev) => {
  // 解决poptip transfer的情况下关闭时会移位闪现的问题
  const parentNode = ev.target.parentNode
  if ((parentNode && parentNode.className === 'ivu-poptip-rel')) {
    return
  }

  const doms = document.getElementsByClassName('poptip_trans')
  let dom
  for (const item of doms) {
    if (item.style.display !== 'none') {
      dom = item
    }
  }

  if (dom) {
    dom.style.display = 'none'
  }
}
// 处理vite 图片导入 获取assets静态资源 <img :src="getAssetsFile('login.png')" />
export const getAssetsFile = (url) => {
  const path = `../assets/images/${url}`
  const modules = import.meta.globEager('../assets/images/*')
  return modules[path].default
  // return new URL(path, import.meta.url).href
}
// 动态添加js
export const addJs = (urls) => {
  let i = 0
  return new Promise((resolve, reject) => {
    const loadNext = () => {
      const url = urls[i]
      const script = document.createElement('script')
      script.src = url
      script.onload = () => {
        i++
        if (i < urls.length) {
          loadNext()
        } else {
          resolve()
        }
      }
      script.onerror = () => {
        reject(new Error(`Failed to load script: ${url}`))
      }
      document.body.appendChild(script)
    }
    loadNext()
  })
}

export const formatTime = (second) => { //  时 分 秒 时间计时器
  var secondType = typeof second

  if (secondType === 'number' || secondType === 'string') {
    second = parseInt(second)

    var h = parseInt(second / 3600)
    var m = parseInt((second - h * 3600) / 60)
    var s = second - h * 3600 - m * 60

    // 格式化
    h < 10 ? h = '0' + h : ''
    m < 10 ? m = '0' + m : ''
    s < 10 ? s = '0' + s : ''

    var time = h + ':' + m + ':' + s
    return time
    // return `${h}:${m}:${s}`
  } else {
    return '0:00:00'
  }
}

export const judgeIsToday = () => {
  // 获取当前日期的字符串表示
  const currentDate = new Date().toISOString().slice(0, 10)

  // 从本地存储中获取上次执行日期的字符串表示
  const lastDate = localStorage.getItem('lastDate')

  // 如果上次执行日期不是今天，则返回true并更新上次执行日期
  if (lastDate !== currentDate) {
    // 更新上次执行日期为今天
    localStorage.setItem('lastDate', currentDate)
    return true
  } else {
    return false
  }
}

// 除法函数，用来得到精确的除法结果
// 说明：javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
// 调用：accDiv(arg1,arg2)
// 返回值：arg1除以arg2的精确结果
export const accDiv = (arg1, arg2) => {
  let t1 = 0
  let t2 = 0
  let r1 = 0
  let r2 = 0
  try {
    t1 = arg1.toString().split('.')[1].length
  } catch (e) {}
  try {
    t2 = arg2.toString().split('.')[1].length
  } catch (e) {}
  r1 = Number(arg1.toString().replace('.', ''))
  r2 = Number(arg2.toString().replace('.', ''))
  return (r1 / r2) * Math.pow(10, t2 - t1)
}

// 字符串隐藏部分用...代替
export const hideStringByEllipsis = (str, pre) => {
  if (str.length > pre) {
    return str.substr(0, pre) + '...'
  } else {
    return str
  }
}
