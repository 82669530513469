import { get, post, put } from '@/libs/api.request'

export const loginAdmin = (data) => {
  return post('/v1/admin/login', data, { noSucTip: true })
}

export const getMenuList = (data) => {
  return get('/v1/admin/menuList', data)
}

export const addNewOrg = (org) => {
  return post('/v1/admin/org', org)
}

export const editOrg = (org) => {
  return put('/v1/admin/org', org)
}

export const getOrg = (org) => {
  return get('/v1/admin/org/orgList', org)
}
export const updateSms = (data) => {
  return post('/v1/admin/org/updateSms', data)
}
export const enbleOrg = (data) => {
  return post('/v1/admin/org/enable', data)
}

export const setSeatLimit = (data) => {
  data.seatLimit = Number(data.seatLimit)
  return post('/v1/admin/org/setSeatLimit', data)
}

export const setOrgSwitches = (data) => {
  return post('/v1/admin/org/setSwitches', data)
}

export const toggleConnectedDuyan = (sendData) => {
  return put(`/v1/admin/org/connectDuyan`, sendData)
}
export const callBackUrl = (data) => {
  return put('/v1/admin/org/setCallbackUrl', data)
}

export const downloadDeploy = (id) => {
  return `/xapi/v1/admin/org/generateLicense/${id}?downloadT=${Date.now()}`
}

export const getSales = (data) => {
  return get('/v1/admin/sale/list', data)
}

export const visitSwitch = (data) => {
  return put('/v1/admin/org/orgSwitchByVisit', data)
}

export const orgResetAdminWhiteList = (data) => {
  return put('/v1/admin/org/orgResetAdminWhiteList', data)
}

export const duyanSync = (data) => {
  return post('/v1/admin/org/duyan', data)
}

export const salesSync = (data) => {
  return post('/v1/admin/org/sales', data)
}

export const region = (data) => {
  return get('/v1/admin/org/region', data)
}

export const businessType = (data) => {
  return get('/v1/admin/org/business/type', data)
}

export const letterSwitch = (data) => {
  return put('/v1/admin/org/letterSwitch', data)
}
export const strategySwitch = data => {
  return put('/v1/admin/org/strategySwitch', data)
}
export const getOrgWhite = (data) => {
  return get('/v1/admin/org/white', data)
}

export const mediateSwitch = (data) => {
  return put('/v1/admin/org/mediationSwitch', data)
}

export const lawsuitSwitch = (data) => {
  return put('/v1/admin/org/lawsuitSwitch', data)
}

// 委外管理接口
export const isAmcSwitch = (data) => {
  return put('/v1/admin/org/isAmc', data)
}

// 清除呼叫中心组织架构
export const cleanCall = (data) => {
  return put(`/v1/admin/org/orgRestCallCenter?orgId=${data.orgId}`)
}

// 调整免费存储空间
export const changSpaceFree = (data) => {
  return put('/v1/admin/orgSpace/free', data)
}

// 购买存储空间
export const addSpace = (data) => {
  return post('/v1/admin/spaceRecord/add', data)
}

// 查看存储空间购买记录
export const getSpaceList = (data) => {
  return get(`/v1/admin/spaceRecord/list`, data)
}
// 配置工作手机
export const AddWorkPhone = data => {
  return post('v1/admin/org/work/phone', data)
}
// 测试工作手机地址
export const testWorkPhone = data => {
  return get('v1/admin/org/work/phone/test', data)
}

// 设置度言回调
export const setCallbackUrl = (data) => {
  return put('/v1/admin/org/setCallbackUrl', data)
}
// 慧捷呼叫中心开关
export const setWellPhoneSwitch = data => {
  return put('v1/admin/org/wellPhoneSwitch', data)
}
// 浦发开关
export const SpdSwitch = data => {
  return put('/v1/admin/org/spdSwitch', data)
}
// /v1/admin/org/localRecordStorageSwitch
// 录音本地存储
export const RecordStorageSwitch = data => {
  return put('/v1/admin/org/localRecordStorageSwitch', data)
}
// v1/admin/org/sync/duyan/user 同步呼叫中心
export const syncUser = orgId => {
  return post('/v1/admin/org/sync/duyan/user?orgId=' + orgId)
}
// 京东开关
export const jdSwitch = data => {
  return put('/v1/admin/org/jdSwitch', data)
}
