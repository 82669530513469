import { get, post, put } from '@/libs/api.request'

export const loginOpen = (loginData) => {
  loginData.password = btoa(loginData.password)
  return post('v1/openApi/login', loginData, { noSucTip: true })
}
export const repaymentImport = data => {
  return post('v1/openApi/repaymentImport', data, { openApi: true })
}
export const getResult = data => {
  return get('v1/openApi/queryRepaymentImportResult', data, { openApi: true })
}
