import { get, post, put, del } from '@/libs/api.request'

export const getOrg = (searchData) => {
  return get('/v1/org', searchData)
}

export const getOrgDet = ({ id }) => {
  return get('/v1/org/details/' + id)
}

export const addOrg = (delt) => {
  return post('/v1/org', delt)
}

export const updateOrg = (delt) => {
  return put('/v1/org', delt)
}

export const delOrg = ({ id }) => {
  return del('/v1/org/' + id)
}
