import { get, post, put, del } from '@/libs/api.request'

export const getAccount = (searchData) => {
  return get('/v1/user', searchData)
}
export const getAccountList = (searchData) => {
  return get('/v1/user/list', searchData)
}
export const getListRole = (searchData) => {
  return get('/v1/user/listByRole', searchData)
}
export const getExcludeUser = (data) => {
  return get('/v1/user/excludeList', data)
}
export const getAccountDet = ({ id }) => {
  return get('/v1/user/details/' + id)
}

export const addAccount = (delt) => {
  return post('/v1/user', delt, { noSucTip: true })
}

export const updateAccount = (delt) => {
  return put('/v1/user', delt)
}

export const delAccount = ({ id, isRemoveRole = false, depType }) => {
  if (depType) {
    return del('/v1/user/' + id + '?isRemoveRole=' + isRemoveRole + '&depType=' + depType, null)
  } else {
    return del('/v1/user/' + id + '?isRemoveRole=' + isRemoveRole, null, { noErrTip: true, hasErrorRes: true })
  }
}
export const giveRoleToUsers = (data) => {
  return put('/v1/user/giveRoleToUsers', data)
}
export const adminList = (data) => {
  return get('v1/user/listRolesByUser')
}

export const getUserLimit = (data) => {
  return get('v1/org/getUserLimit', data)
}

export const getAllCounts = (data) => {
  return get('/v1/user/dunnerIdList', data)
}
