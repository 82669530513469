import { get, post, del } from '@/libs/api.request'

export const getRoles = (searchData) => {
  return get('/v1/role', searchData)
}

export const getAllFunc = (isBranchAdmin) => {
  return get('/v1/role/function/' + isBranchAdmin, null)
}

export const getFuncByRole = ({ roleId }) => {
  return get(`v1/role/details/${roleId}`)
}

export const addRole = (searchData) => {
  return post(`v1/role`, searchData)
}

export const updateRole = (searchData) => {
  return post(`v1/role/function/`, searchData)
}

export const getRoleDet = ({ id }) => {
  return get('/v1/role/details/' + id)
}

export const delRole = (id) => {
  return del('/v1/role/delCustomRole/' + id)
}

// 账号日志
export const getJournal = (data) => {
  return get('/v1/user/log', data)
}