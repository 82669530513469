import { on } from '@/libs/tools'
import { getWatermark } from '@/libs/util'
const directives = {
  draggable: {
    inserted: (el, binding, vnode) => {
      const triggerDom = document.querySelector(binding.value.trigger)
      triggerDom.style.cursor = 'move'
      const bodyDom = document.querySelector(binding.value.body)
      let pageX = 0
      let pageY = 0
      let transformX = 0
      let transformY = 0
      let canMove = false
      const handleMousedown = e => {
        let transform = /\(.*\)/.exec(bodyDom.style.transform)
        if (transform) {
          transform = transform[0].slice(1, transform[0].length - 1)
          const splitxy = transform.split('px, ')
          transformX = parseFloat(splitxy[0])
          transformY = parseFloat(splitxy[1].split('px')[0])
        }
        pageX = e.pageX
        pageY = e.pageY
        canMove = true
      }
      const handleMousemove = e => {
        const xOffset = e.pageX - pageX + transformX
        const yOffset = e.pageY - pageY + transformY
        if (canMove) bodyDom.style.transform = `translate(${xOffset}px, ${yOffset}px)`
      }
      const handleMouseup = e => {
        canMove = false
      }
      on(triggerDom, 'mousedown', handleMousedown)
      on(document, 'mousemove', handleMousemove)
      on(document, 'mouseup', handleMouseup)
    },
    update: (el, binding, vnode) => {
      if (!binding.value.recover) return
      const bodyDom = document.querySelector(binding.value.body)
      bodyDom.style.transform = ''
    }
  },
  waterMark: {
    inserted: (el, binding, vnode) => {
      if (document.getElementById('waterMark')) return
      if (!window.VueMainInstance.$store.getters.userExtend.watermarkSwitch) return

      setTimeout(() => {
        // 创建一个画布
        const can = document.createElement('canvas')
        // 设置画布的长宽
        can.width = el.offsetWidth
        can.height = el.offsetHeight
        // 获取水印内容
        const str = getWatermark()

        const cans = can.getContext('2d')
        // 旋转角度
        cans.rotate(-20 * Math.PI / 180)
        cans.font = '14px Vedana'
        // 设置填充绘画的颜色、渐变或者模式
        cans.fillStyle = 'rgba(200, 200, 200, 0.40)'
        // 设置文本内容的当前对齐方式
        cans.textAlign = 'left'
        // 设置在绘制文本时使用的当前文本基线
        cans.textBaseline = 'Middle'
        const cansWidth = cans.measureText(str).width

        const rows = [100, 275, 450, 625, 800, 975, 1150]

        for (let forIndex = 0; forIndex < rows.length; forIndex++) {
          for (let i = 0; i < 10; i++) {
            const contx = str ? str.split('-') : []
            const len = contx.length

            let showText = ''
            for (let j = 0; j < len; j++) {
              showText += (j === len - 1) ? contx.slice(j, j + 1) : contx.slice(j, j + 1) + '-'
              if (((j + 1) / 3) === 1) {
                cans.fillText(showText, i * 300, rows[forIndex])
                showText = ''
              }
              if (j === len - 1 && ((j + 1) / 3 !== 1)) {
                cans.fillText(showText, i * 300, rows[forIndex] + 20)
                showText = ''
              }
            }
          }
        }

        const div = document.createElement('div')
        const box = el
        div.id = 'waterMark'
        div.style.pointerEvents = 'none'
        div.style.top = '11%'
        div.style.left = '0px'
        div.style.position = 'fixed'
        div.style.zIndex = '9999'
        div.style.width = '100%'
        div.style.height = '100%'
        div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat'

        box.appendChild(div)
      }, 2000)
    }
  },
  disCopy: {
    inserted: (el, binding, vnode) => {
      if (!window.VueMainInstance.$store.getters.userExtend.copySwitch) return

      if (!binding.value) return

      // 禁用右键
      el.oncontextmenu = new Function('event.returnValue=false')
      // 禁用选择
      el.onselectstart = new Function('event.returnValue=false')

      document.onkeydown = function(event) {
        if (event.ctrlKey && window.event.keyCode === 65) { // 禁用ctrl + a 功能
          return false
        }
        if (event.ctrlKey && window.event.keyCode === 67) {	// 禁用ctrl + c 功能
          return false
        }

        if (event.metaKey && event.keyCode === 65) {
          return false
        }

        if (event.metaKey && event.keyCode === 67) {
          return false
        }
      }
    }
  }
}

export default directives
