import { get, post, put, del, postForm } from '@/libs/api.request'

export const getTemplate = ({ id }) => {
  return get(`/v1/cases/template/details/${id}`)
}

export const getTemplates = (searchData) => {
  return get('/v1/cases/template', searchData)
}

export const getAllTemplates = (searchData) => {
  return get('/v1/cases/template/all', searchData)
}

export const addTemplate = (template) => {
  return post('/v1/cases/template', template)
}

export const updateTemplate = (template) => {
  return put('/v1/cases/template', template)
}

export const delTemplate = ({ id }) => {
  return del(`/v1/cases/template/${id}`)
}

export const downloadTemplate = ({ id }) => {
  return `/xapi/v1/cases/template/download/${id}?downloadT=${Date.now()}`
}

export const uploadTemplate = (uploadForm) => {
  return postForm('/v1/file/upload/', uploadForm, { uploadTime: true, noSucTip: true })
}

export const getCustomTp = (template) => {
  return get(`/v1/cases/template/customFields`)
}

export const getCustomTpAdmin = (template) => {
  // return get(`/v1/cases/template/customFieldsAdmin`)  //老接口
  return get('/v1/cases/template/customFields')
}

export const getColorField = (data) => {
  return get('/v1/org/getColorField', data)
}

export const addCustomTp = (template) => {
  return post(`/v1/cases/template/addFields`, template, { hasErrorRes: true })
}

export const delCustomTp = (template) => {
  return put(`/v1/cases/template/delCustomFields`, template)
}

export const getSearchFields = (template) => {
  return get(`/v1/cases/template/customSearchFields`, template)
}

export const getUsedSearchFields = (template) => {
  return get(`/v1/cases/template/listUsedSearchFields`, template)
}

export const setCustomSF = (template) => {
  return put(`/v1/cases/template/setCustomSearchField`, template)
}
