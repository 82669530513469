export default {
  // 菜单
  m_home: 'Home',
  m_mycase: 'My Cases',

  // menu form db
  m_casectrl: 'Cases',
  m_casectrl_cases: 'List',
  m_casectrl_transfer: 'Transfer',
  m_casectrl_batch: 'Batches',
  m_casectrl_file: 'Files',
  m_casectrl_update: 'Case Update',
  m_casectrl_import: 'Case Import',
  m_casectrl_template: 'Templates',
  m_casectrl_contactImport: 'Contact Import',
  m_casectrl_oplog: 'Handle Log',
  m_refund: '催收管理',
  m_refund_controller: '还款管理',
  m_refund_import: '还款导入',
  m_import_result: '导入结果',
  m_refund_reduction: '减免管理',
  m_refund_record: '催记管理',
  m_refund_contactfix: '信修审核',
  m_system: 'System',
  m_system_mechanism: 'Subaccount',
  m_system_department: 'Child Company',
  m_system_account: 'Staf',
  m_system_permission: 'Permission',
  m_delegation: 'Entrusting',
  m_delegation_company: 'Company',
  m_delegation_product: 'Product',
  m_assist: 'Assist',
  m_assist_robot: 'Robot Plan',
  m_assist_sms: 'SMS Plan',
  m_assist_smstpl: 'SMS Template',
  m_callLog: '通话记录',

  // 系统
  closeOthers: 'Close Others',
  closeAll: 'Close All',
  switchRole: 'Switch Role',
  signOut: 'Sign Out',
  chgPwd: 'Change Password',
  addRobotPlan: 'Add Robot plan', // 添加机器人计划
  checkPlanDetail: 'Check plan detail',
  operation: 'Operation',
  detail: 'Detail',
  startPlan: 'Start Plan',
  pausePlan: 'Pause Plan',
  cancelPlan: 'Cancel Plan',
  addSmsPlan: 'Add Sms Plan',
  addSmsTpl: 'Add Sms Template',

  // 表格字段
  k_delt: 'Entrusting Company ',
  k_deltProduct: 'Product', // 委案产品
  k_outBatchNo: 'Entrusting Batch No',
  k_batchNo: 'Batch No',
  k_debtor: 'Bebtor',
  k_mobile: 'Mobile',
  k_callResult: 'Call Result',
  k_talkOverResult: 'Talk Over Result',
  k_callTime: 'Call Time',
  k_callDuration: 'Call Duration',
  k_description: 'Description',
  k_planName: 'Plan Name',
  k_planStatus: 'Plan Status',
  k_outboundDetail: 'Outbound Detail',
  k_robotLogic: 'Robot Logic',
  k_planCreatedTime: 'Plan Created Time',
  k_sentStatus: 'Sent Status',
  k_count: 'Count',
  k_successCount: 'Success Count',
  k_smsTplTit: 'SMS Template Title',
  k_createdTime: 'Created Time',
  k_sentTime: 'Sent Time',

  // 问题确认
  q_startPlan: 'Start plan confirm',
  q_pausePlan: 'Pause plan confirm',

  excel: 'Excel',
  'upload-excel': 'Upload Excel',
  'export-excel': 'Export Excel',
  tools_methods_page: 'Tools Methods',
  drag_list_page: 'Drag-list',
  i18n_page: 'Internationalization',
  modalTitle: 'Modal Title',
  content: 'This is the modal box content.',
  buttonText: 'Show Modal',
  'i18n-tip': 'Note: Only this page is multi-language, other pages do not add language content to the multi-language package.',
  error_store_page: 'Error Collection',
  error_logger_page: 'Error Logger',
  query: 'Query',
  params: 'Params'
}
