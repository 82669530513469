import { login, getInfo, redirect, getDesensitizationRules, getVerifyCode } from '@/api/user'
import { getRobotList } from '@/api/robot'
import {
  setToken,
  setOrgId,
  setDepInfo,
  setName,
  getToken,
  getOrgApikey,
  getName,
  setOrgApikey,
  getMenuByRouter,
  buildRouter,
  setUserInLocalstorage,
  setTagNavListInLocalstorage,
  setLoginInLocalstorage,
  getLoginInLocalstorage,
  judgeAgent,
  localRead
} from '../../libs/util.js?t=20221115'
import i18n from '@/locale'
import { loginOpen } from '@open/api/org'
import { getRecordAddrPrefix } from '@/api/org'

const $t = (key) => {
  return i18n.t(key)
}

const EXTEND_DATA = {
  duyanAccountId: '',
  duyanAdmin: '',
  duyanToken: '',
  duyanCfgToken: '',
  changePasswd: 1,
  duyanOrgId: '',
  enableAutoSwitchPhone: false, // 通讯录打本人可以选择线路开关
  enableDesensitizationRule: false, // 脱敏开关
  enableAutoUpdateOverdueDays: false,
  enableConnectedDuyan: false,
  enableIpAccessAdmin: false,
  enableIpAccessAgent: false,
  enableAccessDatabase: false,
  enableHiddenInfo: false,
  isAutoTranscription: false,
  isFifoEnabled: false,
  isMessageEnabled: false,
  visitSwitch: 0,
  miniProgramsSwitch: 0,
  noteDisplaySwitch: 0,
  operationModifySwitch: 0,
  autoEndSwitch: 0,
  caseCooperationSwitch: false,
  watermarkSwitch: 0,
  copySwitch: 1,
  caseSyncSwitch: 0,
  operationHiddenSwitch: 0,
  showHiddenOperationSwitch: 0,
  isEarlyMediaRecEnabled: false,
  depVisitSwitch: false,
  fifoSwitch: false,
  smsSwitch: false,
  robotSwitch: false,
  isEarlyMediaRecorded: false,
  isCampaignEarlyMediaRecorded: false,
  teamUrgeSwitch: 0,
  phoneDisplaySwitch: false,
  callerDisplaySwitch: false,
  noticeSwitch: false,
  operationVoiceSeatSwitch: false,
  operationVoiceTeamSwitch: false,
  visitContactSwitch: 0,
  strongPasswordSwitch: 0,
  mediationSwitch: 0,
  fieldColorSwitch: 0,
  ctrlSwitch: 0,
  lawsuitSwitch: 0,
  letterSwitch: 0, // 信函开关
  isRobotEnabled: false, // 老机器人开关
  wellPhoneSwitch: true, // 是否开启慧捷呼叫中心
  dispatchRobotEnabled: false, // 新机器人开关
  endTypeSwitch: 0, // 结案类型开关
  enableConnectDuyan: false, // 是否连接度言
  fifoToRobotEnabled: false, // 是否开启预测试外呼转机器人
  writeOperationSwitch: 0, // 不填写催记按钮
  caseInfoModifySwitch: 0, // 支持催员修改案件信息开关
  caseInfoModifyVisitorSwitch: false, // 支持催员修改案件信息开关
  caseNoSwitch: 0,
  operationConfigLinkSwitch: 0, // 催记行动结果强关联
  globalCtrlSwitch: 0, // 全局管控开关
  globalCtrlType: 0, // 管控维度
  callInAutoAllotCaseSwitch: 0, // 自动分案
  callInSeatExclusiveLineSwitch: 0, // 专属线路
  userQuerySwitch: 1, // 催员案件查询权限
  caseViewingSwitch: 0, // 非个人名下案件支持查看/操作
  userContactsImportSwitch: 0, // 催员案件联系人导入
  wpAddWechat: 0, // 工作手机微信是否添加微信好友
  wpCallPhone: 0, // 工作手机是否拨打电话
  wpCustomSmsContent: 0, // 工作手机是否自定义输入短信内容
  wpCustomWechatApply: 0, // 工作手机是否允许自定义输入微信好友申请内容
  wpSendSms: 0, //  工作手机是否发送短信
  userViewSwitch: 1, // 案件列表表头开关
  recordPrefix: '', // 本地存储录音地址
  localRecordStorage: 0, // 本地存储录音开关
  operationSafetySwitch: 0 // 操作安全设置开关
}

export default {
  state: {
    openSrc: judgeAgent() ? 'https://qos-test.duyansoft.com/login' : 'https://qos.duyansoft.com/login',
    userName: getName() || '',
    userNo: '',
    userId: '',
    orgId: '',
    dep: '',
    orgName: '',
    avatorImgPath: '',
    ip: '',
    watermarkPattern: '',
    token: getToken(),
    orgApikey: getOrgApikey() || '',
    access: '',
    mobile: '',
    roles: [],
    menuList: [],
    routers: [],
    currentRole: null,
    currentUserInfo: null,
    inspectType: null,
    extend: { ...EXTEND_DATA },
    dyAgent: false,
    functions: [],
    isAmc: 0, // 是否甲方，0否 1是
    // 脱敏规则
    desensitizationRules: [],
    desenTeamIdList: [], // 脱敏小组
    desenObjects: {
      desensitizationUserLevel: 0, // 催员
      desensitizationAdminLevel: 0 // 管理员
    }, // 脱敏对象
    robot: [], // 机器人列表
    teamId: null,
    isVerifyCode: getLoginInLocalstorage().isVerifyCode || true,
    randomStr: getLoginInLocalstorage().randomStr || '',
    isOldUser: false,
    vcDownCount: 60, // 获取验证码倒计时
    voDownCount: 60, // 获取语音验证码倒计时
    timeCountDown: false, // 是否显示验证码倒计时
    voTimeCountDown: false, // 是否显示语音验证码倒计时
    localDeploy: false, // 是否是本地化客户
    robotPlanTime: '', // 机器人计划时间
    fifoStartTime: '', // 预测式外呼开始时间
    fifoEndTime: '', // 预测式外呼结束时间
    duyanFifoAgent: false, // 预测式外呼坐席
    caseCooperation: [], // 申请协催
    colorFields: [], // 案件标色字段
    isWellPhoneLogin: false, // 是否登录慧捷sdk
    globalCallPhone: ''
  },
  getters: {
    userId: state => state.userId,
    teamId: state => state.teamId,
    myOrgId: state => state.orgId,
    myDep: state => state.dep,
    openSrc: state => state.openSrc,
    mobile: state => state.mobile,
    robot: state => state.robot,
    isRobot: state => state.extend.isRobotEnabled,
    isNewRobot: state => state.extend.dispatchRobotEnabled,
    token: state => state.token,
    orgApikey: state => state.orgApikey,
    orgName: state => state.orgName,
    userRoles: state => state.roles,
    menuList: state => state.menuList,
    inspectType: state => state.currentRole ? state.currentRole.type : null,
    routers: state => state.routers,
    currentRole: state => state.currentRole,
    currentUserInfo: state => state.currentUserInfo,
    isAgent: state => state.currentRole && state.currentRole.type === 1,
    isSupervisor: state => state.currentRole && (state.currentRole.type === 0 || state.currentRole.type === 8) && state.currentRole.depType === 3,
    isVisit: state => state.currentRole && state.currentRole.type === 9 && state.currentRole.depType === 5,
    isInspector: state => state.currentRole && state.currentRole.type === 7,
    userExtend: state => state.extend,
    userName: state => state.userName,
    userNo: state => state.userNo,
    ip: state => state.ip,
    isOutsource: state => state.currentUserInfo && state.currentUserInfo.isAgent === 1 && (state.currentRole && (state.currentRole.type === 4 || (state.currentRole.depType === 4 && state.currentRole.type === 0))), // 是否委外机构
    // alias: state => state.alias,
    watermarkPattern: state => state.watermarkPattern,
    dyAgent: state => state.dyAgent,
    functions: state => state.functions,
    desensitizationRules: state => state.desensitizationRules,
    desenTeamIdList: state => state.desenTeamIdList,
    isVerifyCode: state => state.isVerifyCode,
    randomStr: state => state.randomStr,
    isOldUser: state => state.isOldUser,
    vcDownCount: state => state.vcDownCount,
    timeCountDown: state => state.timeCountDown,
    voDownCount: state => state.voDownCount,
    voTimeCountDown: state => state.voTimeCountDown,
    localDeploy: state => state.localDeploy,
    robotPlanTime: state => state.robotPlanTime,
    fifoStartTime: state => state.fifoStartTime,
    fifoEndTime: state => state.fifoEndTime,
    duyanFifoAgent: state => state.duyanFifoAgent,
    caseCooperation: state => state.caseCooperation,
    isAmc: state => state.isAmc,
    colorFields: state => state.colorFields,
    wellPhoneSwitch: state => state.extend.wellPhoneSwitch,
    isWellPhoneLogin: state => state.isWellPhoneLogin,
    desenObjects: state => state.desenObjects,
    globalCallPhone: state => state.globalCallPhone
  },
  mutations: {
    setExtend(state, kvs) {
      if (!kvs) {
        state.extend = { ...EXTEND_DATA }
        return
      }

      kvs.forEach((item) => {
        state.extend[item.key] = item.value
      })
    },
    setRobot(state, data) {
      state.robot = data.robotInfos
    },
    setCurrentRole(state, role) {
      state.currentRole = role
    },
    setCurrentUserInfo(state, userInfo) {
      state.currentUserInfo = userInfo
    },
    setMenuRouters(state, routers) {
      if (state.menuList.length > 0) {
        state.menuList = []
      }
      state.routers = routers
      state.menuList = getMenuByRouter(routers, state.access)
    },
    setMobile(state, mobile) {
      state.mobile = mobile
    },
    setAvator(state, avatorPath) {
      state.avatorImgPath = avatorPath
    },
    setUserRoles(state, roles) {
      state.roles = roles
    },
    setUserId(state, id) {
      state.userId = id
    },
    setTeamId(state, id) {
      state.teamId = id
    },
    setOrgId(state, orgId) {
      state.orgId = orgId
      setOrgId(orgId)
    },
    setDepInfo(state, dep) {
      state.dep = dep
      setDepInfo(dep)
    },
    setUserName(state, name) {
      state.userName = name
      setName(name)
    },
    setUserNo(state, no) {
      state.userNo = no
    },
    setIp(state, ip) {
      state.ip = ip
    },
    setWatermarkPattern(state, watermarkPattern) {
      state.watermarkPattern = watermarkPattern
    },
    setAccess(state, access) {
      state.access = access
    },
    setToken(state, token) {
      state.token = token
      setToken(token)
    },
    setOrgApikey(state, orgApikey) {
      state.orgApikey = orgApikey
      setOrgApikey(orgApikey)
    },
    setOrgName(state, orgName) {
      state.orgName = orgName
    },
    setIsAgent(state, dyAgent) {
      state.dyAgent = dyAgent
    },
    setIsSupervisor(state, isSupervisor) {
      state.isSupervisor = isSupervisor
    },
    setIsVisit(state, isVisit) {
      state.isVisit = isVisit
    },
    setIsInspector(state, isInspector) {
      state.isInspector = isInspector
    },
    setFunctions(state, functions) {
      state.functions = functions
    },
    setDesensitizationRules(state, desensitizationRules) {
      state.desensitizationRules = desensitizationRules
    },
    setDesenTeamlist(state, teamIdList) {
      state.desenTeamIdList = teamIdList
    },
    setDesenObject(state, object) {
      state.desenObjects = {
        desensitizationUserLevel: object.desensitizationUserLevel, // 催员脱敏开关
        desensitizationAdminLevel: object.desensitizationAdminLevel // 管理员脱敏开关
      }
    },
    setIsVerifyCode(state, isVerifyCode) {
      state.isVerifyCode = isVerifyCode
    },
    setRandomStr(state, randomStr) {
      state.randomStr = randomStr
    },
    setIsOldUser(state, isOldUser) {
      state.isOldUser = isOldUser === 1
    },
    setvcDownCount(state, vcDownCount) {
      state.vcDownCount = vcDownCount
    },
    setTimeCountDown(state, timeCountDown) {
      state.timeCountDown = timeCountDown
    },
    setvoDownCount(state, voDownCount) {
      state.voDownCount = voDownCount
    },
    setvoTimeCountDown(state, voTimeCountDown) {
      state.voTimeCountDown = voTimeCountDown
    },
    setLocalDeploy(state, localDeploy) {
      state.localDeploy = localDeploy === 1
    },
    setRobotPlanTime(state, robotPlanTime) {
      state.robotPlanTime = robotPlanTime
    },
    setFifoStartTime(state, fifoStartTime) {
      state.fifoStartTime = fifoStartTime
    },
    setFifoEndTime(state, fifoEndTime) {
      state.fifoEndTime = fifoEndTime
    },
    setDuyanFifoAgent(state, duyanFifoAgent) {
      state.duyanFifoAgent = duyanFifoAgent === 1
    },
    setCaseCooperation(state, caseCooperation) {
      state.caseCooperation = []
      state.extend.caseCooperation = caseCooperation || ''
      const caseCooperations = {
        0: '管理员审核',
        1: `${$t('agent')}抢单`,
        2: `${$t('agent')}接单`
      }
      const stateTemp = caseCooperation ? caseCooperation.split(',').map(item => Number(item)).sort((a, b) => a - b) : []

      if (stateTemp.length > 0) {
        stateTemp.map(item => state.caseCooperation.push({
          value: item,
          name: caseCooperations[item]
        }))
      }
    },
    setIsAmc(state, isAmc) {
      state.isAmc = isAmc
    },
    setColorFields(state, colorFields) {
      if (!colorFields) return []
      const keys = Object.keys(colorFields)
      state.colorFields = []
      for (const item of keys) {
        state.colorFields.push({
          name: colorFields[item],
          value: item
        })
      }
    },
    setIsLogin(state, bool) {
      state.isWellPhoneLogin = bool
    },
    setGlobalCallPhone(state, phone) {
      state.globalCallPhone = phone
    }
  },
  actions: {
    // 自动登录
    redirectLogin({ commit }, loginData) {
      commit('setToken', loginData.token)
      return new Promise((resolve, reject) => {
        const data = {
          loginKey: loginData.mobile ? loginData.mobile : loginData.userNo,
          loginType: loginData.mobile ? 0 : 1,
          orgId: loginData.orgId,
          token: loginData.token
        }
        redirect(data).then(res => {
          commit('refreshVcodeImg')
          if (res.success === false) {
            commit('setIsVerifyCode', true)
            commit('setRandomStr', res.data)
            const json = {
              isVerifyCode: true,
              randomStr: res.data
            }
            setLoginInLocalstorage(json)
            return false
          }
          const data = res.data
          commit('setIsVerifyCode', data.changePasswd !== 0)
          commit('setRandomStr', '')
          const json = {
            isVerifyCode: data.changePasswd !== 0,
            randomStr: ''
          }
          setLoginInLocalstorage(json)

          commit('setToken', data.token)
          commit('setOrgId', data.orgId)
          commit('setDepInfo', {
            depId: data.depId,
            depName: data.depName
          })
          commit('setUserRoles', data.roles)
          commit('setMobile', data.mobile)
          commit('setUserName', data.name)
          commit('setUserNo', data.userNo)
          commit('setIp', data.ip)
          commit('setIsAmc', data.isAmc)
          // commit('setAlias', data.alias)
          commit('setWatermarkPattern', data.watermarkPattern)
          commit('setUserId', data.id)
          commit('setIsOldUser', data.mobile ? data.oldUser : false)
          commit('setLocalDeploy', data.localDeploy)
          commit('setExtend', [{
            key: 'duyanAccountId',
            value: data.duyanAccountId
          }, {
            key: 'duyanToken',
            value: data.duyanToken
          }, {
            key: 'duyanAdmin',
            value: data.duyanAdmin
          }, {
            key: 'duyanCfgToken',
            value: data.duyanCfgToken
          }, {
            key: 'changePasswd',
            value: data.changePasswd
          }, {
            key: 'watermarkSwitch',
            value: data.watermarkSwitch === 1
          }, {
            key: 'copySwitch',
            value: data.copySwitch === 0
          }, {
            key: 'isEarlyMediaRecorded',
            value: data.isEarlyMediaRecorded
          }, {
            key: 'isCampaignEarlyMediaRecorded',
            value: data.isCampaignEarlyMediaRecorded
          }, {
            key: 'teamUrgeSwitch',
            value: data.teamUrgeSwitch === 1
          }, {
            key: 'phoneDisplaySwitch',
            value: !!data.phoneDisplaySwitch
          },
          {
            key: 'callerDisplaySwitch',
            value: !!data.callerDisplaySwitch
          },
          {
            key: 'noticeSwitch',
            value: !!data.noticeSwitch
          }, {
            key: 'visitContactSwitch',
            value: data.visitContactSwitch
          }, {
            key: 'strongPasswordSwitch',
            value: data.strongPasswordSwitch
          }, {
            key: 'mediationSwitch',
            value: data.mediationSwitch
          }, {
            key: 'fieldColorSwitch',
            value: data.fieldColorSwitch
          }, {
            key: 'ctrlSwitch',
            value: data.ctrlSwitch
          }, {
            key: 'globalCtrlSwitch',
            value: data.globalCtrlSwitch
          }, {
            key: 'globalCtrlType',
            value: data.globalCtrlType
          }, {
            key: 'lawsuitSwitch',
            value: data.lawsuitSwitch
          }, {
            key: 'workPhoneAgent',
            value: data.workPhoneAgent
          }, {
            key: 'endTypeSwitch',
            value: data.endTypeSwitch
          }, {
            key: 'fifoToRobotEnabled',
            value: data.fifoToRobotEnabled
          },
          {
            key: 'enableConnectedDuyan',
            value: data.enableConnectDuyan
          },
          {
            key: 'writeOperationSwitch',
            value: data.writeOperationSwitch
          },
          {
            key: 'callInAutoAllotCaseSwitch',
            value: data.callInAutoAllotCaseSwitch
          },
          {
            key: 'callInSeatExclusiveLineSwitch',
            value: data.callInSeatExclusiveLineSwitch
          },
          {
            key: 'userQuerySwitch',
            value: data.userQuerySwitch
          },
          {
            key: 'caseViewingSwitch',
            value: data.caseViewingSwitch
          },
          {
            key: 'userContactsImportSwitch',
            value: data.userContactsImportSwitch
          },
          {
            key: 'userViewSwitch',
            value: data.userViewSwitch
          }
          ])

          if (data.duyanToken) {
            commit('setIsAgent', true)
          } else {
            commit('setIsAgent', false)
          }

          resolve(data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // open 平台登陆
    handleOpen({ commit }, loginData) {
      return new Promise((resolve, reject) => {
        loginOpen(loginData).then(res => {
          commit('refreshVcodeImg')
          if (!res) {
            return false
          }
          const data = res.data
          commit('setToken', data.token)
          commit('setOrgApikey', data.orgApikey)
          commit('setOrgId', data.orgId)
          commit('setIsAmc', data.isAmc)
          commit('setDepInfo', {
            depId: data.depId,
            depName: data.depName
          })
          commit('setUserRoles', data.roles)
          commit('setMobile', data.mobile)
          commit('setUserName', data.name)
          commit('setUserNo', data.userNo)
          commit('setUserId', data.id)
          commit('setOrgName', data.orgName)
          setTagNavListInLocalstorage(null)
          localStorage.setItem('orgName', data.orgName)
          resolve(data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 登录
    handleLogin({ commit }, loginData) {
      return new Promise((resolve, reject) => {
        if (this.getters.randomStr) {
          loginData['randomStr'] = this.getters.randomStr
        }
        login(loginData).then(res => {
          commit('refreshVcodeImg')
          if (res.success === false) {
            commit('setIsVerifyCode', true)
            commit('setRandomStr', res.data)
            const json = {
              isVerifyCode: true,
              randomStr: res.data
            }
            setLoginInLocalstorage(json)
            return false
          }
          const data = res.data
          commit('setIsVerifyCode', data.changePasswd !== 0)
          commit('setRandomStr', '')
          localStorage.setItem('imageUuid', '')
          const json = {
            isVerifyCode: data.changePasswd !== 0,
            randomStr: ''
          }
          setLoginInLocalstorage(json)
          commit('setToken', data.token)
          commit('setOrgId', data.orgId)
          commit('setIsAmc', data.isAmc)
          commit('setDepInfo', {
            depId: data.depId,
            depName: data.depName
          })
          commit('setUserRoles', data.roles)
          commit('setMobile', data.mobile)
          commit('setUserName', data.name)
          commit('setUserNo', data.userNo)
          commit('setIp', data.ip)
          // commit('setAlias', data.alias)
          commit('setWatermarkPattern', data.watermarkPattern)
          commit('setUserId', data.id)
          commit('setIsOldUser', data.mobile ? data.oldUser : false)
          commit('setLocalDeploy', data.localDeploy)
          commit('setOrgName', data.orgName)
          commit('setCaseCooperation', data.caseCooperationSwitch)
          commit('setExtend', [{
            key: 'duyanAccountId',
            value: data.duyanAccountId
          }, {
            key: 'duyanToken',
            value: data.duyanToken
          }, {
            key: 'enableConnectedDuyan',
            value: data.enableConnectDuyan
          }, {
            key: 'duyanAdmin',
            value: data.duyanAdmin
          }, {
            key: 'duyanCfgToken',
            value: data.duyanCfgToken
          }, {
            key: 'changePasswd',
            value: data.changePasswd
          }, {
            key: 'visitSwitch',
            value: data.visitSwitch === 1
          }, {
            key: 'letterSwitch',
            value: data.letterSwitch === 1
          },
          {
            key: 'miniProgramsSwitch',
            value: data.miniProgramsSwitch === 1
          }, {
            key: 'noteDisplaySwitch',
            value: data.noteDisplaySwitch === 1
          }, {
            key: 'operationModifySwitch',
            value: data.operationModifySwitch === 1
          }, {
            key: 'caseInfoModifySwitch',
            value: data.caseInfoModifySwitch === 1
          }, {
            key: 'caseInfoModifyVisitorSwitch',
            value: data.caseInfoModifyVisitorSwitch === 1
          }, {
            key: 'autoEndSwitch',
            value: data.autoEndSwitch === 1
          }, {
            key: 'caseCooperationSwitch',
            value: data.caseCooperationSwitch
          }, {
            key: 'watermarkSwitch',
            value: data.watermarkSwitch === 1
          }, {
            key: 'copySwitch',
            value: data.copySwitch === 0
          }, {
            key: 'caseSyncSwitch',
            value: data.caseSyncSwitch === 1
          }, {
            key: 'operationHiddenSwitch',
            value: data.operationHiddenSwitch
          }, {
            key: 'showHiddenOperationSwitch',
            value: data.showHiddenOperationSwitch
          }, {
            key: 'isEarlyMediaRecEnabled',
            value: data.isEarlyMediaRecEnabled
          }, {
            key: 'depVisitSwitch',
            value: data.depVisitSwitch
          }, {
            key: 'fifoSwitch',
            value: data.fifoSwitch
          }, {
            key: 'smsSwitch',
            value: data.smsSwitch
          }, {
            key: 'robotSwitch',
            value: data.robotSwitch
          }, {
            key: 'isEarlyMediaRecorded', // 点呼
            value: data.isEarlyMediaRecorded
          }, {
            key: 'isCampaignEarlyMediaRecorded', // 计划
            value: data.isCampaignEarlyMediaRecorded
          }, {
            key: 'teamUrgeSwitch',
            value: data.teamUrgeSwitch === 1
          }, {
            key: 'phoneDisplaySwitch',
            value: !!data.phoneDisplaySwitch
          },
          {
            key: 'callerDisplaySwitch',
            value: !!data.callerDisplaySwitch
          },
          {
            key: 'noticeSwitch',
            value: !!data.noticeSwitch
          }, {
            key: 'operationVoiceSeatSwitch',
            value: !!data.operationVoiceSeatSwitch
          }, {
            key: 'operationVoiceTeamSwitch',
            value: !!data.operationVoiceTeamSwitch
          }, {
            key: 'visitContactSwitch',
            value: data.visitContactSwitch
          }, {
            key: 'strongPasswordSwitch',
            value: data.strongPasswordSwitch
          }, {
            key: 'mediationSwitch',
            value: data.mediationSwitch
          }, {
            key: 'fieldColorSwitch',
            value: data.fieldColorSwitch
          }, {
            key: 'ctrlSwitch',
            value: data.ctrlSwitch
          }, {
            key: 'globalCtrlSwitch',
            value: data.globalCtrlSwitch
          }, {
            key: 'globalCtrlType',
            value: data.globalCtrlType
          }, {
            key: 'lawsuitSwitch',
            value: data.lawsuitSwitch
          }, {
            key: 'isRobotEnabled',
            value: data.isRobotEnabled
          }, {
            key: 'dispatchRobotEnabled',
            value: data.dispatchRobotEnabled
          }, {
            key: 'workPhoneAgent',
            value: data.workPhoneAgent
          }, {
            key: 'endTypeSwitch',
            value: data.endTypeSwitch
          },
          {
            key: 'fifoToRobotEnabled',
            value: data.fifoToRobotEnabled
          },
          {
            key: 'writeOperationSwitch',
            value: data.writeOperationSwitch
          },
          {
            key: 'caseNoSwitch',
            value: data.caseNoSwitch
          },
          {
            key: 'operationConfigLinkSwitch',
            value: data.operationConfigLinkSwitch
          },
          {
            key: 'callInAutoAllotCaseSwitch',
            value: data.callInAutoAllotCaseSwitch
          },
          {
            key: 'callInSeatExclusiveLineSwitch',
            value: data.callInSeatExclusiveLineSwitch
          },
          {
            key: 'userQuerySwitch',
            value: data.userQuerySwitch
          },
          {
            key: 'caseViewingSwitch',
            value: data.caseViewingSwitch
          },
          {
            key: 'userContactsImportSwitch',
            value: data.userContactsImportSwitch
          },
          {
            key: 'userViewSwitch',
            value: data.userViewSwitch
          },
          {
            key: 'operationSafetySwitch',
            value: data.operationSafetySwitch
          }
          ])

          if (data.duyanToken) {
            commit('setIsAgent', true)
          } else {
            commit('setIsAgent', false)
          }

          setTagNavListInLocalstorage(null)
          resolve(data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 退出登录
    handleLogOut({ state, commit }) {
      return new Promise((resolve, reject) => {
        // logout(state.token).then(() => {
        commit('setToken', '')
        commit('setOrgId', '')
        commit('setIsAmc', '')
        commit('setDepInfo', '')
        commit('setOrgApikey', '')
        commit('setUserName', '')
        commit('setUserNo', '')
        commit('setIp', '')
        // commit('setAlias', '')
        commit('setWatermarkPattern', '')
        commit('setIsOldUser', '')
        setUserInLocalstorage(null)
        setTagNavListInLocalstorage(null)
        setLoginInLocalstorage(null)
        window.location.href = '/login'

        location.reload()

        // resolve()
        // }).catch(err => {
        //   reject(err)
        // })
        // 如果你的退出登录无需请求接口，则可以直接使用下面三行代码而无需使用logout调用接口
        // commit('setToken', '')
        // commit('setAccess', [])
        // resolve()
      })
    },
    /**
     * @param type 0 发送； 1 验证
     */
    getVerify({ state, commit, dispatch }, { mobile, type, isVoice = 0, validateCode, imageUuid }) {
      return new Promise((resolve, reject) => {
        const sendData = {
          mobile: mobile,
          type: type,
          isVoice,
          validateCode,
          imageUuid
        }

        getVerifyCode(sendData).then(res => {
          if (res.success) {
            const vcTimestamp = setInterval(() => {
              if (!isVoice) {
                if (state.vcDownCount > 0) {
                  commit('setTimeCountDown', true)
                  commit('setvcDownCount', state.vcDownCount - 1)
                } else {
                  commit('setvcDownCount', 60)
                  commit('setTimeCountDown', false)
                  clearInterval(vcTimestamp)
                }
              } else {
                if (state.voDownCount > 0) {
                  commit('setvoTimeCountDown', true)
                  commit('setvoDownCount', state.voDownCount - 1)
                } else {
                  commit('setvoDownCount', 60)
                  commit('setvoTimeCountDown', false)
                  clearInterval(vcTimestamp)
                }
              }
            }, 1000)
          } else {
            resolve(false)
          }
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    switchRole({ state, commit, dispatch }, { role }) {
      commit('setCurrentRole', role)
      setUserInLocalstorage({
        roleId: role.id,
        userId: state.userId,
        teamId: state.teamId
      })
      return dispatch('getUserInfo', { roleId: role.id, userId: state.userId, teamId: state.teamId })
    },
    // 获取机器人信息
    getRobotList({ commit }) {
      return new Promise((resolve, reject) => {
        getRobotList().then(res => {
          if (!res) {
            return false
          }
          const data = res.data
          commit('setRobot', data)
          resolve(data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 获取用户相关信息
    getUserInfo({ commit, dispatch }, { roleId, userId }) {
      return new Promise((resolve, reject) => {
        try {
          getInfo({ roleId, userId }).then(res => {
            if (res === 'noHandleLogOut') {
              return false
            }

            if (!res) {
              dispatch('handleLogOut')
              return false
            }

            const data = res.data
            if (data.user.localRecordStorage) {
              // 说明是录音本地存储商户
              getRecordAddrPrefix().then(res => {
                if (res) {
                  commit('setExtend', [{
                    key: 'recordPrefix',
                    value: res.data
                  }])
                }
              })
            }
            commit('setIsNotification', true)
            commit('setIsHelping', !(data.user.localDeploy || data.user.language))
            commit('setCurrentRole', data.role)
            commit('setCurrentUserInfo', data.user)
            commit('setFunctions', data.role.functions)

            commit('setOrgId', data.user.orgId)
            commit('setIsAmc', data.user.isAmc)
            commit('setDepInfo', {
              depId: data.user.depId,
              depName: data.user.depName
            })
            commit('setUserRoles', data.user.roles)
            commit('setMobile', data.user.mobile)
            commit('setUserName', data.user.name)
            commit('setUserNo', data.user.userNo)
            commit('setIp', data.user.ip)
            // commit('setAlias', data.user.alias)
            commit('setWatermarkPattern', data.user.watermarkPattern)
            commit('setUserId', data.user.id)
            commit('setOrgName', data.user.orgName)
            commit('setTeamId', data.user.teamId)
            commit('setLocalDeploy', data.user.localDeploy)
            commit('setRobotPlanTime', data.user.autoCampaignTime)
            commit('setFifoStartTime', data.user.callStartTime)
            commit('setFifoEndTime', data.user.callEndTime)
            commit('setDuyanFifoAgent', data.user.duyanFifoAgent)
            commit('setCaseCooperation', data.user.caseCooperationSwitch)
            commit('setColorFields', data.user.colorField)
            commit('setExtend', [{
              key: 'duyanAccountId',
              value: data.user.duyanAccountId
            }, {
              key: 'duyanToken',
              value: data.user.duyanToken
            }, {
              key: 'duyanAdmin',
              value: data.user.duyanAdmin
            }, {
              key: 'duyanCfgToken',
              value: data.user.duyanCfgToken
            }, {
              key: 'duyanOrgId',
              value: data.user.duyanOrgId
            }, {
              key: 'enableAutoSwitchPhone',
              value: data.user.enableAutoSwitchPhone
            }, {
              key: 'enableDesensitizationRule',
              value: data.user.enableDesensitizationRule
            }, {
              key: 'enableAutoUpdateOverdueDays',
              value: data.user.enableAutoUpdateOverdueDays
            },
            {
              key: 'enableConnectedDuyan',
              value: data.user.enableConnectDuyan
            }, {
              key: 'enableIpAccessAdmin',
              value: data.user.enableIpAccessAdmin
            }, {
              key: 'enableIpAccessAgent',
              value: data.user.enableIpAccessAgent
            }, {
              key: 'enableAccessDatabase',
              value: data.user.enableAccessDatabase
            }, {
              key: 'isFifoEnabled',
              value: data.user.isFifoEnabled
            }, {
              key: 'isTeamFifoEnabled',
              value: data.user.isFifoEnabled && (data.user.fifoType === 1 || data.user.fifoType === 3)
            }, {
              key: 'isPersonalFifoEnabled',
              value: data.user.isFifoEnabled && (data.user.fifoType === 2 || data.user.fifoType === 3)
            }, {
              key: 'isMessageEnabled',
              value: data.user.isMessageEnabled
            }, {
              key: 'isAutoTranscription',
              value: data.user.isAutoTranscription
            }, {
              key: 'visitSwitch',
              value: data.user.visitSwitch === 1
            }, {
              key: 'letterSwitch',
              value: data.user.letterSwitch === 1
            }, {
              key: 'miniProgramsSwitch',
              value: data.user.miniProgramsSwitch === 1
            }, {
              key: 'noteDisplaySwitch',
              value: data.user.noteDisplaySwitch === 1
            }, {
              key: 'operationModifySwitch',
              value: data.user.operationModifySwitch === 1
            }, {
              key: 'caseInfoModifySwitch',
              value: data.user.caseInfoModifySwitch === 1
            }, {
              key: 'caseInfoModifyVisitorSwitch',
              value: data.user.caseInfoModifyVisitorSwitch === 1
            }, {
              key: 'autoEndSwitch',
              value: data.user.autoEndSwitch === 1
            }, {
              key: 'caseCooperation',
              value: data.user.caseCooperationSwitch
            }, {
              key: 'watermarkSwitch',
              value: data.user.watermarkSwitch === 1
            }, {
              key: 'copySwitch',
              value: data.user.copySwitch === 0
            }, {
              key: 'caseSyncSwitch',
              value: data.user.caseSyncSwitch === 1
            }, {
              key: 'operationHiddenSwitch',
              value: data.user.operationHiddenSwitch
            }, {
              key: 'showHiddenOperationSwitch',
              value: data.user.showHiddenOperationSwitch
            }, {
              key: 'isEarlyMediaRecEnabled',
              value: data.user.isEarlyMediaRecEnabled
            }, {
              key: 'depVisitSwitch',
              value: data.user.depVisitSwitch
            }, {
              key: 'fifoSwitch',
              value: data.user.fifoSwitch
            }, {
              key: 'smsSwitch',
              value: data.user.smsSwitch
            }, {
              key: 'robotSwitch',
              value: data.user.robotSwitch
            }, {
              key: 'isEarlyMediaRecorded',
              value: data.user.isEarlyMediaRecorded
            }, {
              key: 'isCampaignEarlyMediaRecorded',
              value: data.user.isCampaignEarlyMediaRecorded
            }, {
              key: 'teamUrgeSwitch',
              value: data.user.teamUrgeSwitch === 1
            }, {
              key: 'phoneDisplaySwitch',
              value: !!data.user.phoneDisplaySwitch
            },
            {
              key: 'callerDisplaySwitch',
              value: !!data.user.callerDisplaySwitch
            },
            {
              key: 'noticeSwitch',
              value: !!data.user.noticeSwitch
            },
            {
              key: 'operationVoiceSeatSwitch',
              value: !!data.user.operationVoiceSeatSwitch
            }, {
              key: 'operationVoiceTeamSwitch',
              value: !!data.user.operationVoiceTeamSwitch
            }, {
              key: 'visitContactSwitch',
              value: data.user.visitContactSwitch
            }, {
              key: 'strongPasswordSwitch',
              value: data.user.strongPasswordSwitch
            }, {
              key: 'mediationSwitch',
              value: data.user.mediationSwitch
            }, {
              key: 'fieldColorSwitch',
              value: data.user.fieldColorSwitch
            }, {
              key: 'ctrlSwitch',
              value: data.user.ctrlSwitch
            }, {
              key: 'globalCtrlSwitch',
              value: data.user.globalCtrlSwitch
            }, {
              key: 'globalCtrlType',
              value: data.user.globalCtrlType
            }, {
              key: 'lawsuitSwitch',
              value: data.user.lawsuitSwitch
            }, {
              key: 'isRobotEnabled',
              value: data.user.isRobotEnabled
            }, {
              key: 'dispatchRobotEnabled',
              value: data.user.dispatchRobotEnabled
            }, {
              key: 'workPhoneAgent',
              value: data.user.workPhoneAgent
            }, {
              key: 'workPhoneSwitch',
              value: data.user.workPhoneSwitch
            }, {
              key: 'endTypeSwitch',
              value: data.user.endTypeSwitch
            },
            {
              key: 'fifoToRobotEnabled',
              value: data.user.fifoToRobotEnabled
            },
            {
              key: 'writeOperationSwitch',
              value: data.user.writeOperationSwitch
            },
            {
              key: 'caseNoSwitch',
              value: data.user.caseNoSwitch
            },
            {
              key: 'wellPhoneSwitch',
              value: data.user.wellPhoneSwitch
            },
            {
              key: 'operationConfigLinkSwitch',
              value: data.user.operationConfigLinkSwitch
            },
            {
              key: 'spdSwitch',
              value: data.user.spdSwitch
            },
            {
              key: 'jdSwitch',
              value: data.user.jdSwitch
            },
            {
              key: 'callInAutoAllotCaseSwitch',
              value: data.user.callInAutoAllotCaseSwitch
            },
            {
              key: 'callInSeatExclusiveLineSwitch',
              value: data.user.callInSeatExclusiveLineSwitch
            },
            {
              key: 'userQuerySwitch',
              value: data.user.userQuerySwitch
            },
            {
              key: 'caseViewingSwitch',
              value: data.user.caseViewingSwitch
            },
            {
              key: 'userContactsImportSwitch',
              value: data.user.userContactsImportSwitch
            },
            {
              key: 'userViewSwitch',
              value: data.user.userViewSwitch
            },
            {
              key: 'wpAddWechat',
              value: data.user.wpAddWechat
            },
            {
              key: 'wpCallPhone',
              value: data.user.wpCallPhone
            },
            {
              key: 'wpCustomSmsContent',
              value: data.user.wpCustomSmsContent
            },
            {
              key: 'wpCustomWechatApply',
              value: data.user.wpCustomWechatApply
            },
            {
              key: 'wpSendSms',
              value: data.user.wpSendSms
            },
            {
              key: 'localRecordStorage',
              value: data.user.localRecordStorage
            },
            {
              key: 'operationSafetySwitch',
              value: data.user.operationSafetySwitch
            }
            ])
            if (data.user.enableConnectDuyan && (data.user.isRobotEnabled || data.user.dispatchRobotEnabled)) {
              dispatch('getRobotList')
            }
            if (data.user.enableDesensitizationRule) {
              getDesensitizationRules().then(res => {
                commit('setDesensitizationRules', res.data.desensitizationRules)
                commit('setDesenTeamlist', res.data.teamIdList)
                commit('setDesenObject', {
                  desensitizationUserLevel: res.data.desensitizationUserLevel,
                  desensitizationAdminLevel: res.data.desensitizationAdminLevel
                })
              })
            }

            commit('setIsAgent', data.user.duyanToken)
            commit('setIsSupervisor', data.role.depType === 3 && data.role.type === 0)
            commit('setIsVisit', data.role.type === 9 && data.role.depType === 5)
            commit('setIsInspector', data.role.type === 7)

            let language = data.user.language || 'zh-CN'
            if (window.location.host === 'med.anmiai.com') {
              language = 'tj'
            }
            i18n.locale = language

            if (i18n.locale !== localRead('local')) {
              localStorage.setItem('local', i18n.locale)
              window.location.reload()
            }

            if (!window.VueMainInstance.adminRoutes) {
              // 临时路由
              // const menu = {
              //   "id": 1094,
              //   "status": 0,
              //   "createTime": 1703814902000,
              //   "updateTime": 1703814902000,
              //   "name": "明文审核",
              //   "path": "plain",
              //   "icon": "",
              //   "fileName": "dy-refund/auditPlain.vue",
              //   "parentId": 129,
              //   "type": "1",
              //   "sort": 7
              // }

              // const obj = findTarget(data.role.functions, 'children', 'id', 129)
              // if (obj.length) {
              //   const isExist = findTarget(data.role.functions, 'children', 'id', 1094).length
              //   if (!isExist) {
              //     obj[0].children.push(menu)
              //   }
              // }

              const { oldRouters, newRouters } = buildRouter(data.role.functions)
              window.VueMainInstance.$router.addRoutes(newRouters)

              if (data.role.type === 7) {
                for (const item of oldRouters) {
                  if (item.name === 'inspection') {
                    item.meta.hideInMenu = false
                  }
                }
              }
              commit('setMenuRouters', oldRouters)
            } else {
              // admin 暂时是写死的

              commit('setMenuRouters', window.VueMainInstance.adminRoutes)
            }

            resolve(data)
          }).catch(err => {
            dispatch('handleLogOut')
            reject(err)
          })
        } catch (error) {
          dispatch('handleLogOut')
          reject(error)
        }
      })
    },

    doSetGlobalCallPhone({ commit }, callNum) {
      commit('setGlobalCallPhone', callNum)
    },
    doCallOut({ commit }, callNum_) {
      let callNum = callNum_.split(',')[0]
      const showANI = callNum_.split(',')[1]

      commit('setGlobalCallPhone', callNum)
      console.log('======doCallOut==', callNum)
      // let regList = '-|-';
      // let regListEXP = new RegExp(regList, 'ig');
      // callNum = callNum.replace(regListEXP, '');
      // console.log('====================101000000000===', callNum)
      // 外拨加Tel，或出局需加0
      if (callNum.indexOf('sip:') === -1) {
        callNum = 'Tel:' + callNum
      }
      var makeCallType = '&makecallType=2'
      console.log('====================101000000000==callout=', callNum)
      // var callOutUrl = null
      // if (showANI.indexOf('02260358129') >= 0) {
      //   callOutUrl = 'http://127.0.0.1:9009/CallControl/MakeCall?webcallType=callBar&' + 'TARGETDN=' + callNum + makeCallType + '&showANI=' + showANI + '&type=1&callback=?'
      // } else {
      var callOutUrl = 'http://127.0.0.1:9009/CallControl/MakeCall?webcallType=callBar&' + 'TARGETDN=' + callNum + makeCallType + '&showANI=&type=1&callback=?'
      // }
      jQuery.ajax({
        type: 'get',
        url: callOutUrl,
        dataType: 'jsonp',
        jsonp: 'callback',
        beforeSend: function(XMLHttpRequest) {
          console.log('调用本地“外呼”接口开始')
          console.log('外呼地址：' + callOutUrl)
        },
        success: function(data) {
          // ucwindows.resultCallBack("doLogOut", data);
          console.log(data)
          console.log('调用本地“外呼”接口成功, Code: ' + data.Code)
        },
        complete: function(XMLHttpRequest, textStatus) {

        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          // 请求出错处理
          console.log('调用本地“外呼”接口失败 : \n' +
            'XMLHttpRequest:' + XMLHttpRequest +
            '\ntextStatus:' + textStatus +
            '\nerrorThrown:' + errorThrown
          )
        }
      })
      console.log('调用本地“外呼”接口结束')
    }

  }
}
