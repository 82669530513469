export default {
  m_report_agentMoneyBack: '调解员还款',
  m_report_dailyForm: '日调解进程',
  m_casectrl_batch: '智能调解',
  m_collection_collectionImport: '调解导入',
  m_casectrl_collection: '调解导入',
  m_casectrl_record: '调解记录',
  m_refund_record: '调解记录',
  m_assist: '协调管理',
  m_recordCtrl_record: '调解列表',
  m_collection_record: '调解列表',
  m_recordCtrl_collection: '调解导入',
  m_cooperation: '协调',
  m_collection_cooperation: '协调管理',
  m_record_import: '调解导入',
  m_record_export: '调解导出',
  m_report_mediation: '调解统计报表',
  selectAdjustFor: '选择待调整调解员',
  agent: '调解员',
  newAllocateForAgent: '新分配给调解员',
  selectIntCallResult: '机器人协调电话结果',
  selectCallResult: '机器人协调结果',
  refundTpInfo: '调解记录模版信息',
  selectReminder: '选择调解员',
  talkOverRecord: '调解记录',
  talkCase: '共债调解记录',
  telComAndTalk: '调解记录',
  refundSync: '调解情况同步',
  elTalkoverResult: '提交调解记录',
  unElTalkoverResult: '不填写调解记录',
  plSelectReminder: '请选择调解员',
  accelerationExportData: '调解记录导出数据',
  caseDistributed: '分配至调解员',
  refundInfo: '请填写对应调解记录',
  refundlist: '调解列表',
  refundNumber: '调解数',
  refundOperation: '调解操作',
  makeRefund: '填写调解记录',
  SummaryCollection: '请填写调解小结',
  talkOverNote: '调解小结',
  stopTalkOver: '停调',
  recoverTalkOver: '停调恢复',
  pause_one: '停调',
  createExportPlan: '创建调解记录导出计划',
  noDuyanRecord: '您尚未开通智能机器人协调功能1，请联系客户经理开通',
  caseOpedTotal: '调解中案件',
  caseDutyAgent: '案件负责调解员',
  caseDuty: '调解员',
  isAssistAgent: '案件是否分配给接听调解员',
  k_robotResult: '机器人协调结果',
  k_talkOverResult: '调解结果',
  k_agent: '调解员',
  k_answerAgent: '接听调解员',
  k_ownAgent: '所属调解员',
  k_selectAgenr: '请选择调解员',
  k_talkOverTime: '调解时间',
  k_talkOverType: '调解类型',
  k_AdjustAgent: '分配调解员',
  k_currentTalkOver: '当前在调',
  k_refundMark: '调解备注',
  k_intTalkoverResult: '智能协调结果',
  k_recordTimeRange: '调解记录时间范围',
  k_collectionTime: '上次机器人协调时间',
  k_collectionRecord: '机器人协调客户标签',
  k_robotRecord: '机器人协调客户标签',
  k_agentName: '调解员姓名',
  k_createType: '调解记录来源',
  l_noAgent: '请选择至少一个调解员',
  l_adjustedAgent: '待调整调解员必选',
  l_newAdjustAgent: '新分配调解员必选',
  l_requireCall: '需要先提交调解记录才能再拨号',
  l_requireRefund: '需要先提交调解记录才能操作',
  l_noRequireForm: '调解记录没有填写完成，请检查必填项',
  l_noSelectAgent: '调解员必须选择',
  l_selectRecordTimeRange: '请选择调解记录时间范围',
  reminder: '调解',
  reminderRecord: '调解记录',
  collection: '调解',
  coordinate: '协调',
  urging: '在调',
  followUp: '跟进',
  m_delegation: '申请管理',
  m_casectrl_company: '申请方',
  m_casectrl_product: '申请产品',
  selectCaseDelt: '请选择申请公司',
  selectcaseProduct: '请选择申请产品',
  inputOutBatchNum: '请输入申请方批次号',
  caseExpireTime: '预计申请到期日',
  l_baileBatchLimit: '最多选择20个申请方批次',
  l_outSerialNoAndDelt: '案件编号，需选择申请方',
  l_noCaseOrg: '申请方不能为空',
  l_noBailde: '申请方不能为空',
  l_noChoseOrg: '申请方必须选择',
  l_requireCaseProduct: '申请方产品必选',
  l_requireCaseBatchAmount: '申请方批次号最多选择20个',
  l_requireCaseBatch: '申请方批次号不能为空',
  l_requireCaseOrg: '申请方必选',
  l_baileTimeLimit: '申请截止日期必须大于开始时间',
  l_caseStartTime: '申请开始日期必填',
  l_caseExpireTime: '申请到期日必填',
  l_sameCaseproduct: '选择批次的申请产品必须保持一致',
  l_sameCaseBatch: '选择批次的申请批次必须保持一致',
  l_sameCaseDelt: '选择批次的申请公司必须保持一致',
  k_debtFollowAmount: '被申请人跟进次数',
  k_debtFollowTime: '被申请人最近跟进时间',
  k_baileStartTime: '申请开始日期',
  k_baileEndTime: '申请截止日期',
  K_entrustedOrg: '申请方',
  k_baileBatchNo: '申请方批次号',
  k_baile: '申请方',
  k_baileProduct: '申请方产品',
  k_caseProduct: '申请产品',
  k_outBatchNum: '申请方批次号',
  k_entrustStartTime: '申请开始时间',
  k_entrustEndTime: '申请截止时间',
  K_outSetialMoney: '申请金额',
  K_outSerialNo: '案件编号',
  k_debtorTel: '被申请人电话',
  k_debtorMobile: '被申请人手机号',
  k_deltProduct: '申请产品',
  k_outBatchNo: '申请批次',
  k_delt: '申请方',
  untouchableDebtor: '被申请人失联',
  selectBaile: '选择申请方',
  selectBaileProduct: '选择申请方产品',
  baileBatch: '申请方批次',
  makeBaileBatch: '填写申请方批次',
  selectBaileBatch: '选择申请方批次',
  baileStartTime: '申请开始日期',
  selectBaileStartTime: '选择申请开始日期',
  baileEndTime: '申请截止日期',
  selectBaileEndTime: '选择申请截止日期',
  baile: '申请',
  baileCompany: '申请方',
  client: '申请方',
  obligor: '被申请人',
  effectiveContacts: '约调数',
  selectTalkOverResult: '请选择调解结果',
  callReminder: '电调',
}
