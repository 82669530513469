import { get, post, put, postForm, del } from '@/libs/api.request'

const filterSearchFields = (data) => {
  data['searchKeyParam'] = {}
  for (const key in data) {
    if (key.indexOf('search_key') !== -1) {
      const json = {}
      json[key] = data[key].split(',')
      data['searchKeyParam'] = { ...data['searchKeyParam'], ...json }
      delete data[key]
    }
    if (typeof (data[key]) === 'string' && ['names', 'mobiles', 'contactMobiles', 'outSerialNos', 'idCards', 'nameList', 'outSerialTempList'].includes(key)) {
      data[key] = data[key].trim().replace(/\n/g, ',').split(',')
    }
  }
  return data
}

const handleOverdueDays = (data) => {
  if (data.enableAutoUpdateOverdueDays) {
    if (data.overdueDate) {
      delete data.overdueDaysStart
      delete data.overdueDaysEnd
    } else if (data.overdueDaysStart || data.overdueDaysEnd || (data.overdueDaysStart === 0) || (data.overdueDaysEnd === 0)) {
      let overdueDaysStart = (data.overdueDaysStart || data.overdueDaysStart === 0) ? (new Date().getTime()) - (data.overdueDaysStart * 24 * 3600 * 1000) : ((data.overdueDaysEnd || data.overdueDaysEnd === 0) ? new Date().getTime() : null)

      if (overdueDaysStart) {
        overdueDaysStart = new Date(new Date(overdueDaysStart).toLocaleDateString()).getTime() + ',' + (new Date(new Date(overdueDaysStart).toLocaleDateString()).getTime() + 24 * 3600 * 1000 - 1)
      }

      let overdueDaysEnd = (data.overdueDaysEnd || data.overdueDaysEnd === 0) ? (new Date().getTime()) - (data.overdueDaysEnd * 24 * 3600 * 1000) : null

      if (overdueDaysEnd) {
        overdueDaysEnd = new Date(new Date(overdueDaysEnd).toLocaleDateString()).getTime() + ',' + (new Date(new Date(overdueDaysEnd).toLocaleDateString()).getTime() + 24 * 3600 * 1000 - 1)
      }

      if (overdueDaysStart && !overdueDaysEnd) {
        const overdueDaysStarts = overdueDaysStart.split(',')
        data['overdueDate'] = `,${overdueDaysStarts[1]}`
      } else {
        const overdueDaysEnds = overdueDaysEnd.split(',')
        const overdueDaysStarts = overdueDaysStart.split(',')
        data['overdueDate'] = `${overdueDaysEnds[0]},${overdueDaysStarts[1]}`
      }

      delete data.overdueDaysStart
      delete data.overdueDaysEnd
    }
  }

  delete data.enableAutoUpdateOverdueDays

  return data
}

const handleMoreSearch = (data) => {
  if (data.searchs) {
    if (/^\d{11}$/.test(data.searchs)) {
      data.contactMobiles = [data.searchs]
    } else if(data.searchs.length === 18){
      data.idCards = [data.searchs]
    } else {
      data.names = [data.searchs]
    }
    delete data.searchs
  }

  return data
}

export const getCaseAccountAge = (age) => {
  if (!age && age !== 0) return ''
  if (age <= 30) return 'M1'
  else if (age > 30 && age <= 60) return 'M2'
  else if (age > 60 && age <= 90) return 'M3'
  else if (age > 90 && age <= 120) return 'M4'
  else if (age > 120 && age <= 150) return 'M5'
  else if (age > 150 && age <= 180) return 'M6'
  else return 'M6+'
}

export const getCases = (searchData) => {
  searchData = handleOverdueDays(searchData)
  searchData = filterSearchFields(searchData)
  searchData = handleMoreSearch(searchData)

  if (searchData.action === 0 || searchData.action === 10) {
    const keys = Object.keys(searchData)
    let forbidRequest = true
    for (const item of keys) {
      if (item === 'searchKeyParam') {
        const searchKeys = Object.keys(searchData.searchKeyParam)
        if (searchKeys.length) {
          forbidRequest = false
        }
      }

      if (!['action', 'sortRule', 'orderBy', 'limit', 'page', 'searchKeyParam'].includes(item)) {
        forbidRequest = false
      }
    }

    if (forbidRequest) {
      return new Promise((resolve, reject) => {
        window.VueMainInstance.$Message.error('请至少选择一个筛选项后再查询！')
        resolve([])
      })
    }
  }

  return post('/v1/cases/list', searchData, { noSucTip: true })
}

export const caseInfo = (data) => {
  return get('v1/cases/info', data)
}

export const rightsCaseInfo = (data) => {
  return get('v1/cases/infoByRole', data)
}


export const getCallPhoneCaseList = (data) => {
  return get('v1/cases/getCallPhoneCaseList', data)
}



export const getBatchedCases = (searchData) => {
  return get('v1/cases/allot/cases', searchData)
}

export const casesPreAssgin = (data) => {
  return post('/v1/cases/allot', data)
}

export const casesPreAssginSkip = (data) => {
  return post('/v1/cases/allot/check', data)
}

export const casesAssgin = (searchData) => {
  searchData = handleOverdueDays(searchData)
  searchData = filterSearchFields(searchData)
  searchData = handleMoreSearch(searchData)

  return post('/v1/cases/allot/submit', searchData)
}

export const changeCasesInfo = (searchData) => {
  searchData = handleOverdueDays(searchData)
  searchData = filterSearchFields(searchData)
  searchData = handleMoreSearch(searchData)

  return post('/v1/cases/status', searchData)
}

export const reassginAgentCase = (searchData) => {
  searchData = handleOverdueDays(searchData)
  searchData = filterSearchFields(searchData)
  searchData = handleMoreSearch(searchData)

  return post('/v1/cases/allot/adjust', searchData)
}

export const delCase = (searchData) => {
  searchData = handleOverdueDays(searchData)
  searchData = filterSearchFields(searchData)
  searchData = handleMoreSearch(searchData)

  return put('/v1/cases/delete', searchData, { noSucTip: true })
}

export const getGarbage = (searchData) => {
  return get('/v1/cases/garbageList', searchData)
}

export const recoverCase = (data) => {
  return put('/v1/cases/recoverCases', data)
}

export const getInvalidCase = (searchData) => {
  return get('/v1/cases/invalidList', searchData)
}

export const getStatisCase = (searchData) => {
  searchData = handleOverdueDays(searchData)
  searchData = filterSearchFields(searchData)
  searchData = handleMoreSearch(searchData)

  if (searchData.action === 0 || searchData.action === 10) {
    const keys = Object.keys(searchData)
    let forbidRequest = true
    for (const item of keys) {
      if (item === 'searchKeyParam') {
        const searchKeys = Object.keys(searchData.searchKeyParam)
        if (searchKeys.length) {
          forbidRequest = false
        }
      }

      if (!['action', 'sortRule', 'orderBy', 'limit', 'page', 'searchKeyParam'].includes(item)) {
        forbidRequest = false
      }
    }

    if (forbidRequest) {
      return new Promise((resolve, reject) => {
        resolve({})
      })
    }
  }

  return post('/v1/cases/statisCases', searchData, { noSucTip: true })
}

export const getInbCase = (data) => {
  return get('/v1/cases/caseInfoCallIn', data)
  // return get('/v1/cases/infoByMobile', data)
}

export const exportCases = (searchData) => {
  searchData = handleOverdueDays(searchData)
  searchData = handleMoreSearch(searchData)
  searchData = handleMoreSearch(searchData)
  return post('/v1/cases/addExportTask', searchData, { noSucTip: true })
}

export const exportList = (searchData) => {
  return get('/v1/cases/listTask', searchData)
}
// 催收小结分页
export const getCaseList = (data) => {
  return get('/v1/cases/note/list', data)
}
export const saveCaseNote = ({ id, caseId, content }) => {
  return post('/v1/cases/note/save', {
    id,
    caseId,
    content
  })
}
// 共债案件列表
export const conjointList = (data) => {
  return get('/v1/cases/conjointList', data)
}
// 预分配
export const preview = searchData => {
  searchData = handleOverdueDays(searchData)
  searchData = filterSearchFields(searchData)
  searchData = handleMoreSearch(searchData)

  return post('/v1/cases/allot/preview', searchData, { noSucTip: true })
}
// 案件分配提交
// export const caseSubmit = data => {
//   return post('/v1/cases/allot/submit', data)
// }
export const allotList = data => {
  return get('/v1/task/allotList', data)
}
export const taskList = data => {
  return get('/v1/task/allotView', data)
}
// 案件重新分配
export const allotReset = searchData => {
  searchData = handleOverdueDays(searchData)
  searchData = filterSearchFields(searchData)
  searchData = handleMoreSearch(searchData)

  return post('/v1/cases/allotReset', searchData)
}
export const caseUpdateList = data => {
  return get('/v1/task/caseUpdateList', data)
}

export const caseUploadFile = data => {
  return postForm('/v1/cases/uploadFile', data, { noSucTip: true })
}

export const getCaseFileList = ({ id, ...searchData }) => {
  return get(`/v1/cases/listCaseFiles/${id}`, searchData)
}

export const delCaseFile = (id, fileId) => {
  return put(`/v1/cases/delCaseFile/${id}/${fileId}`)
}

export const getInvalidReason = data => {
  return get('/v1/cases/getInvalidReason', data)
}

export const doAllotStrategy = (searchData) => {
  searchData = handleOverdueDays(searchData)
  searchData = filterSearchFields(searchData)
  searchData = handleMoreSearch(searchData)
  return post(`/v1/allot/strategy/doAllot`, searchData)
}

export const caseColor = (data) => {
  // data = filterSearchFields(data)
  return put('/v1/cases/caseColoring', data)
}
// callUuid
export const caseInfoUuid = data => {
  return get(`/v1/cases/info/${data.callUuid}`)
}

export const ignorePlan = data => {
  return post(`/v1/cases/ignorePlan`, data)
}

// 案件状态修改申请
export const getApplyList = (data) => {
  data = filterSearchFields(data)
  return post('/v1/caseApply/list', data, { noSucTip: true })
}

export const addApply = (data) => {
  return post('/v1/caseApply/add', data, { noSucTip: true })
}

export const changeApplyStatus = (data) => {
  return post('/v1/caseApply/changeStatus', data, { noSucTip: true })
}

export const checkCaseApply = (data) => {
  return get('/v1/caseApply/check', data)
}

export const cancelTask = ({ id }) => {
  return put(`/v1/downloadTask/cancelTask/${id}`, { noSucTip: true })
}

export const getCaselog = (data) => {
  return post('/v1/cases/log', data, { noSucTip: true })
}

export const exportLog = (data) => {
  data = filterSearchFields(data)
  return post('/v1/cases/log/export', data, { noSucTip: true })
}

export const setCustomView = (data) => {
  return post('/v1/cases/setCustomView', data)
}

export const getCustomView = (data) => {
  return post('/v1/cases/getCustomView', data, { noSucTip: true })
}

// 管理员自定义视图设置
export const setAdminCustomView = (data) => {
  return post('/v1/cases/adminCustomView', data)
}

// 管理员自定义视图查询
export const getAdminCustomView = (data) => {
  return get('/v1/cases/queryAdminCustomView', data, { noSucTip: true })
}

export const updateCaseInfo = (data) => {
  return put('/v1/cases/updateCaseInfo', data)
}

export const caseTagList = (data) => {
  return get('/v1/cases/caseTagList', data)
}

export const updateCaseTag = (data) => {
  return put('/v1/cases/updateCaseTag', data)
}

export const addCaseTag = (data) => {
  data = handleOverdueDays(data)
  data = filterSearchFields(data)
  data = handleMoreSearch(data)

  return post('/v1/cases/addCaseTag', data)
}

export const addTag = (data) => {
  return post('/v1/cases/addTag', data)
}

export const applyAdd = (data) => {
  return post('/v1/caseCooperation/applyAdd', data, { noSucTip: true })
}

export const listByCooperation = (data) => {
  data = filterSearchFields(data)
  return post('/v1/caseCooperation/listByCooperatorId', data, { noSucTip: true })
}

export const statisCaseCooperation = (data) => {
  data = filterSearchFields(data)
  return post('/v1/caseCooperation/statisCaseCooperation', data, { noSucTip: true })
}

export const cooperationList = (data) => {
  data = filterSearchFields(data)
  return post('/v1/caseCooperation/cooperationList', data, { noSucTip: true })
}

export const cooperationApplyList = (data) => {
  data = filterSearchFields(data)
  return post('/v1/caseCooperation/cooperationApplyList', data, { noSucTip: true })
}

export const applyhandle = (data) => {
  return post('/v1/caseCooperation/applyHandle', data)
}

export const cooperationApplyStatistics = (data) => {
  data = filterSearchFields(data)
  return post('/v1/caseCooperation/cooperationApplyStatistics', data, { noSucTip: true })
}

export const cooperationAudit = (data) => {
  return post('/v1/caseCooperation/audit', data, { noSucTip: true })
}

export const cooperationStatistics = (data) => {
  data = filterSearchFields(data)
  return post('/v1/caseCooperation/cooperationStatistics', data, { noSucTip: true })
}

export const allotCooperation = (data) => {
  data = handleOverdueDays(data)
  data = filterSearchFields(data)
  data = handleMoreSearch(data)
  return post('/v1/caseCooperation/allot', data, { noSucTip: true })
}

export const finishCooperation = (data) => {
  return post('/v1/caseCooperation/finish', data)
}

export const cooperationAwaitList = (data) => {
  data = filterSearchFields(data)
  return post('/v1/caseCooperation/cooperationAwaitList', data, { noSucTip: true })
}

export const deleteNote = (id) => {
  return del(`/v1/cases/note/${id}`)
}

export const commentSave = (data) => {
  return post('/v1/cases/note/commentSave', data)
}

export const commentList = (data) => {
  return get('/v1/cases/note/commentList', data)
}

export const checkStatusAndUserId = (data) => {
  return get('/v1/cases/checkStatusAndUserId', data, { noErrTip: true, hasErrorRes: true })
}

export const listUsingMysql = (data) => {
  return post('/v1/case/repayment/listUsingMysql', data, { noSucTip: true })
}

export const changeOperationState = (searchData) => {
  searchData = handleOverdueDays(searchData)
  searchData = filterSearchFields(searchData)
  searchData = handleMoreSearch(searchData)

  return post('/v1/cases/changeOperationState', searchData)
}

export const agentList = (data) => {
  return get('/v1/dep/agentList', data)
}

export const caseCtrlInfo = (data) => {
  return get('/v1/caseCtrl/caseCtrlInfo', data)
}

export const checkCaseCtrl = (data) => {
  return get('/v1/caseCtrl/checkCaseCtrl', data)
}

export const updateCustomCaseRel = (data) => {
  return post('/v1/caseCtrl/updateCustomCaseRel', data)
}

export const caseWarning = (data) => {
  return post(`/v1/cases/warning?caseId=${data.caseId}&waring=${data.warning}`)
}

export const caseLawsuit = (data) => {
  return post('/v1/cases/lawsuit', data)
}

// 案件全局管控详情信息
export const getGlobalControl = data => {
  return get('/v1/globalCtrl/globalCtrlInfo', data)
}

// 发送短信检查案件是否达成全局管控限制
export const getMessageReturn = data => {
  return get('/v1/globalCtrl/checkGlobalCtrlSms', data)
}

// 点呼检查案件是否达到全局管控限
export const getCaseReturn = data => {
  return get('/v1/globalCtrl/checkGlobalCtrlTel', data)
}
// 民生银行查看本人接口
export const getRealName = data => {
  return post('/v1/cases/realNameCardNo', data, { noSucTip: true, noErrTip: true, hasErrorRes: true })
}

// 呼入自动分案
export const autoAllot = (data) => {
  return post(`/v1/cases/allot/auto/${ data.caseId }/${ data.mobile }`)
}

// 明文申请
export const applyPlain = (data) => {
  return post('/v1/plaintext', data)
}

// 案件明文详情
export const getPlainDetail = (data) => {
  return get('/v1/plaintext/detail', data)
}

// 明文审核列表
export const getAuditPlain = (data) => {
  return get('/v1/plaintext', data)
}

// 明文审核
export const auditPlain = (data) => {
  return put('/v1/plaintext/audit', data)
}

// 案件资料列表
export const getFileList = (data) => {
  return post('/v1/cases/file/list', data, { noSucTip: true })
}

// 案件资料统计
export const getFileCount = (data) => {
  return post('/v1/cases/file/count', data, { noSucTip: true })
}

// 案件资料删除
export const delFiles = (data) => {
  return del('/v1/cases/file/batch', data)
}

// 分片上传
export const fragUploadConfig = (data) => {
  return post('/v1/cases/oss/config', data, { noSucTip: true })
}
export const fragUpload = (data) => {
  return post('/v1/cases/oss/upload', data)
}
export const chunkCheck = (data) => {
  return post('/v1/cases/chunk/check', data)
}
export const chunkUpload = (data, config) => {
  return post('/v1/cases/chunk/upload', data, { noSucTip: true, retryCount: 3 })
}
export const chunkMerge = (data) => {
  return post('/v1/cases/chunk/merge', data)
}
// 批量完成协催
export const finishBatch = (data) => {
  return post('/v1/caseCooperation/finish/batch', data)
}
