import { get, post, put, del } from '@/libs/api.request'

export const getBatch = (searchData) => {
  return get('/v1/batch/innerList', searchData)
}

export const getInnerCaseBatch = (searchData) => {
  return get('/v1/batch/innerCaseList', searchData)
}

export const getInnerNotCaseBatch = (searchData) => {
  return get('/v1/batch/innerNotCaseList', searchData)
}

export const getInnerCaseUserBatch = (searchData) => {
  return get('/v1/batch/innerCaseUserList', searchData)
}

// 委案方批次号
export const getOutBatch = (searchData) => {
  return get('/v1/batch/outList', searchData)
}

export const addBatch = (delt) => {
  return post('/v1/batch/innerList', delt)
}

export const updateBatch = (delt) => {
  return put('/v1/batch/innerList', delt)
}

export const delBatch = ({ id }) => {
  return del(`/v1/batch/innerList/${id}`)
}
