import { get, post, put, postForm, del } from '@/libs/api.request'

// 启用/禁用管控池
export const enableCaseCtrl = ({ caseCtrlId, enable }) => {
  return post('/v1/caseCtrl/enableCaseCtrl?caseCtrlId=' + caseCtrlId + '&enable=' + enable, null)
}
// 添加管控池
export const addCaseCtrl = (data) => {
  return post('/v1/caseCtrl/addCaseCtrl', data)
}
// 管控池列表
export const caseList = (data) => {
  return get('/v1/caseCtrl/list', data)
}

// GET /v1/caseCtrl/caseCtrlInfo 案件管控信息
export const getCaseInfo = data => {
  return get('/v1/caseCtrl/caseCtrlInfo', data)
}
// GET /v1/caseCtrl/detail 管控池明细信息
export const getDetail = data => {
  return get('/v1/caseCtrl/detail', data)
}
// 删除自定义管控明细
export const delCtrlCustom = id => {
  return post('/v1/caseCtrl/delCtrlCustom?customCaseCtrlId=' + id)
}
// 管控池排序
export const sortCaseCtrl = data => {
  return post('/v1/caseCtrl/sortCaseCtrl', data)
}
// 更新管控池
export const updateCaseCtrl = data => {
  return post('/v1/caseCtrl/updateCaseCtrl', data)
}
// 设置取消案件自定义管控的完成情况
export const updateCustomCaseRel = data => {
  return post('/v1/caseCtrl/updateCustomCaseRel', data)
}

// 设置全局管控开关
export const setSwitch = data => {
  return put(`/v1/org/globalSwitch/${data.isOpen}?globalCtrlType=${data.globalCtrlType}`)
}
// 关闭全局管控开关
export const closeSwitch = (data) => {
  return put(`/v1/org/globalSwitch/${data.isOpen}`)
}

// 查询全局管控列表
export const getCtrList = data => {
  return get('/v1/globalCtrl/list', data)
}

// 新增全局管控
export const addCtrl = data => {
  return post('/v1/globalCtrl/add', data)
}

// 更新全局管控
export const updataCtrl = data => {
  return post('/v1/globalCtrl/update', data)
}
