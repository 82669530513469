
<div v-if="userExtend.noticeSwitch" class="notice">
  <div class="title">
    <Icon type="iconfont icon-gonggao" />
  </div>
  <div class="scroll_box">
    <scrollAnnouncement>
      {{ announcementTiltle }}
      {{ announcementContent }}
    </scrollAnnouncement>
  </div>
</div>
