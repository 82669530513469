export default {
  // 菜单
  m_home: '控制台',
  m_mycase: '案件',
  m_doingCase: '进行中案件',
  m_notStartCase: '未开始案件',
  m_closedCase: '已结案案件',
  m_invalidCase: '已失效案件',
  m_applicateRecord: '申请记录',
  m_agentFifo: '预测式外呼',
  m_report_taskList: '任务列表',
  m_describe: '调用说明',
  m_case_import: '案件导入',
  m_get_results: '获取导入结果',
  m_update_status: '更新案件状态',
  m_get_status_results: '获取更新结果',
  m_case_update: '案件更新',
  m_casectrl_caseInfo: '案件资料',
  m_get_case_results: '获取更新结果',
  m_getTemplate: '获取模板参数',
  m_messageEnable: '短信管理',

  // menu form db
  m_call_workphone: '工作手机',
  m_casectrl: '案件管理',
  m_casectrl_cases: '案件列表',
  m_casectrl_transfer: '案件流转',
  m_casectrl_file: '文件管理',
  m_casectrl_update: '案件更新',
  m_casectrl_import: '案件导入',
  m_casectrl_template: '模板管理',
  m_casectrl_contactImport: '联系人管理',
  m_casectrl_oplog: '操作记录',
  m_casectrl_refundImport: '还款管理',
  m_casectrl_paymentList: '还款列表',
  m_refund: '审批管理',
  m_refund_review: '还款审核',
  m_refund_deduction: '划扣审核',
  m_refund_controller: '还款管理',
  m_refund_import: '还款导入',
  m_refund_tasklist: '任务列表',
  m_back_code: '返回值',
  m_import_result: '导入结果',
  m_collection_reduction: '减免管理',
  m_collection_reductionStag: '减免分期',
  m_refund_contactfix: '信修管理',
  m_refund_plane: '任务列表',
  m_system: '账号管理',
  m_system_mechanism: '子账户管理',
  m_system_organ: '组织架构',
  m_system_account: '员工列表',
  m_robotDetail: '外呼详情',
  m_system_permission: '角色管理',
  m_assist_robot: '机器人计划',
  m_assist_fifo: '预测式外呼',
  m_fifoDetail: '预测式外呼详情',
  m_smsDetail: '短信详情',
  m_assist_robotSite: '机器人话术',
  m_assist_robotInfo: '机器人信息',
  m_assist_sms: '短信群发',
  m_assist_smstpl: '短信模板',
  m_assist_wpSms: '短信群发',
  m_assist_wpConfig: '话术配置',
  m_assist_smsRecord: '短信记录',
  m_assist_textRecord: '发送异常记录',
  m_call_callCenter: '呼叫中心',
  m_setting_operation: '系统设置',
  m_templateCtrl_template: '模板管理',
  m_collection_refundImport: '还款导入',
  m_controller_paymentList: '还款列表',
  m_org: '公司管理',
  m_collection_paymentList: '还款划扣',
  m_casectrl_batchManagement: '批次号管理',
  m_casectrl_caseStrategy: '分案策略',
  m_collection_assistApply: '协办管理',
  m_inspection_inspection: '质检设置',
  m_call_inspection: '质检管理',
  m_collection_auditStatus: '状态审核',
  m_inter: '外访记录',
  m_record_result: '导入结果',
  m_outsource_outCases: '委外案件管理',
  m_outsource_orgManage: '委外机构管理',
  m_customCount: '客户统计',
  m_analyse: '活跃分析',
  m_callCenter: '呼叫中心统计',
  m_businessCount: '业务统计',
  m_cusList: '客户列表',
  m_palm: '工作手机',
  m_letter_dataConfig: '数据配置',
  m_letter_letterList: '函件列表',
  m_letter_sendLetters: '发函文件',
  m_call_smsCenter: '短信设置',
  m_notice: '运营管理',
  m_contacts_import: '联系人导入',
  m_contacts_result: '获取导入结果',
  m_feedback: '问题反馈',
  m_test: '白名单测试',
  m_inspection_inspector: '质检员设置',
  m_case_export: '案件导出',
  m_strategy_strategyList: '策略管理',
  m_strategy_planTemplate: '执行模板',
  m_mediate_template: '模板管理',
  m_mediate_seals: '印章管理',
  m_mediate_mediator: '调解员设置',
  m_mediate_electron: '电子签审批',
  m_mediate_text: '电子签文书',
  m_sealAudit: '印章审批',
  m_audit: '业务审批',
  m_lawsuit_audit: '诉讼审批',
  m_lawsuit_update: '诉讼案件更新',
  m_lawsuit_cases: '诉讼案件',
  m_lawsuit_administration: '诉讼文件管理',
  m_report_performance: '业绩统计',
  m_report_workCoverage: '工作覆盖',
  m_report_ledgerStatistics: '台账统计',
  m_report_callStatistics: '点呼统计',
  m_report_robotStatistics: '机器人统计',
  m_report_preOutsideStatistics: '预测式外呼统计',
  m_report_trialStatistics: '提成统计',
  m_storage_storage: '文件存储',
  m_assist_plan: '机器人计划',
  m_assist_site: '机器人话术',
  m_callLogs: '通话记录',
  m_report_mediation: '催收统计报表',

  m_system_journal: '账号日志',
  m_casectrl_refundPlan: '还款计划导入',
  m_update_contact: '联系人状态更新',
  m_get_result: '获取更新任务结果',
  m_report_smsExpendRecord: '短信消费统计',
  m_mediate_videoRecord: '在线视频记录',
  m_visit_visitAudit: '外访列表',
  m_visit_config: '数据配置',
  m_app: 'App版本号',
  m_casectrl_spdBank: '浦发银行',
  m_mediate: '调解',
  m_mediate_cases: '案件列表',
  m_mediate_filing: '移交立案审批',
  m_collection_plain: '明文审核',
  // casectrl: '数据管理',
  // casectrl_cases: '案件列表',
  // casectrl_transfer: '案件流转',
  // casectrl_batch: '批次管理',
  // casectrl_file: '文件管理',
  // casectrl_update: '案件更新',
  // casectrl_import: '案件导入',
  // casectrl_template: '模板管理',
  // casectrl_contactImport: '导入通讯录',
  // casectrl_oplog: '操作记录',
  // refund: '催收管理',
  // refund_controller: '还款管理',
  // refund_import: '还款导入',
  // refund_reduction: '减免管理',
  // refund_record: '催记管理',
  // refund_contactfix: '信修审核',
  // system: '系统管理',
  // system_mechanism: '子账户管理',
  // system_department: '分公司管理',
  // system_account: '员工管理',
  // system_permission: '权限管理',
  // delegation: '委托方管理',
  // delegation_company: '委托方公司管理',
  // delegation_product: '产品管理',
  // assist: '协催管理',
  // assist_robot: '机器人计划',
  // assist_sms: '短信群发',
  // assist_smstpl: '短信模板',

  // 系统
  closeOthers: '关闭其他',
  closeAll: '关闭所有',
  switchRole: '切换角色',
  signOut: '退出登录',
  chgPwd: '修改密码',
  addRobotPlan: '添加机器人计划',
  checkPlanDetail: '查看计划详情',
  checkDetail: '查看详情',
  operation: '操作',
  detail: '详情',
  startPlan: '开始计划',
  pausePlan: '暂停计划',
  cancelPlan: '取消计划',
  cancelTask: '取消任务',
  deletePlan: '删除计划',
  addSmsPlan: '添加短信计划',
  smsplan: '短信群发计划',
  addSmsTpl: '新增短信模板',
  addSmsSign: '新增签名',
  selectOrg: '选择筛选机构',
  addAccount: '添加账号',
  addStaff: '添加员工',
  editAccount: '修改账号',
  defaultPassword: '默认密码',
  submit: '提交',
  cancel: '取消',
  addTeam: '添加小组',
  addBranchOrg: '添加分公司',
  editTeam: '修改小组',
  editBranchOrg: '修改分公司',
  addSubAccount: '新增子账户',
  addRoles: '添加角色',
  editRoles: '修改角色',
  authority: '权限',
  authorityTeamName: '权限组名称',
  authorityDesc: '权限描述',
  rolesName: '角色名称',
  edit: '修改',
  add: '添加',
  moveOut: '移出',
  anmi: '安米智能',
  smsVars: '短信变量',
  sir: '先生/女士您好',
  you: '您在',
  arrearage: '的欠款已经逾期',
  le: '了',
  varsUnavailable: '变量无法使用',
  success: '成功',
  fail: '失败',
  distributionBatch: '分配批次',
  caseBatchMa: '案件批次管理',
  createCaseMode: '创建批次方式',
  createPlan: '创建计划',
  failStartPlan: '计划启动失败',
  confirmStartPlan: '是否确定启动计划',
  allocateFile: '分配文件',
  selectFile: '选择分配文件',
  fillCustomBatch: '填写自定义批次号',
  selectBatchStatus: '请选择批次状态',
  selectTemplate: '请选择模板',
  resultCount: '结果统计',
  listCaseNumber: '案件数',
  piece: '件',
  listMoney: '列表金额',
  yuan: '元',
  repayment: '还款总额',
  repayHousehold: '还款次数',
  door: '次',
  allFilterResult: '全部筛选结果',
  adjustFor: '调整对象',
  newAllocateTeam: '新分配小组',
  group: '小组',
  addGroup: '添加小组',
  selectGroup: '选择小组',
  editGroup: '修改小组',
  selectTeam: '选择小组',
  newAllocateFor: '新分配给',
  adjustReason: '调整原因',
  reason: '原因',
  redistribute: '重新分配',
  redistributeCase: '案件重新分配',
  selectCaseStatus: '请选择案件状态',
  selectTag: '请选择客户标签',
  selectBatchNo: '请选择内部批次号',
  selectBranchDelt: '请选择分公司',
  plSelectTeam: '请选择小组',
  importAddressList: '联系人导入',
  selectImportTp: '选择导入模板',
  download: '下载',
  errorData: '错误数据',
  uploadExcelFile: '上传Excel文件',
  uploadFiles: '上传文件或图片',
  uploadTpData: '上传模板数据文件',
  submitData: '提交数据',
  uploadResult: '上传结果',
  fileTypeError: '文件类型错误',
  file: '文件',
  updateCase: '更新案件',
  importCase: '导入案件',
  select: '选择',
  update: '更新',
  import: '导入',
  template: '模板',
  selectTp: '选择模板',
  operationType: '操作类型',
  new: '新增',
  create: '新建',
  selectTpName: '请输入模板名称',
  selectEntrustedOrg: '选择委托公司',
  sortByDragModule: '拖拉模块可进行排序',
  read: '预览',
  baileInfo: '案件基本信息',
  baileUpdateInfo: '案件更新信息',
  visitInfo: '外访模板信息',
  data: '数据',
  selectTpType: '选择模板类型',
  selectTpAttribute: '选择模板属性',
  dataLoading: '加载数据中',
  caseAllocation: '案件分配',
  intAllocation: '智能分配',
  caseOperation: '案件操作',
  caseNumber: '案件数',
  caseTo: '分案至',
  caseToTeam: '分案至组',
  all: '全部',
  hasDeleted: '已作废',
  selectAgent: '选择坐席',
  caseWeight: '分案权重',
  caseType: '分案类型',
  predistribution: '预分配',
  selectProType: '请选择产品类型',
  addressList: '联系人',
  caseInfo: '案件信息',
  caseManInfo: '案人信息',
  createdRecord: '创建记录',
  reliefInfo: '减免信息',
  reliefApply: '减免审核',
  reliefRecord: '减免记录',
  repaymentInfo: '还款信息',
  debtCase: '共债案件',
  caseInfoTp: '案件信息模板',
  distributeCase: '共债分配',
  contact: '联系人',
  selectContact: '选择联系人',
  relationship: '关系',
  family: '家人',
  friend: '朋友',
  relative: '亲戚',
  classmate: '同学',
  caseContact: '案件联系人',
  promisedRepayment: '承诺还款金额',
  promisedRepayTime: '承诺还款时间',
  reliefMent: '减免金额',
  reliefRemark: '减免备注',
  inputReliefRemark: '输入减免备注',
  nextFollowUpTime: '下次跟进时间',
  today: '今天',
  tomorrow: '明天',
  dayAfterTomorrow: '后天',
  contactNumber: '联系电话',
  inputRelationship: '填写与债务人关系',
  topCantact: '置顶联系人',
  invalidMark: '标记无效',
  markWarning: '标记警告',
  Warning: '警告',
  selectMainPhoneLine: '选择主叫线路',
  chosePhoneLine: '选择电话线路',
  randomTel: '随机号码',
  terTel: '属地号码',
  dial: '拨打',
  inputMent: '填写金额',
  product: '产品',
  none: '无',
  caseInvalid: '案件失效',
  infoApplition: '信修申请',
  infoRecord: '信修记录',
  applitionCount: '申请条数',
  applitionRemark: '申请备注',
  applyStatus: '申请修改案件状态',
  doApplyStatus: '申请案件状态',
  weightSort: '权重排序',
  check: '查看',
  newCase: '最新案件',
  productVolume: '产品量',
  prdVolumePer: '产品数量占比',
  batchPass: '批量通过',
  batchReject: '批量拒绝',
  batchDestroy: '批量作废',
  batchDelete: '批量删除',
  destroy: '作废',
  confirm: '确定',
  pass: '通过',
  refuse: '拒绝',
  inputRepayInfo: '还款信息导入',
  selectorExport: '选项导出',
  audit: '审核',
  waitAudit: '待审核',
  auditSuccess: '审核通过',
  auditFail: '审核未通过',
  remarkInfo: '备注信息',
  selectApplyStatus: '请选择申请状态',
  ApplyStatus: '状态',
  newBatchexport: '创建批量导出任务',
  newExportTask: '创建导出任务',
  telRecord: '电话录音',
  headOrg: '总公司',
  subAccount: '子账户',
  updateSuccess: '上传成功',
  warehouseSuccess: '入库成功',
  warehouseFail: '入库失败',
  updating: '更新中',
  updateCompleted: '更新完成',
  updateFail: '更新失败',
  exportData: '导出数据',
  open: '开',
  close: '关',
  resetPwdAndLogin: '请重置密码后登陆',
  resetPwd: '重置密码',
  selectRoles: '请选择登录角色',
  mobileAccount: '手机账号',
  companyAccount: '公司账号',
  selectBatchNum: '选择内部批次号',
  addressListSelect: '联系人选择',
  address: '地址',
  outVisit: '外访',
  callMe: '拨打本人',
  startWay: '开始方式',
  manualExecute: '手动执行',
  timingExecute: '定时执行',
  earliestStart: '最早开始',
  earliestStartTime: '最早开始时间',
  latestEnd: '最晚结束',
  latestEndTime: '最晚结束时间',
  executivepriority: '优先级',
  select_executivepriority: '请选择优先级',
  outboundTimeLimit:
    '机器人外呼时间为 09:00 - 12:00, 14:00 - 20:00 若启动中的计划未在工作时间内完成，次日将继续执行',
  chooseWords: '话术选择',
  speechName: '话术名称',
  compaignFinished: '计划完成短信通知',
  smsTomobile: '短信发送至电话',
  sendObject: '发送对象',
  callObject: '外呼对象',
  containAudioFile: '包含录音文件',
  selectTime: '选择时间',
  customPwd: '自定义密码',
  initPwd: '初始密码',
  caseImport: '案件导入',
  caseUpdate: '案件更新',
  updateRecord: '更新记录',
  importRecord: '导入记录',
  makeBatchNo: '生成内部批次号',
  selectMakeWay: '选择生成方式',
  caseDistribute: '待分配',
  teamCase: '分配至组',
  systemTp: '系统模板',
  refundRecord: '还款记录',
  applyRepairInfo: '申请信修',
  deleteAccount: '删除员工',
  quitAccountCase: '离职员工案件',
  isTop: '是否置顶',
  assignRoles: '分配角色',
  selectAcount: '选择员工',
  delete: '删除',
  setAgent: '设置坐席',
  setManage: '设置管理员',
  manage: '管理员',
  normal: '正常',
  trialCase: '试案',
  cooperating: '合作中',
  pauseCooperation: '暂停合作',
  endCooperation: '终止合作',
  consumerFinance: '消费金融',
  creditCard: '信用卡',
  mortgageLoan: '抵押贷',
  commercialLoan: '商贷',
  carLoan: '车贷',
  profee: '物业费',
  caseBaseInfoTp: '案件导入模板',
  caseUpadateTp: '案件更新模板',
  repayMentTp: '还款模板',
  repayVouche: '还款凭证',
  deductionVouche: '划扣凭证',
  addressListTp: '联系人模板',
  export: '导出',
  publicTp: '公共模板',
  customTp: '自定义模板',
  markRed: '标红',
  markBlue: '标蓝',
  normal_caseDue: '正常-案件到期',
  abnormal_recellAdvance: '非正常-甲方提前撤回',
  abnormal_specialCase: '非正常-争议/投诉/特殊案件',
  leaveCase: '留案',
  closeCase: '结案',
  Doing: '处理中',
  caseAdjust: '批量调整',
  unallocated: '未分配',
  allocated: '已分配',
  pause: '暂停',
  dividedCase: '分案完成',
  unhandle: '未处理',
  untouched: '未联系上',
  promisedRepay: '承诺还款',
  willRepay: '意愿还款',
  negotiating: '谈判中',
  effectInform: '已转告',
  claimOfRepayment: '声称已还款',
  demandRelief: '要求减免',
  denialFund: '否认用款',
  nonSelf: '非本人',
  InvalidInform: '已结清',
  claimToComplain: '声称要投诉',
  noCall: '未拨打',
  normalCall: '正常接通',
  callException: '呼叫异常',
  shutdown: '关机',
  busyLine: '忙线中',
  noAnswer: '无人接听',
  answerHangup: '接听挂断',
  closeDown: '停机',
  spaceNumber: '空号',
  electrograph: '传真机',
  callInLimit: '呼入限制',
  onlineCheck: '网查',
  retireCase: '退案',
  unknown: '未知',
  unedit: '未填写',
  installment: '分期还款',
  disputeCase: '争议案件',
  searchCase: '查询案件',
  allocateByCount: '按数量平均分案',
  allocateByMent: '按金额平均分案',
  allocateByProportion: '比例分案',
  unAllocate: '暂不分配',
  autoCreate: '自动创建',
  manualCreate: '手动创建',
  allocatedBatch: '已分配批次',
  created: '创建',
  division: '分案',
  back: '上一步',
  recover: '恢复',
  caseRecover: '案件恢复',
  generalUpdate: '更新',
  repay: '还款',
  applying: '申请中',
  notStarted: '未开始',
  inProgress: '进行中',
  paused: '已暂停',
  cancelled: '已取消',
  finished: '已完成',
  search: '搜索',
  clear: '清除',
  editInfo: '修改信息',
  searchCriteria: '搜索条件',
  createCaseExportPlan: '创建案件导出任务',
  addSmsTask: '添加短信群发任务',
  taskName: '任务名称',
  selectSmsTpl: '选择短信模板',
  smsTpContent: '短信模板内容',
  caseDetail: '案件详情',
  repaired: '修复完成',
  noDuyanTip: '该功能未开启，如需开启请联系管理员',
  noDuyanInspect: '您尚未开启呼叫中心功能，请联系管理员或客户经理开启',
  clickPlay: '点击播放',
  error: '错误',
  addCustomTp: '自定义字段',
  casesLabel: '案件标签',
  operationError: '操作失败',
  recycleBin: '回收站',
  deleteTh: '彻底删除',
  remove: '移除',
  outcome: '呼叫结果',
  noSameTeam: '小组名称不能重复',
  noSameBranch: '分公司名称不能重复',
  inBound: '呼入',
  outBound: '呼出',
  importing: '导入中',
  importFail: '导入失败',
  imported: '导入完成',
  unimported: '导入失败',
  assigning: '处理中',
  unAssign: '未分配',
  emptyTrash: '清空回收站',
  caseExportTpl: '案件导出模版',
  createExportTask: '创建批量导出任务',
  searchsTip: '多个用换行符隔开(≤1000行)',
  supplyInfo: '补充资料',
  caseData: '案件数据',
  casingCount: '进行中案件统计',
  casingRepayCount: '进行中案件还款统计',
  repaiedCaseTotal: '已还款案件',
  undoCaseTotal: '未处理案件',
  caseTotal: '案件总数量',
  caseAmount: '案件总金额',
  retrunBack: '返回',
  addStrategy: '新增策略',
  editStrategy: '修改策略',
  ruleMake: '制定规则',
  selectStrategy: '分案策略',
  ascByImport: '按案件导入顺序正序排列',
  descByImport: '按案件导入顺序倒序排列',
  selectApplyResult: '请选择审核结果',
  exportTime: '导出时间',
  exportWay: '导出方式',
  waiting: '等待中',
  exportFailed: '导出失败',
  outOfDate: '已失效',
  caseInTeam: '组内案件',
  exportBySearch: '搜索结果导出',
  exportCriteria: '导出条件',
  assistApply: '协办申请',
  assistDeal: '协办处理',
  auditStatus: '案件状态审核',
  examineApply: '申请审核',
  reply: '回复',
  enclosure: '附件',
  inspector: '质检人员设置',
  intelligence: '智能质检设置',
  userReplyRecord: '用户回复记录',
  selectedCases: '已选案件数',
  connectExport: '关联导出',
  judgeWay: '判断方式',
  custiomViews: '列表视图',
  smsTip: '短信提醒',
  emailTip: '邮件提醒',

  // 表格字段
  k_visitingAddress: '外访地址',
  k_addressType: '地址类型',
  k_visitStatus: '外访状态',
  k_visitSchedule: '外访排期',
  k_visitor: '外访员',
  k_coInterviewer: '协访员',
  k_visitNote: '外访备注',
  k_visitDate: '外访日期',
  k_visitOutcome: '外访结果',
  k_smsCount: '发送短信数',
  k_robotText: '机器人对话',
  k_failCount: '处理失败数',
  k_batchNo: '内部批次',
  k_batchNum: '内部批次号',
  k_debtor: '姓名',
  k_callResult: '电话结果',
  k_callTime: '呼叫时间',
  k_lastCallTime: '最后呼叫时间',
  k_callDuration: '通话时长',
  k_description: '描述',
  k_planName: '计划名称',
  k_planStatus: '计划状态',
  k_selectPlanStatus: '请选择计划状态',
  k_outboundDetail: '进度',
  k_excutiveTeam: '执行小组',
  k_robotLogic: '机器人话术',
  k_planCreatedTime: '计划创建时间',
  k_send: '发送',
  k_sentStatus: '发送状态',
  k_sending: '发送中',
  k_sent: '发送完毕',
  k_caseWeight: '分配权重',
  k_surplusCases: '剩余案件',
  k_assignWay: '分案类型',
  k_assignObject: '分案对象',
  k_inCludeCase: '是否包含共债案件分配',
  k_count: '总量',
  k_successCount: '成功数',
  k_smsTplTit: '短信模板',
  k_smsContent: '短信内容',
  k_createdTime: '创建时间',
  k_productReason: '案件理由',
  k_sentTime: '发送时间',
  k_sendBy: '发送人',
  k_receiveStatus: '接收状态',
  k_accountId: '员工编号',
  k_name: '姓名',
  k_inputName: '填写姓名',
  k_mobile: '手机号',
  k_selectCase: '选择案件',
  k_effectCase: '有效案件',
  k_newPassword: '请输入6-16位新密码',
  k_confirmPassword: '请再次确认新密码',
  k_affiliatedOrg: '所属公司',
  k_selectOrg: '请选择所属公司',
  k_branchOrg: '所属分公司',
  k_branchCom: '分公司',
  k_supervisor: '小组',
  k_isSupervisor: '小组长',
  k_selectBranchOrg: '请选择所属分公司',
  k_roles: '角色',
  k_selectRoles: '请选择角色',
  k_status: '状态',
  K_resetPass: '密码重置',
  k_operation: '操作',
  k_edit: '修改',
  k_teamName: '小组名称',
  k_branchName: '分公司名称',
  k_orgName: '公司名称',
  k_businessNo: '业务编号',
  k_subAccountName: '子账户名称',
  k_tpName: '模板名称',
  k_smsNote: '短信签名',
  k_tpContent: '模板内容',
  k_tpExample: '模板样例',
  k_auditStatus: '审核状态',
  k_auditing: '审核中',
  k_auditInstruction: '审核说明',
  k_batchNumber: '批次号',
  k_accountAge: '账龄',
  k_caseAccountAge: '案件账龄',
  k_casesCount: '案件总数',
  k_casesAmount: '金额',
  k_uploadTime: '上传时间',
  k_updateBy: '录入人',
  k_caseStatus: '批次状态',
  k_rank: '排名',
  k_Amount: '已还款金额',
  k_repayAmount: '待还款金额',
  k_selectRobotLogic: '请选择机器人话术',
  k_selectRepairStatus: '请选择审核状态',
  K_caseTag: '标签',
  K_outSerialStatus: '案件状态',
  k_overdueTime: '逾期日期',
  k_overdueDays: '逾期天数',
  k_startTime: '开始时间',
  k_recordTime: '记录时间',
  k_recordContent: '内容',
  k_idCard: '身份证号',
  k_callType: '拨打结果',
  k_callingType: '呼叫类型',
  k_distributeBranchOrg: '分配分公司',
  k_distributeTeam: '分配小组',
  k_fileName: '文件名称',
  k_fileNm: '文件名',
  k_totalContact: '联系人总量',
  k_importResult: '导入结果',
  k_updateResult: '更新结果',
  k_importBy: '导入人',
  k_importByTime: '导入时间',
  k_totalCase: '案件总量',
  k_successAmt: '成功导入数',
  k_failAmt: '失败导入数',
  k_updateTotalCase: '更新总量',
  k_updateSuccessAmt: '成功更新量',
  k_updateFailAmt: '失败更新量',
  k_totalAmount: '案件总金额',
  k_importTime: '导入日期',
  k_operator: '操作人',
  k_arrearsIdcard: '欠款人身份证',
  k_operationTime: '操作时间',
  k_TpType: '模板类型',
  k_TpVars: '模板字段',
  k_remark: '备注',
  k_tpAttribute: '模板属性',
  k_updateTime: '更新时间',
  k_editTime: '修改时间',
  k_team: '小组',
  k_affiliatedMechanism: '所属机构',
  k_qcRemark: '质检评语',
  k_newCaseNumber: '新增案件数',
  k_newCaseAmont: '新增案件金额',
  k_cooperationStatus: '合作状态',
  k_productName: '产品名称',
  k_productType: '产品类型',
  k_applicant: '申请人',
  k_approver: '审批人',
  k_approveTime: '审批时间',
  k_contact: '联系人',
  k_contactTel: '联系人电话',
  k_relationship: '关系',
  k_talkOverTel: '联系人电话',
  k_dialResult: '拨打结果',
  k_PTPment: 'PTP金额',
  k_PTPtime: 'PTP时间',
  k_nextFollowUpTime: '下次跟进时间',
  k_applyReliefMent: '申请减免金额',
  k_applyResult: '申请结果',
  k_applyTime: '申请时间',
  k_applyExplain: '申请说明',
  k_applyOpinion: '审核意见',
  k_applyDelayTime: '申请留案日期',
  k_repayment: '还款总额',
  k_deductionment: '划扣总额',
  k_repayTime: '还款日期',
  k_repayWay: '还款方式',
  k_repayType: '还款类型',
  k_repayBy: '还款人',
  k_repayCarNo: '还款卡号',
  k_deductionCarNo: '划扣卡号',
  k_deductionPrincipal: '划扣本金',
  k_deductionInterest: '划扣利息',
  k_sort: '排序',
  k_dayAdd: '单日新增',
  k_applitionRemark: '申请备注',
  k_repayTimeRange: '还款日期范围',
  k_retireCase: '实际退案日期',
  k_reliefMent: '减免金额',
  k_afterReliefMent: '减免后金额',
  k_todayPaymentFollowUp: '今日还款待跟进',
  k_group: '所属小组',
  k_jobNo: '工号',
  k_affiliatedGroup: '所属小组',
  k_affiliatedTeam: '团队',
  k_selectTeam: '请选择团队',
  k_droupName: '小组名称',
  k_followedUpToday: '今日待跟进',
  k_followedUpTomorrow: '明日待跟进',
  k_followedUpFixSuccess: '修复成功待跟进',
  k_markDialResult: '标记电话结果',
  k_repairProgress: '修复进度',
  k_repairResult: '修复结果',
  k_planState: '任务状态',
  k_fee: '费用',
  k_repairStatus: '修复状态',
  k_fixStatus: '信修状态',
  k_expirTime: '下载失效时间',
  k_arrearsAmount: '欠款金额',
  K_lastCallTime: '上次通话时间',
  k_followCount: '跟进次数',
  k_lastFollowTime: '上次跟进时间',
  k_dyagent: '坐席',
  k_manage: '管理员',
  k_Manage: '管理者',
  k_depType: '层级',
  k_createPlanTime: '任务创建时间',
  k_caseContactMobile: '案件联系人手机号',
  k_ringDuration: '响铃时长',
  k_createdBy: '任务创建人',
  k_creatBy: '创建人',
  k_mainPhone: '主叫号码',
  k_recentFollowTime: '最近跟进时间',
  k_customLabel: '客户标签',
  k_exemptionVoucher: '减免凭证',
  k_uploadBy: '上传人',
  k_uploading: '上传中',
  k_divisionTime: '分案日期',
  k_divisionNewTime: '分案时间',
  k_endCaseTime: '结案时间',
  k_divisionType: '分案类型',
  k_hasReliefMent: '已减免金额',
  k_strategyName: '策略名称',
  k_strategyType: '策略类型',
  k_strategyRule: '策略规则',
  k_strategy: '策略',
  k_minNumber: '最小值',
  k_maxNumber: '最大值',
  k_unAllocateCount: '暂不分配数',
  k_time: '时间',
  k_investigation: '考察对象',
  k_groupName: '组别',
  k_surveyDataType: '考察数据类型',
  k_caseCount: '案件数量',
  k_caseAmount: '案件金额',
  k_repaymentCount: '当日还款次数',
  k_repaymentAmount: '当日还款金额',
  k_monthlyRepaymentCount: '当月还款次数',
  k_monthlyRepaymentAmount: '当月还款金额',
  k_taskName: '任务名称',
  k_failedReason: '失败原因',
  k_reason: '原因',
  k_templateId: '模板编号',
  k_companyId: '公司编号',
  k_productId: '产品编号',
  k_taskType: '任务类型',
  k_assistContent: '协办内容',
  k_leaderOption: '组长意见',
  k_cusServiceReply: '客服回复',
  k_replier: '回复人',
  k_replyContent: '回复内容',
  k_inspectPercent: '质检比例',
  k_errorReason: '错误原因',
  k_applyStatus: '申请案件状态',
  k_replyTime: '回复时间',
  k_contactMobile: '联系人手机号',
  k_vertifyCode: '验证码',
  k_conjointType: '共债判断方式',
  k_outboundedCase: '已外呼案件数',
  k_sendedNumber: '已发送数',
  k_type: '类型',
  k_percent: '完成度',
  k_callRoles: '呼叫中心角色',
  k_addressStatus: '地址状态',
  k_outVisitCount: '外访次数',
  k_outVisitStatus: '外访状态',
  k_resource: '来源',
  k_repaymentPrincipal: '还款本金',
  k_repaymentInterest: '还款利息',

  // 问题确认
  q_startPlan: '确定要开始计划吗',
  q_pausePlan: '确定要暂停计划吗',
  q_cancelPlan: '确定要取消计划吗',
  q_resetPass: '你确定要重置该用户密码',

  // 限制条件
  l_noAccountId: '用户编号不能为空',
  l_noStaffId: '员工编号不能为空',
  l_noName: '姓名不能为空',
  l_noMobile: '手机号不能为空',
  l_noOrg: '所属公司不能为空',
  l_noBranchOrg: '所属分公司不能为空',
  l_noRoles: '角色不能为空',
  l_formError: '表单内容填写有误',
  l_noTeamName: '小组名称不能为空',
  l_nzoBusinessNo: '业务编号不能为空',
  l_noSubAccountName: '子账户名称不能为空',
  l_noThorityName: '角色名称不能为空',
  l_noThorityDepType: '公司层级不能为空',
  l_noThorityDesc: '权限描述不能为空',
  l_noThority: '请设置权限',
  l_setThirityError: '设置权限错误',
  l_smsNoteLimit: '输入您希望发送的内容，请勿另行添加签名',
  l_smsNotes: '请输入短信模板内容(已含短信签名，请勿另行添加)',
  l_requireSmsNote: '短信签名必选',
  l_selectUploadFile: '选择上传文件',
  l_noCustomBatchNo: '自定义批次号不能为空',
  l_noBatch: '请选择至少一个批次',
  l_noManage: '请选择至少一个管理员',
  l_operationReason: '操作原因必填',
  l_retireCase: '退案原因必填',
  l_requireTeam: '小组必选',
  l_requireAdjustReason: '调整原因必填',
  l_caseNumberLimit: '批量处理案件不能超过5000件，请细化搜索条件',
  l_noCase: '请选择至少一条案件',
  l_mustUpload: '必须上传',
  l_selectImportTp: '选择导入的模板',
  l_errorFileType: '不是EXCEL文件，请选择后缀为.xlsx或者.xls的EXCEL文件。',
  l_noModuleName: '模板名称不能为空',
  l_noChoseAgent: '坐席必须选择',
  l_noOrgName: '公司名称不能为空',
  l_choseCooperationStatus: '请选择合作状态',
  l_noProductName: '产品名称不能为空',
  l_noProductType: '产品类型不能为空',
  l_noSmsTplTit: '未添加短信模板',
  l_noCantactInfo: '请填写联系人信息',
  l_requireMobile: '手机号必须填写',
  l_requirePlanName: '任务名称必须填写',
  l_requireName: '姓名必须填写',
  l_requireRelationship: '关系必须填写',
  l_ctiConnectFail: 'CTI未连接成功',
  l_requirePhone: '本次通话结束后才能操作',
  l_noTerLine: '无属地化线路',
  l_changeLineError: '呼叫失败',
  l_caseInvalid: '该案件已经失效，请关闭案件！',
  l_noPrdVolumePer: '暂无产品数量占比数据',
  l_checkDestroyCase: '确认对选择的案件进行作废处理！',
  l_requireAuditRemark: '审核未通过原因必填',
  l_searchCriteria: '已添加，则必须添加',
  l_option: '选项',
  l_noExportableData: '无可导出数据',
  l_noLatestEendTime: '最晚结束时间必选',
  l_endBigerStart: '最晚结束时间需要大于开始时间',
  l_noCompaignName: '计划名称不能为空',
  l_requireBatchNo: '内部批次号必选',
  l_writeBatchNo: '内部批次号不能为空',
  l_requireMakeWay: '请选择生成方式',
  l_requireOutBatchNo: '外部批次必选',
  l_requireTp: '请选择模版',
  l_requireEarliest: '开始时间必选',
  l_requireCHoosWord: '话术选择必选',
  l_noSmsTp: '短信模板必选',
  l_repayment: '为了避免影响您的个人征信请尽快还款',
  l_noGroup: '小组必须选择',
  l_noGroupName: '小组名称不能为空',
  l_selectFixStatus: '请选择信修状态',
  l_noCallLog: '无通话记录',
  l_errorDownload: '下载链接已失效',
  l_noCustom: '请确认添加字段后再提交',
  l_sieldsRepetition: '字段重复',
  l_stringLength: '300字符以内',
  l_deleteRole: '你确定要移除该用户的角色吗？',
  l_selectOne: '请至少选择其中一项',
  l_moreAgent: '超出已购买坐席数量，请先购买坐席',
  l_wrongMobile: '请输入正确的手机号',
  l_noPassword: '密码不能为空',
  l_differentPwd: '两次密码输入不一致',
  l_noCode: '验证码不能为空',

  excel: 'Excel',
  'upload-excel': '上传excel',
  'export-excel': '导出excel',
  tools_methods_page: '工具函数',
  drag_list_page: '拖拽列表',
  i18n_page: '多语言',
  modalTitle: '模态框题目',
  content: '这是模态框内容',
  buttonText: '显示模态框',
  'i18n-tip': '注：仅此页做了多语言，其他页面没有在多语言包中添加语言内容',
  error_store_page: '错误收集',
  error_logger_page: '错误日志',
  query: '带参路由',
  params: '动态路由',

  // 文案替换
  m_report_agentMoneyBack: '催员还款',
  m_report_dailyForm: '日催收进程',
  m_casectrl_batch: '智能协催',
  m_collection_collectionImport: '催记导入',
  m_casectrl_collection: '催记导入',
  m_casectrl_record: '催记管理',
  m_refund_record: '催记管理',
  m_assist: '协催管理',
  m_recordCtrl_record: '催记列表',
  m_collection_record: '催记列表',
  m_recordCtrl_collection: '催记导入',
  m_cooperation: '协催',
  m_collection_cooperation: '协催管理',
  m_record_import: '催记导入',
  m_record_export: '催记导出',
  selectAdjustFor: '选择待调整催员',
  agent: '催员',
  newAllocateForAgent: '新分配给催员',
  selectIntCallResult: '机器人协催电话结果',
  selectCallResult: '机器人协催结果',
  refundTpInfo: '催记模版信息',
  selectReminder: '选择催员',
  talkOverRecord: '催收记录',
  talkCase: '共债催记',
  telComAndTalk: '催记记录',
  refundSync: '催收情况同步',
  elTalkoverResult: '提交催记',
  unElTalkoverResult: '不填写催记',
  plSelectReminder: '请选择催收员',
  accelerationExportData: '催记导出数据',
  caseDistributed: '分配至催员',
  refundInfo: '请填写对应催记信息',
  refundlist: '催记列表',
  refundNumber: '催记数',
  refundOperation: '催记操作',
  makeRefund: '填写催记',
  SummaryCollection: '请填写催收小结',
  talkOverNote: '催收小结',
  stopTalkOver: '停催',
  recoverTalkOver: '停催恢复',
  pause_one: '停催',
  createExportPlan: '创建催记导出计划',
  noDuyanRecord: '您尚未开通智能机器人协催功能，请联系客户经理开通',
  caseOpedTotal: '催收中案件',
  caseDutyAgent: '案件负责催员',
  caseDuty: '催员',
  isAssistAgent: '案件是否分配给接听催员',
  k_robotResult: '机器人协催结果',
  k_talkOverResult: '催收结果',
  k_agent: '催员',
  k_answerAgent: '接听催员',
  k_ownAgent: '所属催员',
  k_selectAgenr: '请选择催员',
  k_talkOverTime: '催收时间',
  k_talkOverType: '催收类型',
  k_AdjustAgent: '分配催员',
  k_currentTalkOver: '当前在催',
  k_refundMark: '催收备注',
  k_intTalkoverResult: '智能协催结果',
  k_recordTimeRange: '催记时间范围',
  k_collectionTime: '上次机器人协催时间',
  k_collectionRecord: '机器人协催客户标签',
  k_robotRecord: '机器人协催客户标签',
  k_agentName: '催员姓名',
  k_createType: '催记来源',
  l_noAgent: '请选择至少一个催员',
  l_adjustedAgent: '待调整催员必选',
  l_newAdjustAgent: '新分配催员必选',
  l_requireCall: '需要先提交催记才能再拨号',
  l_requireRefund: '需要先提交催记才能操作',
  l_noRequireForm: '催记没有填写完成，请检查必填项',
  l_noSelectAgent: '催员必须选择',
  l_selectRecordTimeRange: '请选择催记时间范围',
  reminder: '催记',
  reminderRecord: '催记',
  collection: '催收',
  coordinate: '协催',
  urging: '在催',
  followUp: '跟催',
  m_delegation: '委案管理',
  m_casectrl_company: '委案公司',
  m_casectrl_product: '委案产品',
  selectCaseDelt: '请选择委案公司',
  selectcaseProduct: '请选择委案产品',
  inputOutBatchNum: '请输入委案方批次号',
  caseExpireTime: '预计委案到期日',
  l_baileBatchLimit: '最多选择20个委案方批次',
  l_outSerialNoAndDelt: '委案编号，需选择委案公司',
  l_noCaseOrg: '委案公司不能为空',
  l_noBailde: '委案方不能为空',
  l_noChoseOrg: '机构必须选择',
  l_requireCaseProduct: '委案方产品必选',
  l_requireCaseBatchAmount: '委案方批次号最多选择20个',
  l_requireCaseBatch: '委案方批次号不能为空',
  l_requireCaseOrg: '委案公司必选',
  l_baileTimeLimit: '委案截止日期必须大于开始时间',
  l_caseStartTime: '委案开始日期必填',
  l_caseExpireTime: '委案到期日必填',
  l_sameCaseproduct: '选择批次的委案产品必须保持一致',
  l_sameCaseBatch: '选择批次的委案批次必须保持一致',
  l_sameCaseDelt: '选择批次的委案公司必须保持一致',
  k_debtFollowAmount: '债务人跟进次数',
  k_debtFollowTime: '债务人最近跟进时间',
  k_baileStartTime: '委案开始日期',
  k_baileEndTime: '委案截止日期',
  K_entrustedOrg: '委托公司',
  k_baileBatchNo: '委案方批次号',
  k_baile: '委案方',
  k_baileProduct: '委案方产品',
  k_caseProduct: '委案产品',
  k_outBatchNum: '委案方批次号',
  k_entrustStartTime: '委案开始时间',
  k_entrustEndTime: '委案截止时间',
  K_outSetialMoney: '委案金额',
  K_outSerialNo: '委案编号',
  k_debtorTel: '债务人电话',
  k_debtorMobile: '债务人手机号',
  k_outBatchNo: '委案批次',
  k_delt: '委案公司',
  untouchableDebtor: '债务人失联',
  selectBaile: '选择委案方',
  selectBaileProduct: '选择委案方产品',
  baileBatch: '委案方批次',
  makeBaileBatch: '填写委案方批次',
  selectBaileBatch: '选择委案方批次',
  baileStartTime: '委案开始日期',
  selectBaileStartTime: '选择委案开始日期',
  baileEndTime: '委案截止日期',
  selectBaileEndTime: '选择委案截止日期',
  baile: '委案',
  baileCompany: '委案公司',
  k_deltProduct: '委案产品',
  client: '委案方',
  obligor: '债务人',
  effectiveContacts: '有效联络数',
  selectTalkOverResult: '请选择催收结果',
  callReminder: '电催',
}
