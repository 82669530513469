import { get, post, put } from '@/libs/api.request'

const handleOverdueDays = (searchData) => {
  if (searchData.enableAutoUpdateOverdueDays) {
    if (searchData.overdueDate) {
      delete searchData.overdueDaysStart
      delete searchData.overdueDaysEnd
    } else if (searchData.overdueDaysStart || searchData.overdueDaysEnd || (searchData.overdueDaysStart === 0) || (searchData.overdueDaysEnd === 0)) {
      let overdueDaysStart = (searchData.overdueDaysStart || searchData.overdueDaysStart === 0) ? (new Date().getTime()) - (searchData.overdueDaysStart * 24 * 3600 * 1000) : ((searchData.overdueDaysEnd || searchData.overdueDaysEnd === 0) ? new Date().getTime() : null)

      if (overdueDaysStart) {
        overdueDaysStart = new Date(new Date(overdueDaysStart).toLocaleDateString()).getTime() + ',' + (new Date(new Date(overdueDaysStart).toLocaleDateString()).getTime() + 24 * 3600 * 1000 - 1)
      }

      let overdueDaysEnd = (searchData.overdueDaysEnd || searchData.overdueDaysEnd === 0) ? (new Date().getTime()) - (searchData.overdueDaysEnd * 24 * 3600 * 1000) : null

      if (overdueDaysEnd) {
        overdueDaysEnd = new Date(new Date(overdueDaysEnd).toLocaleDateString()).getTime() + ',' + (new Date(new Date(overdueDaysEnd).toLocaleDateString()).getTime() + 24 * 3600 * 1000 - 1)
      }

      if (overdueDaysStart && !overdueDaysEnd) {
        const overdueDaysStarts = overdueDaysStart.split(',')
        searchData['overdueDate'] = `,${overdueDaysStarts[1]}`
      } else {
        const overdueDaysEnds = overdueDaysEnd.split(',')
        const overdueDaysStarts = overdueDaysStart.split(',')
        searchData['overdueDate'] = `${overdueDaysEnds[0]},${overdueDaysStarts[1]}`
      }

      delete searchData.overdueDaysStart
      delete searchData.overdueDaysEnd
    }
  }

  delete searchData.enableAutoUpdateOverdueDays

  return searchData
}

const filterSearchFields = (data) => {
  data['searchKeyParam'] = {}
  for (const key in data) {
    if (key.indexOf('search_key') !== -1) {
      const json = {}
      json[key] = data[key].split(',')
      data['searchKeyParam'] = { ...data['searchKeyParam'], ...json }
      delete data[key]
    }
  }
  return data
}

const handleMoreSearch = (data) => {
  if (data.searchs) {
    if (/^\d{11}$/.test(data.searchs)) {
      data.contactMobiles = [data.searchs]
    } else if(data.searchs.length === 18){
      data.idCards = [data.searchs]
    } else {
      data.names = [data.searchs]
    }
    delete data.searchs
  }

  return data
}

export const getRobotPlan = (searchData) => {
  return get('v1/case/plan', searchData, { noSucTip: true, noErrTip: true })
}
export const getRobotPlanDetail = (searchData) => {
  if (searchData.callTime) {
    searchData.startTime = searchData.callTime.split(',')[0]
    searchData.endTime = searchData.callTime.split(',')[1]
    delete searchData.callTime
  }
  return get('v1/case/plan/detail', searchData)
}

export const addRobotPlan = (searchData) => {
  searchData.caseMultiQuery = handleOverdueDays(searchData.caseMultiQuery)
  searchData.caseMultiQuery = filterSearchFields(searchData.caseMultiQuery)
  searchData.caseMultiQuery = handleMoreSearch(searchData.caseMultiQuery)
  return post('v1/case/plan/add', searchData, { noSucTip: true })
}
export const changeRobotPlanStatus = (delt) => {
  return post('/v1/case/plan/change', delt)
}
export const addSite = searchData => {
  searchData = handleOverdueDays(searchData)
  return post('/v1/case/plan/addSiteRelative', searchData, { noSucTip: true })
}
export const getRobotVsite = (searchData) => {
  return get('v1/case/plan/site', searchData)
}
export const getRobotList = (data) => {
  return get('v1/case/plan/robot', data)
}

export const planChat = data => {
  return get('v1/case/plan/chart/' + data.planId)
}
export const saveTtsType = data => {
  return put('v1/case/plan/ttsTypeModify', data)
}
export const callLog = data => {
  return get('v1/case/plan/callLog/detail', data)
}
export const robotText = data => {
  return get('v1/case/plan/robot/text', data)
}
export const robotTask = data => {
  return get('v1/task/casePlanTaskList', data)
}
export const fifoAdd = searchData => {
  searchData.caseMultiQuery = handleOverdueDays(searchData.caseMultiQuery)
  searchData.caseMultiQuery = filterSearchFields(searchData.caseMultiQuery)
  searchData.caseMultiQuery = handleMoreSearch(searchData.caseMultiQuery)
  return post('v1/case/plan/fifo/add', searchData, { noSucTip: true })
}
export const fifoList = data => {
  return get('v1/case/plan/fifo/list', data)
}
export const personalFifoAdd = (searchData) => {
  searchData.caseMultiQuery = handleOverdueDays(searchData.caseMultiQuery)
  searchData.caseMultiQuery = filterSearchFields(searchData.caseMultiQuery)
  searchData.caseMultiQuery = handleMoreSearch(searchData.caseMultiQuery)
  return post('v1/case/plan/fifo/addPersonal', searchData, { noSucTip: true })
}
export const getPersonalFifoList = (data) => {
  return get('v1/case/plan/fifo/personalList', data)
}

export const addFifoPlan = (data) => {
  return post('/v1/smsSend/addFifoPlan', data)
}
export const smsAdd = searchData => {
  searchData.caseMultiQuery = handleOverdueDays(searchData.caseMultiQuery)
  searchData.caseMultiQuery = filterSearchFields(searchData.caseMultiQuery)
  searchData.caseMultiQuery = handleMoreSearch(searchData.caseMultiQuery)
  return post('v1/case/plan/message/add', searchData, { noSucTip: true })
}
export const resend = (data) => {
  return post('/v1/message/message/resend', data)
}

export const addMessagePlan = (data) => {
  return post('/v1/smsSend/addMessagePlan', data)
}

export const smsList = data => {
  return get('v1/case/plan/message/list', data, { noSucTip: true, noErrTip: true })
}
export const smsDetail = data => {
  return get('v1/case/plan/message/detail', data)
}
// 取消短信计划
export const smsCancel = data => {
  return get('v1/case/plan/message/cancel/' + data.planId)
}

export const getTtsTypeList = (data) => {
  return get('v1/case/plan/ttsTypeList', data)
}

export const listenTtsType = (data) => {
  return get(`v1/case/plan/ttsType/freeDemo/${data.showName}/${data.volume}/${data.speechRate}/${data.pitchRate}`)
}

export const phoneLine = (data) => {
  return get('/v1/org/phoneLine', data)
}

export const singleAddMsg = (data) => {
  return post('/v1/message/message/singleAdd', data)
}

export const newRobotDetail = (data) => {
  return get('/v1/case/plan/baseInfo', data)
}

export const getSiteVars = (data) => {
  return get('/v1/case/plan/site/vars', data)
}

export const setSiteVars = (data) => {
  return post('/v1/case/plan/site/vars/set', data)
}

export const getCallRounds = (data) => {
  return get('/v1/case/plan/phone/callRounds', data)
}
// 预测试计划图标明细查询
export const getFifoData = (id) => {
  return get(`/v1/case/plan/fifo/chart/${id}`)
}
// 预测计划基本信息
export const getFifoBaseData = (data) => {
  return get('/v1/case/plan/detail',data)
}

// 预测试计划基本信息
export const getBaseData = (id) => {
  return get(`/v1/case/plan/fifo/planInfo/${id}`)
}

// 修改机器人计划
export const updateRobotPlan = (id, data) => {
  return put(`/v1/case/plan/robot/${id}`, data)
}

// 新机器人计划统计
export const getRobotStatistics = (id, data) => {
  return get(`/v1/case/plan/robot/statistics/${id}`, data)
}

// 查询是否达到管控
export const reSendCheckCtrl = (data) => {
  return get('/v1/smsSend/reSendCheckCtrl', data)
}
