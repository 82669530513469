import Vue from 'vue'
import Router from 'vue-router'
import routes from './routers?t=20221202'
import store from '@/store'
import iView from 'view-design'
import {
  getToken,
  getParams,
  getUserInLocalstorage
} from '@/libs/util'
import config from '@/config'
const { homeName } = config

Vue.use(Router)
const router = new Router({
  routes,
  mode: 'history'
})
const LOGIN_PAGE_NAME = 'login'

router.beforeEach((to, from, next) => {
  iView.LoadingBar.start()
  const token = getToken()
  const obj = getParams(window.location.href)
  if (!token && to.name !== LOGIN_PAGE_NAME) {
    // 未登录且要跳转的页面不是登录页
    next({
      name: LOGIN_PAGE_NAME // 跳转到登录页
    })
  } else if (!token && to.name === LOGIN_PAGE_NAME) {
    // 未登录且要跳转的页面是登录页
    store.commit('setToken', '')
    next() // 跳转
  } else if (token && to.name === LOGIN_PAGE_NAME) {
    // 已登录且要跳转的页面是登录页
    // 自动登录如果本地之前有token，会导致重新定向，链接参数丢失
    if (obj.token) {
      next()
    } else {
      if (obj.callUuid) {
        store.commit('setCallUuid', obj.callUuid)
        next({
          name: 'caseQosDetail'
        })
      } else {
        next({
          name: homeName // 跳转到homeName页
        })
      }
    }
  } else {
    // 没有刷新的情况下menuList 在内存中都是存在的，包括agent
    if (store.getters.menuList.length || store.getters.isAgent || store.getters.inspectType === 7) {
      if (obj.callUuid) {
        store.commit('setCallUuid', obj.callUuid)
      }
      next()
    } else {
      // next()
      const userInfo = getUserInLocalstorage()

      if (!userInfo.userId) {
        store.dispatch('handleLogOut')
        return
      }
      store
        .dispatch('getUserInfo', {
          roleId: userInfo.roleId,
          userId: userInfo.userId
        })
        .then(user => {
          // 拉取用户信息，通过用户权限和跳转的页面的name来判断是否有权限访问;access必须是一个数组，如：['super_admin'] ['super_admin', 'admin']
          if (!store.getters.isAgent && !store.getters.menuList.length && store.getters.inspectType !== 7) {
            // 登录账号为管理员但无权限 清除token 跑401页面
            if (to.name === 'error_401') {
              store.commit('setToken', '')
              next()
            } else {
              next({ replace: true, name: 'error_401' })
            }
          } else {
            next(to.path)
          }
        })
        .catch(e => {
          store.dispatch('handleLogOut')
        })
    }
  }
})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

router.afterEach(to => {
  iView.LoadingBar.finish()
  window.scrollTo(0, 0)
})

export default router
