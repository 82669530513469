import { get, post, put, del, postForm } from '@/libs/api.request'

const filterSearchFields = (data) => {
  data['searchKeyParam'] = {}
  for (const key in data) {
    if (key.indexOf('search_key') !== -1) {
      const json = {}
      json[key] = data[key].split(',')
      data['searchKeyParam'] = { ...data['searchKeyParam'], ...json }
      delete data[key]
    }
    if (typeof (data[key]) === 'string' && ['names', 'outSerialTempList', 'idCards', 'mobiles', 'outSerialNos'].includes(key)) {
      data[key] = data[key].trim().replace(/\n/g, ',').split(',')
    }
  }
  return data
}

export const getCaseAddressList = (data) => {
  return get('v1/visit/caseAddressList', data)
}

export const addAddress = (data) => {
  return put('v1/visit/addAddress', data)
}

export const updateAddress = (data) => {
  return put('v1/visit/updateAddress', data)
}

export const deleteAddress = (data) => {
  return put(`v1/visit/deleteAddress/${data.addressId}`)
}

export const applyVisits = (data) => {
  return put(`v1/visit/applyVisit`, data, { noSucTip: true })
}

export const getApplyVisitList = (data) => {
  data = filterSearchFields(data)
  return post('v1/visit/applyVisitByCaseList', data, { noSucTip: true })
}

export const cancelApplyVisit = (data) => {
  return put(`v1/visit/cancelApply/${data.applyId}`, null, { noSucTip: true })
}

export const getAuditInfo = (data) => {
  return get('v1/visit/auditInfo', data)
}

export const applyVisitStatistics = (data) => {
  data = filterSearchFields(data)
  return post('v1/visit/applyVisitStatistics', data, { noSucTip: true })
}

export const allotVisit = (data) => {
  return put('v1/visit/allotVisit', data, { noSucTip: true })
}

export const rejectAudit = (data) => {
  return put('v1/visit/refuseAudit', data)
}

export const getVisitors = (data) => {
  return get('v1/visit/visitUserList', data)
}

export const getVisitList = (data) => {
  data = filterSearchFields(data)
  return post('v1/visit/visitList', data, { noSucTip: true })
}

export const visitListStatistics = (data) => {
  data = filterSearchFields(data)
  return post('v1/visit/visitListStatistics', data, { noSucTip: true })
}

export const getVisitInfo = (data) => {
  return get('v1/visit/visitInfo', data)
}

export const resetAllotVisit = (data) => {
  return put('v1/visit/resetAllotVisit', data)
}
export const supplyVisit = (data) => {
  return put('/v1/visit/supplementVisitReport', data, { noSucTip: true })
}

export const addVisitReport = (data) => {
  return put('/v1/visit/addVisitReport', data, { noSucTip: true })
}

export const addVisitFile = (data, callBack) => {
  return post('/v1/visit/addVisitFile', data, { noSucTip: true, uploadTime: true, onUploadProgress: callBack })
}
// , uploadTime: true

// export const delVisitFile = (data) => {
//     return put(`/v1/visit/deleteVisitFile/${data.visitFileId}`)
// }
export const delVisitFile = (data) => {
  return put('/v1/visit/deleteVisitFile', data, { noSucTip: true })
}

export const cancelVisitExport = (data) => {
  return put(`/v1/downloadTask/cancelTask/${data.taskId}`)
}

export const getExportList = (data) => {
  return get('/v1/downloadTask/list', data)
}

export const addVisitExport = (data) => {
  data = filterSearchFields(data)
  return post('/v1/visit/addDownloadTask', data, { noSucTip: true })
}

export const getSwitchSpace = (data) => {
  return get('/v1/visit/orgVisitFileSpace', data)
}

export const visitTrialByOrgList = (data) => {
  return post('/v1/visit/visitTrailByOrgList', data, { noSucTip: true })
}

export const visitSignInList = (data) => {
  return post('/v1/visit/visitSignInList', data, { noSucTip: true })
}

export const visitTrailList = (data) => {
  return post('/v1/visit/visitTrailList', data, { noSucTip: true })
}

export const listByVisitId = (data) => {
  return get('/v1/caseCallback/listByVisitId', data)
}

export const cancelVisit = ({ id }) => {
  return put(`/v1/visit/cancelVisit/${id}`)
}

export const visitFileList = (data) => {
  data = filterSearchFields(data)
  return post('/v1/visitFile/list', data, { noSucTip: true })
}

export const exportVisitFile = (data) => {
  data = filterSearchFields(data)
  return post('/v1/visitFile/export', data, { noSucTip: true })
}

export const delVisitFiles = (data) => {
  data = filterSearchFields(data)
  return del('/v1/visitFile/del', data)
}

export const caseAddresses = (data) => {
  return get('/v1/visit/caseAddresses', data)
}

export const allotVisitBatch = (data) => {
  return put('/v1/visit/allotVisitBatch', data, { noSucTip: true })
}

export const allotVisitList = (data) => {
  return get('/v1/task/allotVisitList', data)
}

export const allotVisitView = (data) => {
  return get('/v1/task/allotVisitView', data)
}
// 批量取消外访
export const batchVisit = (data) => {
  return put('/v1/visit/cancel/batch', data)
}
// 批量调整外访排程
export const batchAdjustment = (data) => {
  return put('/v1/visit/adjust/batch', data)
}

// 外访报告模板
export const getVisitTpl = (data) => {
  return get('/v1/visit/templateList', data)
}

// 分页 外访报告模板列表
export const getVisitTplPage = (data) => {
  return get('/v1/visit/templateListByPage', data)
}

// 获取启用的自定义外访表单配置
export const getVisitSelectedFields = (data) => {
  return get('/v1/visit/selectedFields', data)
}

// 新增外访报告模板
export const addVisitTpl = (data) => {
  return postForm('/v1/visit/addTemplate', data)
}

// 编辑外访报告模板
export const updateVisitTpl = (data) => {
  return postForm('/v1/visit/updateTemplate', data)
}

// 删除外访报告模板
export const delVisitTpl = (data) => {
  return del(`/v1/visit/delTemplate/${ data.id }`, data)
}

// 自定义外访表单配置
export const visitFields = (data) => {
  return get('/v1/visit/fields', data)
}

// 外访表单自定义字段排序
export const sortVisitField = (data) => {
  return post('/v1/visit/field/sort', data, { noSucTip: true })
}

// 外访自定义字段开关
export const enableVisitField = (data) => {
  return post('/v1/visit/field/enable', data)
}

// 自定义字段详情
export const getFieldDetail = (data) => {
  return get(`/v1/visit/field/detail/${data}`)
}

// 自定义字段更新
export const updateVisitField = (data) => {
  return post('/v1/visit/field/update', data)
}

// 外访报告更新
export const editVisitReport = (data) => {
  return post('/v1/visit/report/edit', data)
}
