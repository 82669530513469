import { put, get, post, del } from '@/libs/api.request'

export const applyList = (data) => {
  return post('/v1/lawsuit/apply/list', data, { noSucTip: true })
}

export const applyAudit = (data) => {
  return put('/v1/lawsuit/apply/audit', data)
}

export const applyStatistic = (data) => {
  return post('/v1/lawsuit/apply/statistics', data, { noSucTip: true })
}
// 诉讼案件更新
export const updateCase = (data) => {
  return post('/v1/lawsuit/update/import', data)
}

export const lawsuitList = (data) => {
  return post('/v1/lawsuit/list', data, { noSucTip: true })
}

export const getLawsuitConfig = (data) => {
  return get(`/v1/lawsuit/config/lawsuitList/${data.type}`)
}

export const lawsuit = (data) => {
  return put('/v1/lawsuit', data, { noSucTip: true })
}

export const exportLawsuit = (data) => {
  return post('/v1/lawsuit/export', data)
}

export const getLawsuitDetail = (id) => {
  return get(`/v1/lawsuit/${id}`)
}

export const addLawsuitProcess = (data) => {
  return post('/v1/lawsuit/process', data, { noSucTip: true })
}

export const updateLawsuit = (id, data) => {
  return put(`/v1/lawsuit/${id}`, data)
}

export const getLawsuitHandler = (data) => {
  return get('/v1/lawsuit/handler', data)
}

export const addLawsuitHandler = (data) => {
  return post('/v1/lawsuit/handler/add', data, { noSucTip: true })
}

export const delLawsuitHandler = (id) => {
  return del(`/v1/lawsuit/handler/${id}`)
}

export const updateLawsuitHandler = (data) => {
  return put('/v1/lawsuit/handler', data)
}

export const getLawsuitPay = (data) => {
  return get('/v1/lawsuit/pay', data)
}

export const delLawsuitPay = (id) => {
  return del(`/v1/lawsuit/pay/${id}`)
}

export const addLawsuitPay = (data) => {
  return post('/v1/lawsuit/pay/add', data)
}

export const updateLawsuitPay = (data) => {
  return put('/v1/lawsuit/pay', data)
}

export const paySum = (id) => {
  return get(`/v1/lawsuit/paySum/${id}`)
}

export const getLawsuitAssets = (data) => {
  return get('/v1/lawsuit/asset', data)
}

export const delLawsuitAsset = (id) => {
  return del(`/v1/lawsuit/asset/${id}`)
}

export const addLawsuitAsset = (data) => {
  return post('/v1/lawsuit/asset/add', data)
}

export const updateLawsuitAsset = (data) => {
  return put('/v1/lawsuit/asset', data)
}

export const getLawsuitFile = (data) => {
  return get('/v1/lawsuit/file', data)
}

export const delLawsuitFile = (id) => {
  return del(`/v1/lawsuit/file/${id}`)
}

export const addLawsuitFile = (data) => {
  return post('/v1/lawsuit/file/add', data)
}

export const getLawsuitNote = (data) => {
  return get('/v1/lawsuit/note', data)
}

export const addLawsuitNote = (data) => {
  return post('/v1/lawsuit/note', data)
}

export const addLawsuitApply = (data) => {
  return post('/v1/lawsuit/apply', data, { noSucTip: true })
}

// 诉讼文件管理
export const getFileLawsuit = (data) => {
  return post('/v1/lawsuit/file/list', data, { noSucTip: true })
}

// 批量删除诉讼文件
export const delLawsuits = (data) => {
  return del('/v1/lawsuit/file/batch', data)
}

// 诉讼文件管理导出列表
export const getLassuitList = (data) => {
  return get('/v1/downloadTask/list', data)
}

// 导出诉讼文件
export const exportLawsuitFile = (data) => {
  return post('/v1/lawsuit/exportLawsuitFile', data)
}

export const getExportList = (data) => {
  return get('/v1/lawsuit/downloadTask', data)
}

// 诉讼删除操作记录
export const getLawsuitRecord = (data) => {
  return get('/v1/lawsuit/file/deleteRecord',data)
}
